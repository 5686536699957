import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Breadcrumbs, Button, CollapsingCard, Label, Table } from "components";
import { InputForm, TextareaForm } from "components/HookForm";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { CgChevronDown } from "react-icons/cg";

import ClassicEditor from "ckeditor5-custom-build";
import { AiFillFilePdf } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import { useMutation, useQuery } from "react-query";
import { getDetailDebtor, putAgreementAgencyMemberDebtor } from "services/directDistribution";
import { enqueueSnackbar } from "notistack";
import AsyncDropzoneFieldForm from "components/HookForm/AsyncDropzoneFieldForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { currencyFormatter } from "utils/helpers";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
    path: "/agreement-direct-distribution",
  },
  {
    label: "Kontrak Perjanjian",
    path: -1,
  },
  {
    label: "Debitur",
  },
];

const uploadSchema = yup.object({
  doc_agreement: yup
    .mixed()
    .test("required", "Template perjanjian tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
  doc_fiduasia: yup
    .mixed()
    .test("required", "Template perjanjian tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
  doc_rights: yup
    .mixed()
    .test("required", "Template perjanjian tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
  dataDebtor: yup.object({
    profile: yup.object({
      name: yup.string().label('Nama Lembaga'),
      agency_type: yup.string().label('Jenis Lembaga'),
      service_type: yup.string().label('Jenis usaha dan/atau jenis layanan'),
      npwp: yup.string().label('Nomor NPWP Lembaga'),
      email: yup.string().label('Email Lembaga'),
      phone: yup.string().label('No Telepon'),
      country: yup.string().label('Negara'),
      province: yup.string().label('Province'),
      city: yup.string().label('Kab/Kota'),
      district: yup.string().label('Kecamatan'),
      village: yup.string().label('Kelurahan'),
      zip_code: yup.string().label('Kode Pos'),
      address: yup.string().label('Alamat'),
    }),
    information: yup.object({
      contact_person_name: yup.string().label('Nama Narahubung'),
      contact_person_position: yup.string().label('Jabatan'),
      contact_person_email: yup.string().label('Email'),
      contact_person_phone: yup.string().label('No Telepon'),
      contact_person_identity_number: yup.string().label('NIK'),
      contact_person_npwp: yup.string().label('NPWP'),
    }),
    mandatory: yup.array(),
    collateral: yup.array().notRequired().of(
      yup.object({
        type: yup.string().notRequired().label(),
        value: yup.string().notRequired().label(),
        form: yup.string().notRequired().label(),
      })
    )
  })
});

const OtherLegalEntitiesDebtor = () => {
  const methods = useForm({
    resolver: yupResolver(uploadSchema),
  });

  const navigate = useNavigate();
  const { id, id_debtor } = useParams();

  const [collateral, setCollateral] = useState();

  const { data: detailDebtor } = useQuery(
    ['GET_DETAIL_DEBTOR', id_debtor],
    getDetailDebtor,
    {
      select: (data) => data?.result
    }
  )

  const handleMountForm = useCallback(async () => {
    if (detailDebtor) {
      methods.reset({
        doc_agreement_draft_template: detailDebtor?.doc_agreement_draft_template,
        doc_agreement: detailDebtor?.doc_agreement,
        doc_fiduasia: detailDebtor?.doc_fiduasia,
        doc_rights: detailDebtor?.doc_rights,
        dataDebtor: detailDebtor?.dataDebtor
      })

      setCollateral(detailDebtor?.dataDebtor?.collateral)
    }
  }, [detailDebtor])

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'dataDebtor.mandatory'
  })

  useEffect(() => {
    handleMountForm();
  }, [handleMountForm]);

  const mutatePutAgreementAgencypMemberDebtor = useMutation(putAgreementAgencyMemberDebtor)

  const onSubmit = async (data) => {
    const payload = methods.getValues()

    const mapPayload = {
      doc_agreement: payload.doc_agreement,
      doc_fiduasia: payload.doc_fiduasia,
      doc_rights: payload.doc_rights,
    }

    mutatePutAgreementAgencypMemberDebtor.mutate(
      { id_debtor, mapPayload },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Draft berhasil diupload",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Draft gagal diupload",
          });
        },
      }
    )
  };

  const doc_agreement_draft_template = methods.watch('doc_agreement_draft_template')

  const tableColumnDocumenPendukung = useMemo(
    () => [
      {
        id: "",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          return index + 1;
        },
      },
      {
        id: "type",
        title: "Jenis Jaminan",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          console.log('data', data)
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.type}
            </div>
          );
        },
      },
      {
        id: "form",
        title: "Bentuk Jaminan",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.form}
            </div>
          );
        },
      },
      {
        id: "value",
        title: "Nilai Jaminan",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {currencyFormatter(data.value)}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <CollapsingCard title="Profile Lembaga" isCollapse={false}>
          <div className="flex flex-col gap-5">
            <div className="flex py-4 px-2 bg-primary-700 text-white rounded">
              <h5 className="text-lg font-semibold">Informasi Lembaga</h5>
            </div>

            {/* <div className="grid grid-cols-4 gap-5">
              <div className="flex flex-col gap-2">
                <Label required>Nama Pengurus</Label>
                <InputForm
                  name=""
                  className="w-full"
                  placeholder="Masukan Nama Pengurus"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Jabatan</Label>
                <InputForm
                  name=""
                  className="w-full"
                  placeholder="Masukan Jabatan"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>NIK</Label>
                <InputForm
                  name=""
                  className="w-full"
                  placeholder="Masukan NIK"
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Nomor Telepon</Label>
                <InputForm
                  name=""
                  className="w-full"
                  placeholder="Masukan Nomor Telepon"
                />
              </div>
            </div> */}

            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col gap-2">
                <Label required>Nama Lembaga</Label>
                <InputForm
                  name="dataDebtor.profile.name"
                  className="w-full"
                  placeholder="Masukan Nama Lembaga"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Jenis Lembaga</Label>
                <InputForm
                  name="dataDebtor.profile.agency_type"
                  className="w-full"
                  placeholder="Masukan Jenis Lembaga"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label required>Jenis usaha dan/atau jenis layanan</Label>
                <InputForm
                  name="dataDebtor.profile.service_type"
                  className="w-full"
                  placeholder="Masukan Jenis usaha dan/atau jenis layanan"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Nomor NPWP Lembaga</Label>
                <InputForm
                  name="dataDebtor.profile.npwp"
                  className="w-full"
                  placeholder="Masukan Nomor NPWP Lembaga"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label required>Email</Label>
                <InputForm
                  name="dataDebtor.profile.email"
                  className="w-full"
                  placeholder="Masukan Email"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>No Telepon</Label>
                <InputForm
                  name="dataDebtor.profile.phone"
                  className="w-full"
                  placeholder="Masukan No Telepon"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label required>Negara</Label>
                <InputForm
                  name="dataDebtor.profile.country"
                  className="w-full"
                  placeholder="Masukan Negara"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Provinsi</Label>
                <InputForm
                  name="dataDebtor.profile.province"
                  className="w-full"
                  placeholder="Masukan Provinsi"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label required>Kab/Kota</Label>
                <InputForm
                  name="dataDebtor.profile.city"
                  className="w-full"
                  placeholder="Masukan Kab/Kota"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Kecamatan</Label>
                <InputForm
                  name="dataDebtor.profile.district"
                  className="w-full"
                  placeholder="Masukan Kecamatan"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label required>Kelurahan</Label>
                <InputForm
                  name="dataDebtor.profile.village"
                  className="w-full"
                  placeholder="Masukan Keurahan"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Kode Pos</Label>
                <InputForm
                  name="dataDebtor.profile.zip_code"
                  className="w-full"
                  placeholder="Masukan Kode Pos"
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <Label required>Alamat Lengkap</Label>
              <TextareaForm
                name="dataDebtor.profile.address"
                className="dataDebtor.profile.address"
                placeholder="Masukan Alamat Lengkap"
                disabled
              />
            </div>

            <div className="flex py-4 px-2 bg-primary-700 text-white rounded justify-between items-center">
              <h5 className="text-lg font-semibold">
                Informasi Penanggung Jawab (Narahubung)
              </h5>

              <CgChevronDown className="text-2xl mr-2" />
            </div>

            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col gap-2">
                <Label required>Nama Narahubung</Label>
                <InputForm
                  name="dataDebtor.information.contact_person_name"
                  className="w-full"
                  placeholder="Masukan Nama Narahubung"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>Jabatan</Label>
                <InputForm
                  name="dataDebtor.information.contact_person_position"
                  className="w-full"
                  placeholder="Masukan Jabatan"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label required>Email</Label>
                <InputForm
                  name="dataDebtor.information.contact_person_email"
                  className="w-full"
                  placeholder="Masukan Email"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>No Telepon</Label>
                <InputForm
                  name="dataDebtor.information.contact_person_phone"
                  className="w-full"
                  placeholder="Masukan No Telepon"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-2">
                <Label required>NIK</Label>
                <InputForm
                  name="dataDebtor.information.contact_person_identity_number"
                  className="w-full"
                  placeholder="Masukan Nama Narahubung"
                  disabled
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label required>NPWP</Label>
                <InputForm
                  name="dataDebtor.information.contact_person_npwp"
                  className="w-full"
                  placeholder="Masukan NPWP"
                  disabled
                />
              </div>
            </div>

            <div className="flex py-4 px-2 bg-primary-700 text-white rounded justify-between items-center">
              <h5 className="text-lg font-semibold">
                Hak dan Tanggung Jawab lembaga
              </h5>

              <CgChevronDown className="text-2xl mr-2" />
            </div>

            {
              fields.map((field, index) => (
                <div className="flex gap-4 items-end" key={field.id}>
                  <InputForm
                    label="Hak dan Tanggung Jawab Lembaga"
                    wrapperClass=""
                    className="w-full"
                    name={`dataDebtor.mandatory[${index}]`}
                    required
                    asText={true}
                  />
                </div>
              ))
            }

            {/* <div className="flex flex-col gap-2" id="template-debitur">
              <Label>Hal yang Mendasari untuk menjadi Lembaga Penyalur</Label>
              <CKEditor
                style={{ height: "150px" }}
                editor={ClassicEditor}
                onReady={(editor) => {
                  // editor.setData();
                }}
                onChange={(e, editor) => console.log(editor.getData())}
                disabled
              />
            </div>

            <div className="flex flex-col gap-2" id="template-debitur">
              <Label>
                Pengalaman Lembaga dalam Pembiayaan Khususnya Usaha Kehutanan
                Dan/Atau Investasi Lingkungan
              </Label>
              <CKEditor
                style={{ height: "150px" }}
                editor={ClassicEditor}
                onReady={(editor) => {
                  // editor.setData();
                }}
                onChange={(e, editor) => console.log(editor.getData())}
                disabled
              />
            </div> */}
          </div>
        </CollapsingCard>

        {/* <CollapsingCard title="Data Jaminan" isCollapse={false}>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <Label>Jenis Jaminan</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Jenis Jaminan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Bentuk Jaminan</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Bentuk Jaminan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Nilai Jaminan</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Nilai Jaminan"
              />
            </div>

            <span className="w-full h-0.5 bg-primary-700"></span>

            <div className="flex border rounded-lg p-4 max-w-[538px] w-full items-center">
              <div className="flex gap-3 flex-grow">
                <AiFillFilePdf size={40} fill="#00BD52" />
                <div className="flex flex-col gap-1">
                  <Label>Format Daftar Potensi Penerimaan</Label>
                  <p className="text-xs">FDB.pdf (2MB)</p>
                </div>
              </div>
              <Button
                size="sm"
                className="flex gap-2 bg-primary-700 hover:bg-primary-600 text-white"
              >
                <BsDownload />
                <h4>Download</h4>
              </Button>
            </div>
          </div>
        </CollapsingCard> */}

        <CollapsingCard title="Jaminan" isCollapse={false}>
          <Table dataSource={collateral} columns={tableColumnDocumenPendukung} showPagination={false} />
        </CollapsingCard>

        <CollapsingCard title="Pembuatan Draft Perjanjian" isCollapse={false}>
          <div className="flex gap-4 items-center">
            {
              doc_agreement_draft_template ? (
                <AsyncDropzoneFieldForm
                  name="doc_agreement_draft_template"
                  informationText="PDF"
                  maxSize={10}
                  maxFiles={1}
                />
              ) : (
                <>
                  <Label>Lihat Draft Perjanjian:</Label>
                  <Button
                    className="bg-primary-700 hover:bg-primary-600 text-white"
                    onClick={() =>
                      navigate(
                        `draft`
                      )
                    }
                  >
                    Buat Kontrak Perjanjian
                  </Button>
                </>
              )
            }

          </div>
        </CollapsingCard>

        <CollapsingCard
          title="Upload Dokumen Perjanjian Notaris"
          isCollapse={false}
        >
          <div className="flex flex-col gap-2">
            <Label>Upload Dokumen</Label>
            <AsyncDropzoneFieldForm
              name="doc_agreement"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            />
          </div>
        </CollapsingCard>

        <CollapsingCard title="Upload Dokumen Akta Fidusia" isCollapse={false}>
          <div className="flex flex-col gap-2">
            <Label>Upload Dokumen</Label>
            <AsyncDropzoneFieldForm
              name="doc_fiduasia"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            />
          </div>
        </CollapsingCard>

        <CollapsingCard
          title="Upload Dokumen Hak Tanggungan"
          isCollapse={false}
        >
          <div className="flex flex-col gap-2">
            <Label>Upload Dokumen</Label>
            <AsyncDropzoneFieldForm
              name="doc_rights"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            />
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            type="button"
            onClick={() => navigate(-1)}
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default OtherLegalEntitiesDebtor;
