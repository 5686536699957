import _ from "lodash";
import React from "react";
// import { useSelector } from "react-redux";
import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";
import {
  Route,
  BrowserRouter as Router,
  Routes as WrapperRoutes,
} from "react-router-dom";

import route from "./route";

import Custom404 from "pages/404";

import { BasicLayout } from "components/Layout";
import { AuthConsumer } from "providers/authProvider";
import { Spinner } from "components";

const Routes = () => {
  // const { token } = useSelector((state) => state.auth);
  return (
    <Router>
      <WrapperRoutes>
        <Route exact={true} path="/signin-oidc" element={<CALLBACK />} />
        <Route exact={true} path="/logout" element={<LOGOUT />} />
        <Route exact={true} path="/logout/callback" element={LOGOUT_CALLBACK} />
        <Route exact={true} path="/silentrenew" element={SILENT_RENEW} />
        {_.map(_.filter(route, { routeType: "private" }), (val, key) => {
          const Component = val.element;
          return (
            <Route
              exact
              key={key}
              path={val.path}
              element={
                <AuthConsumer>
                  {({ isAuthenticated, signinRedirect }) => {
                    if (!isAuthenticated()) {
                      signinRedirect();
                      return (
                        <div className="relative min-h-screen">
                          <Spinner />
                        </div>
                      );
                    }

                    return (
                      <BasicLayout>
                        <Component />
                      </BasicLayout>
                    );
                  }}
                </AuthConsumer>
              }
            />
          );
        })}
        <Route path="*" element={<Custom404 />} />
      </WrapperRoutes>
    </Router>
  );
};

export default Routes;