import { Breadcrumbs, Button, SearchInput, Table, TitleText } from "components";
import { useNotaryList } from "hooks/notary";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import { useNavigate } from "react-router";

const Notary = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);

  const { data: notaryData, isLoading } = useNotaryList([
    "notary_list",
    {
      page,
      limit,
      search: keyword ?? undefined,
    },
  ]);

  const links = [
    {
      label: "Master Data Notaris",
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        // className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(notaryData?.paginator?.page) * parseInt(notaryData?.paginator?.limit) -
            parseInt(notaryData?.paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "notary_name",
        title: "Nama Notaris",
        dataIndex: "notaryName",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.name ?? "-"}
          </div>
        ),
      },
      {
        id: "kemenkunham",
        title: "SK. KEMENKUNHAM",
        dataIndex: "kemenkunham",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.sk_kemenkumham ?? "-"}
          </div>
        ),
      },
      {
        id: "ppat",
        title: "SK. PPAT",
        dataIndex: "ppat",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.sk_ppat ?? "-"}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        width: 160,
        columnClassName: "text-center min-w-[120px]",
        className: "text-center min-w-[120px]",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex justify-center gap-2">
            <BsEye
              className={`text-gray-600 cursor-pointer`}
              size={20}
              onClick={() => navigate(`/notary/${data.id}/preview`)}
            />
            <BiPencil onClick={() => navigate(`/notary/${data.id}/form`)} className={`text-gray-600 cursor-pointer`} size={20} />
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Daftar Data Notaris</TitleText>
        <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={() => navigate(`/notary/form`)}
        >
          Tambah Data
        </Button>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {/* Filter */}
            <div className="flex items-center space-x-4">
              <SearchInput placeholder="Pencarian" onChange={onSearch} />
            </div>

            {/* Table */}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={notaryData?.result}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={notaryData?.paginator}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notary;
