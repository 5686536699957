import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CollapsingCard, Label } from "components";
import { CustomSelectForm, InputForm, TextareaForm } from "components/HookForm";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import {
  useGetNotesConceptDetail,
  useGetProfileByUserId,
  useUpdateLemtara,
  useUpdateLemtaraProposal,
} from "hooks/lemtara";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { createFileRequest, createFileRequestLemtara } from "utils";
import * as yup from "yup";

const validationScheme = yup.object({
  proposal_id: yup.string().notRequired(),
  proposal_name: yup.string().notRequired(),
  submission_date: yup.string().notRequired(),
  donor_id: yup.string().notRequired(),
  donor_name: yup.string().notRequired(),
  agreement_number: yup
    .string()
    .notRequired("Nomor Perjanjian tidak boleh kosong")
    .label("Nomor Perjanjian"),
  implementation_date: yup
    .string()
    .notRequired("Tanggal Pelaksanaan tidak boleh kosong"),
  doc_agreement: yup
    .mixed()
    .test("required", "Dokumen hak tanggungan tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
});

const LemtaraForm = () => {
  // const methods = useForm();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  // const { data: notes } = useGetNotesConceptDetail(
  //   ["GET_DETAIL_NOTES", location?.state?.program?.id],
  //   { select: (data) => data?.data }
  // );

  // const { data: profile } = useGetProfileByUserId(
  //   ["GET_PROFILE_ID", notes?.user_id],
  //   { select: (data) => data?.data }
  // );

  // console.log(profile, "PROFILE");

  const queryClient = useQueryClient();
  const mutatePutLemtara = useUpdateLemtara();
  const mutateUpdateProposalLemtara = useUpdateLemtaraProposal();

  const methods = useForm({
    resolver: yupResolver(validationScheme),
  });

  const mountingForm = useCallback(() => {
    methods.reset({
      proposal_id: location?.state?._id,
      proposal_name: location?.state?.proposal_title,
      submission_date: location?.state?.date_submit,
      donor_id: "",
      donor_name: "",
      agreement_number: null,
      implementation_date: null,
      doc_agreement: [],
    });
  }, [methods, location?.state]);

  useEffect(() => {
    mountingForm();
  }, [mountingForm]);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
    };

    const payloadDaprog = {
      proposal_id: data?.proposal_id,
    };

    await createFileRequest(data?.doc_agreement).then((values) => {
      payload.doc_agreement = values;
    });

    await createFileRequestLemtara(data?.doc_agreement).then((values) => {
      payloadDaprog.document = values;
    });

    mutateUpdateProposalLemtara.mutate(
      { payload: payloadDaprog },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Berhasil update lemtara",
          });
          navigate(-1);
          queryClient.invalidateQueries(["GET_LIST_PROPOSAL_LEMTARA"]);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Gagal update lemtara",
          });
        },
      }
    );

    // mutatePutLemtara.mutate(
    //   { id: params.id, payload },
    //   {
    //     onSuccess: (res) => {
    //       enqueueSnackbar({
    //         variant: "success",
    //         message: "Berhasil update lemtara",
    //       });
    //       navigate(-1);
    //       queryClient.invalidateQueries(["GET_LIST_PROPOSAL_LEMTARA"]);
    //     },
    //     onError: (err) => {
    //       enqueueSnackbar({
    //         variant: "error",
    //         message: "Gagal update lemtara",
    //       });
    //     },
    //   }
    // );
  };

  console.log(methods.formState.errors);

  return (
    <section className="flex flex-col space-y-4">
      <FormProvider {...methods}>
        <CollapsingCard title="Informasi Peraturan" isCollapse={false}>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-2">
              <Label>Nama Proposal</Label>
              <p className="text-sm">{location?.state?.proposal_title}</p>
            </div>

            {/* <div className="flex flex-col gap-2">
              <Label>Nama Donor</Label>
              <p className="text-sm">Lorem Ipsum</p>
            </div> */}

            <div className="flex flex-col gap-2">
              <Label>Tanggal Pengajuan</Label>
              <p className="text-sm">
                {moment(location?.state?.date_submit).format("DD MMMM YYYY")}
              </p>
            </div>

            {/* <div className="flex flex-col gap-2">
              <Label>Nomor Perjanjian</Label>
              <InputForm
                type="text"
                name="agreement_number"
                className="w-full"
              />
            </div> */}

            {/* <div className="flex flex-col gap-2">
              <Label>Tanggal Pelaksanaan</Label>
              <InputForm
                type="date"
                name="implementation_date"
                className="w-full"
              />
            </div> */}
          </div>
        </CollapsingCard>

        {/* <CollapsingCard
          title="General Informasi Penerima Manfaat"
          isCollapse={false}
        >
          <div className="flex flex-col gap-20">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Label>Nama PENERIMA MANFAAT</Label>
                <CustomSelectForm name="" options={[]} isDisabled />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <Label>Pilih Fokus Isu Atau Intervansi Lembaga</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Kapasitas Pengelolaan Dana Hibah</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Email</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>No Telepon</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Negara</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Provinsi</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Kota/Kabupaten</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Kecamatan</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Kelurahan</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Kode Pos</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2 col-span-2">
                <Label>Alamat Lengkap</Label>
                <TextareaForm className="" type="text" name="" disabled />
              </div>

              <h3 className="text-xl text-gray-800 font-semibold col-span-2">
                Informasi Penanggung Jawab
              </h3>

              <div className="flex flex-col gap-2">
                <Label>Nama Narahubung</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Jabatan</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Email</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>

              <div className="flex flex-col gap-2">
                <Label>Telepon</Label>
                <InputForm className="w-full" type="text" name="" disabled />
              </div>
            </div>
          </div>
        </CollapsingCard> */}

        <CollapsingCard
          title="Upload Dokumen Perjanjian Lemtara"
          isCollapse={false}
        >
          <div className="w-full">
            <DropzoneFieldForm
              name="doc_agreement"
              informationText="PDF/DOC"
              accept={{
                "application/docx": [".doc", ".docx"],
                "application/pdf": [".pdf"],
              }}
              maxFiles={1}
              maxSize={1}
              multiple={false}
            />
          </div>
        </CollapsingCard>

        {/* <CollapsingCard title="Notes/Change Log" isCollapse={false}>
          <InputForm
            type="textarea"
            rows={3}
            name="notes"
            className="w-full"
            placeholder="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo, consequatur?"
          />
        </CollapsingCard> */}

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            type="button"
            // onClick={() => navigate(`/agreement-donor`, { state: { tab: 1 } })}
            outline
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </FormProvider>
    </section>
  );
};

export default LemtaraForm;
