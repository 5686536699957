import { AnimatePresence } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import moment from 'moment';
import localization from 'moment/locale/id';
import Modal from 'react-modal';
import { AuthProvider } from "providers/authProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
});

moment.updateLocale('id', localization);
Modal.setAppElement('#root');

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        maxSnack={3}
        disableWindowBlurListener
        autoHideDuration={3000}
      >
        <AnimatePresence mode="wait">
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </AnimatePresence>
        <ReactQueryDevtools />
      </SnackbarProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
