import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "constants/Auth";
import axios from "axios";

export default class AuthService {
  UserManager;
  UserManagerCallback;

  constructor() {
    if (!this.UserManager) {
      this.UserManager = new UserManager({
        ...IDENTITY_CONFIG,
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
        metadata: {
          ...METADATA_OIDC,
        },
      });
    }
    // this.UserManagerCallback = new UserManager({
    //   ...IDENTITY_CONFIG,
    //   userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    //   response_mode: "query",
    //   metadata: {
    //     ...METADATA_OIDC,
    //   },
    // });
    // Logger
    // Log.logger = console;
    // Log.level = Log.DEBUG;

    // this.UserManager.events.addAccessTokenExpiring(function () {
    //   console.log("token expiring");

    //   // maybe do this code manually if automaticSilentRenew doesn't work for you
    //   this.UserManager.signinSilent()
    //     .then(function (user) {
    //       console.log("silent renew success", user);
    //     })
    //     .catch(function (e) {
    //       console.log("silent renew error", e.message);
    //     });
    // });
    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });

    this.UserManager.events.addSilentRenewError(function (e) {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        window.location.replace(localStorage.getItem("redirectUri") ?? "/");
      }
    });

    this.UserManager.events.addUserUnloaded(function (e) {
      console.log("user unloaded");
    });

    this.UserManager.events.addUserSignedIn(function (e) {
      console.log("user logged in to the token server");
    });

    this.UserManager.events.addUserSignedOut(function (e) {
      console.log("user logged out of the token server");
    });

    this.oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${`${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}`}:${"web_app_internal"}`
      )
    );

    if (this.oidcStorage?.access_token) axios.defaults.headers["Authorization"] = `Bearer ${this.oidcStorage?.access_token}`

  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {
        "";
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  isAuthenticated = () => {
    return !!this.oidcStorage && !!this.oidcStorage.access_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace("/");
    });
    this.UserManager.clearStaleState();
  };
}
