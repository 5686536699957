import { debounce, map } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router";

import {
  Breadcrumbs,
  Button,
  SearchInput,
  Table,
  TitleText,
} from "components";
import {
  useDonorAgreementList,
} from "hooks/donor";
import moment from "moment/moment";
import { BsEye } from "react-icons/bs";

// =============== Breadcrumbs Items
const links = [
  {
    label: "Perjanjian Debitur Lembaga Penyalur",
  },
];

const DebtorAgreement = () => {
  // =============== Initialize
  const navigate = useNavigate();

  // =============== State
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);

  const { isLoading: isLoadingDebtorAgreement, data: debtorAgreement } =
    useDonorAgreementList([
      "debtor_agreement_list",
      {
        page,
        limit,
        search: keyword ?? undefined,
      },
    ]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) {
            return event?.target?.value;
          }
          return undefined;
        }),
      []
    ),
    800
  );

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const handleEditClick = useCallback(
    (id) => {
      navigate(`/debtor-agreement/${id}/upload`);
    },
    [navigate]
  );

  const tableColumnsDebtorAgreement = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        width: 20,
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(debtorAgreement?.paginator?.page) *
              parseInt(debtorAgreement?.paginator?.limit) -
            parseInt(debtorAgreement?.paginator?.limit) +
            index +
            1;
          return no + ".";
        },
      },
      {
        id: "lp_name",
        title: "Nama LP",
        dataIndex: "lpName",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.lp_name ?? "-"}
          </div>
        ),
      },
      {
        id: "branch_name",
        title: "Nama Cabang",
        dataIndex: "branchName",
        sortable: true,
        render: (value, data, index) => data?.branch_name ?? "-",
      },
      {
        id: "leader_name",
        title: "Nama Ketua Kelompok",
        dataIndex: "leaderName",
        sortable: true,
        render: (value, data, index) => data?.leader_name ?? "-",
      },
      {
        id: "group_name",
        title: "Nama Kelompok",
        dataIndex: "groupName",
        sortable: true,
        render: (value, data, index) => data?.group_name ?? "-",
      },
      {
        id: "registration_date",
        title: "Tanggal Registrasi",
        dataIndex: "registrationDate",
        columnClassName: "text-center",
        className: "text-center",
        sortable: true,
        render: (value, data, index) =>
          data?.registration_date
            ? moment(data?.registration_date).format("DD-MM-YYYY")
            : "-",
      },
      {
        id: "distribution_batch",
        title: "Batch Penyaluran",
        dataIndex: "distributionBatch",
        columnClassName: "text-center",
        className: "text-center",
        sortable: true,
        render: (value, data, index) => data?.distribution_batch ?? "-",
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        sortable: true,
        width: 160,
        columnClassName: "text-center min-w-[120px]",
        className: "text-center min-w-[120px]",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex justify-center gap-2">
            <BsEye
              className={`text-gray-600 cursor-pointer`}
              size={20}
            />
            <BiPencil
              className={`text-gray-600 cursor-pointer`}
              size={20}
              onClick={() => handleEditClick(data.id)}
            />
          </div>
        ),
      },
    ];
  }, [debtorAgreement, handleEditClick]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Perjanjian Debitur 3 Pihak</TitleText>
        <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={() => navigate(`/debtor-agreement/form`)}
        >
          Tambah Data
        </Button>
      </div>

      {/* Filter */}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <SearchInput placeholder="Cari" onChange={onSearch} />
            </div>
          </div>
        </div>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {/* Table */}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumnsDebtorAgreement}
              dataSource={[]}
              isLoading={false}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={debtorAgreement?.paginator}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebtorAgreement;
