import { yupResolver } from "@hookform/resolvers/yup";
import {
  Breadcrumbs,
  Button,
  CollapsingCard,
  Input,
  Label,
  Table,
} from "components";
import AsyncDropzoneFieldForm from "components/HookForm/AsyncDropzoneFieldForm";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import {
  useAgreementGroupMemberDebtorDetail,
  usePutAgreementGroupMemberDebtor,
} from "hooks/directDistribution";
import { enqueueSnackbar } from "notistack";
import { getFileFromUrl } from "pages/Agreement/BankTrusty/Upload";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillFilePdf } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { BsDownload, BsEyeFill } from "react-icons/bs";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getDebtorAgreementDetail } from "services/directDistribution";
import { getFile } from "services/fileService";
import { createFileRequest } from "utils";

import * as yup from "yup";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
    path: "/agreement-direct-distribution",
  },
  {
    label: "Kontrak Perjanjian",
    path: -1,
  },
  {
    label: "Debitur",
  },
];

const validationSchema = yup.object({
  doc_agreement: yup
    .mixed()
    .test(
      "required",
      "Dokumen perjanjian notaris tidak boleh kosong",
      (files) => {
        return !!files && files.length > 0;
      }
    ),
  doc_fiduasia: yup
    .mixed()
    .test("required", "Dokumen akta fiduasia tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
  doc_rights: yup
    .mixed()
    .test("required", "Dokumen hak tanggungan tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
});

const IndividualWithinGroupDebtor = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { id, debtorId, applicantId } = useParams();

  const mutatePutAgreementGroupMemberDebtor =
    usePutAgreementGroupMemberDebtor();

  const { data: debtor } = useAgreementGroupMemberDebtorDetail(
    ["GET_DETAIL_DEBTOR", `${applicantId}`],
    { enabled: !!applicantId, select: (data) => data?.result }
  );

  const [supportingDoc, setSupportingDoc] = useState();
  const [draftTemplate, setdraftTemplate] = useState();
  const [tallyDoc, setTallyDoc] = useState();

  const { data: agreementDetail } = useQuery(
    ["GET_DETAIL_AGREEMENT_DETAIL", applicantId],
    getDebtorAgreementDetail
  );

  const handleGetSupportingDocument = useCallback(async () => {
    if (!debtor) return;

    let supporting_document = {
      identity_file: null,
      family_card_file: null,
      offerring_doc: null,
      fidusia_doc: null,
    };

    // GET FILE IDENTITY
    if (debtor?.supporting_doc?.identity_file !== "") {
      const identityFile = await getFile(debtor?.supporting_doc?.identity_file);

      supporting_document.identity_file = identityFile;
    }

    // GET FILE FAMILY CARD
    if (debtor?.supporting_doc?.family_card_file !== "") {
      const familyCardFile = await getFile(
        debtor?.supporting_doc?.family_card_file
      );

      supporting_document.family_card_file = familyCardFile;
    }

    // GET FILE OFFERING FILE
    if (debtor?.supporting_doc?.offerring_doc !== "") {
      const offeringFile = await getFile(debtor?.supporting_doc?.offerring_doc);

      supporting_document.offerring_doc = offeringFile;
    }

    setSupportingDoc([supporting_document]);
  }, [debtor]);

  const handleDraftTemplate = useCallback(async () => {
    if (!debtor) return;

    if (debtor?.doc_agreement_draft_template) {
      const draftTemplateFile = await getFile(
        debtor?.doc_agreement_draft_template[0]?.id
      );

      setdraftTemplate(debtor?.doc_agreement_draft_template[0]?.id);
      // setdraftTemplate(draftTemplateFile);
    }
  }, [debtor]);

  const handleTallyDocFile = useCallback(async () => {
    if (!debtor) return;
    const tallyDoc = await getFile(debtor?.guarantee_data?.tally_doc);

    setTallyDoc(tallyDoc);
  }, [debtor]);

  useEffect(() => {
    handleGetSupportingDocument();
  }, [handleGetSupportingDocument]);

  useEffect(() => {
    handleDraftTemplate();
  }, [handleDraftTemplate]);

  useEffect(() => {
    handleTallyDocFile();
  }, [handleTallyDocFile]);

  const tableColumnJaminan = useMemo(
    () => [
      {
        id: "",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(debtor?.guarantee_data?.tally_sheets?.paginator.page) *
              parseInt(debtor?.guarantee_data?.tally_sheets?.paginator.limit) -
            parseInt(debtor?.guarantee_data?.tally_sheets?.paginator.limit) +
            index +
            1;

          return no;
        },
      },
      {
        id: "type",
        title: "Jenis Pohon",
        dataIndex: "type",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.type}
            </div>
          );
        },
      },
      {
        id: "area",
        title: "Keliling",
        dataIndex: "area",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.area ? (
                <>
                  {data?.area}m<sup>2</sup>
                </>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        id: "condition",
        title: "Kondisi",
        dataIndex: "condition",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.condition}
            </div>
          );
        },
      },
      {
        id: "price",
        title: "Harga",
        dataIndex: "price",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data?.price
                ? `Rp.${Math.floor(data?.price).toLocaleString()}`
                : "-"}
            </div>
          );
        },
      },
    ],
    [debtor?.guarantee_data?.tally_sheets]
  );

  const tableColumnDocumenPendukung = useMemo(
    () => [
      {
        id: "",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          return index + 1;
        },
      },
      {
        id: "identity_file",
        title: "KTP",
        dataIndex: "identity_file",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              <button
                className="font-semibold hover:underline cursor-pointer"
                onClick={() => {
                  window.open(data?.identity_file?.url);
                }}
              >
                {data.identity_file ? "KTP File" : "-"}
              </button>
            </div>
          );
        },
      },
      {
        id: "family_card_file",
        title: "KK",
        dataIndex: "family_card_file",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.family_card_file ? (
                <button
                  className="font-semibold hover:underline cursor-pointer"
                  onClick={() => {
                    window.open(data?.family_card_file?.url);
                  }}
                >
                  KK File
                </button>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        id: "offerring_doc",
        title: "Dokumen Penawaran",
        dataIndex: "offerring_doc",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.offerring_doc ? (
                <button
                  className="font-semibold hover:underline cursor-pointer"
                  onClick={() => {
                    window.open(data?.offerring_doc?.url);
                  }}
                >
                  Penawaran File
                </button>
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      // {
      //   id: "fidusia_doc",
      //   title: "Dokumen Fidusia",
      //   dataIndex: "fidusia_doc",
      //   className: "overflow-hidden text-ellipsis",
      //   width: 540,
      //   render: (value, data, index) => {
      //     return (
      //       <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
      //         {data.fidusia_doc ? (
      //           <button
      //             className="font-semibold hover:underline cursor-pointer"
      //             onClick={() => {
      //               window.open(data?.fidusia_doc?.url);
      //             }}
      //           >
      //             Fidusia Doc
      //           </button>
      //         ) : (
      //           "-"
      //         )}
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  const onSubmit = async (data) => {
    const payload = {};

    // await createFileRequest(data?.doc_agreement).then((files) => {
    //   payload.doc_agreement = files;
    // });
    // await createFileRequest(data?.doc_fiduasia).then((files) => {
    //   payload.doc_fiduasia = files;
    // });
    // await createFileRequest(data?.doc_rights).then((files) => {
    //   payload.doc_rights = files;
    // });

    payload.doc_rights = [await getFile(data?.doc_rights)];
    payload.doc_agreement = [await getFile(data?.doc_agreement)];
    payload.doc_fiduasia = [await getFile(data?.doc_fiduasia)];

    console.log('payload', payload)

    mutatePutAgreementGroupMemberDebtor.mutate(
      { id: debtorId, payload },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Kontrak perjanjian debitur berhasil diubah",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Kontrak perjanjian debitur gagal diubah",
          });
          console.log(err);
        },
      }
    );
  };

  const resetForm = useCallback(async () => {
    if (!debtor) return;

    // const blobAgreement = await getFileFromUrl(debtor?.doc_agreement ?? []);
    // const blobFiduasia = await getFileFromUrl(debtor?.doc_fiduasia ?? []);
    // const blobRights = await getFileFromUrl(debtor?.doc_rights ?? []);


    const fidusiaFileId = debtor?.doc_fiduasia[0]?.id;
    const agreementFileId = debtor?.doc_agreement[0]?.id;
    const rightsFileId = debtor?.doc_rights[0]?.id;
    const draftFileId = debtor?.doc_agreement_draft_template[0]?.id;

    methods.reset({
      doc_agreement_draft_template: draftFileId,
      doc_agreement: agreementFileId,
      doc_rights: rightsFileId,
      doc_fiduasia: fidusiaFileId,
    });
  }, [debtor, methods]);

  const doc_agreement_draft_template = methods.watch('doc_agreement_draft_template')

  console.log("method", methods.getValues())

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <CollapsingCard title="Profile Debitur">
        <div className="flex gap-4 min-h-[150px]">
          <div className="w-40 h-40 border"></div>
          <div className="flex flex-grow justify-between">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col gap-2 max-w-[336px]">
                <div className="flex">
                  <span className="text-sm font-bold w-40">Nama Debitur</span>
                  <span className="text-sm">
                    {debtor?.debtor_profile?.debtor_name}
                  </span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">NIK</span>
                  <span className="text-sm">
                    {debtor?.debtor_profile?.nip
                      ? debtor?.debtor_profile?.nip
                      : "-"}
                  </span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">NAP</span>
                  <span className="text-sm">
                    {debtor?.debtor_profile?.nap !== ""
                      ? debtor?.debtor_profile?.nap
                      : "-"}
                  </span>
                </div>
              </div>

              <div className="flex flex-col gap-2 max-w-[336px]">
                <div className="flex">
                  <span className="text-sm font-bold w-40">Bank</span>
                  <span className="text-sm">
                    {debtor?.debtor_profile?.bank !== ""
                      ? debtor?.debtor_profile?.bank
                      : "-"}
                  </span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">
                    Pengeluaran/bln
                  </span>
                  <span className="text-sm">
                    {debtor?.debtor_profile?.monthly_expenses !== ""
                      ? `Rp. 
                    ${Math.floor(
                      debtor?.debtor_profile?.monthly_expenses
                    ).toLocaleString()}`
                      : "-"}
                  </span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">Pekerjaan</span>
                  <span className="text-sm">
                    {debtor?.debtor_profile?.main_job}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between mr-10">
              <div className="flex flex-col">
                <div className="flex flex-col gap-2 max-w-[336px]">
                  <div className="flex">
                    <span className="text-sm font-bold w-40">Alamat</span>
                    <span className="text-sm w-full">
                      {debtor?.debtor_profile?.address}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">Longitude:</span>
                    <span className="text-sm w-full">
                      {debtor?.debtor_profile?.longitude}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">Latitude:</span>
                    <span className="text-sm w-full">
                      {debtor?.debtor_profile?.latitude}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex flex-col gap-2 max-w-[336px]">
                  <div className="flex">
                    <span className="text-sm font-bold w-40">
                      Nomor Rekening
                    </span>
                    <span className="text-sm ">
                      {debtor?.debtor_profile?.bank_account !== ""
                        ? debtor?.debtor_profile?.bank_account
                        : "-"}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">
                      Sumber pengeluaran
                    </span>
                    <span className="text-sm ">
                      {debtor?.debtor_profile?.expense_source !== ""
                        ? debtor?.debtor_profile?.expense_source
                        : "-"}
                    </span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">
                      Pendapatan/bln
                    </span>
                    <span className="text-sm ">
                      {debtor?.debtor_profile?.monthly_expenses !== ""
                        ? `Rp. 
                      ${Math.floor(
                        debtor?.debtor_profile?.monthly_expenses
                      ).toLocaleString()}`
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CollapsingCard>

      <FormProvider {...methods}>
        <CollapsingCard title="Data Pokok Pemohon" isCollapse={false}>
          <div className="grid grid-cols-4 gap-x-5 gap-y-7">
            <div className="flex flex-col gap-2">
              <Label>No Kepdirut</Label>
              <Input
                disabled
                name="delegation_decision_number"
                className="w-full"
                defaultValue={
                  debtor?.applicant_data?.delegation_decision_number !== ""
                    ? debtor?.applicant_data?.delegation_decision_number
                    : "-"
                }
                placeholder="Masukan No Kepdirut"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Provinsi</Label>
              <Input
                disabled
                name="province"
                className="w-full"
                defaultValue={debtor?.applicant_data?.province}
                placeholder="Masukan Provinsi"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Kabupaten</Label>
              <Input
                disabled
                name="regency"
                className="w-full"
                defaultValue={debtor?.applicant_data?.regency}
                placeholder="Masukan Kabupaten"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Kecamatan</Label>
              <Input
                disabled
                name="district"
                className="w-full"
                defaultValue={debtor?.applicant_data?.subdistrict}
                placeholder="Masukan Kecamatan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Kelurahan</Label>
              <Input
                disabled
                name="subdistrict"
                className="w-full"
                defaultValue={debtor?.applicant_data?.village}
                placeholder="Masukan Kelurahan"
              />
            </div>

            {/* <div className="flex flex-col gap-2">
              <Label>Status Perkawinan</Label>
              <Input
                disabled
                name="marital_status"
                className="w-full"
                defaultValue={
                  debtor?.applicant_data?.marital_status !== ""
                    ? debtor?.applicant_data?.marital_status
                    : "-"
                }
                placeholder="Masukan Status Perkawinan"
              />
            </div> */}

            <div className="flex flex-col gap-2">
              <Label>Nama Pasangan</Label>
              <Input
                disabled
                name="spouse_name"
                className="w-full"
                defaultValue={debtor?.applicant_data?.partner_name}
                placeholder="Masukan Nama Pasangan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>NIK Pasangan</Label>
              <Input
                disabled
                name="spouse_nik"
                className="w-full"
                defaultValue={debtor?.applicant_data?.partner_identity_number}
                placeholder="Masukan NIK Pasangan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>No Hp/Tlpn</Label>
              <Input
                disabled
                name="phone"
                className="w-full"
                defaultValue={debtor?.applicant_data?.phone_number}
                placeholder="Masukan No Hp/Tlpn"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Email</Label>
              <Input
                disabled
                name="email"
                className="w-full"
                defaultValue={debtor?.applicant_data?.email}
                placeholder="Masukan email"
              />
            </div>
          </div>
        </CollapsingCard>

        {searchParams.get("group_type") === "Non Perhutanan Sosial" && (
          <CollapsingCard title="Data Jaminan" isCollapse={false}>
            <div className="grid grid-cols-3 gap-5 mb-5">
              <div className="flex flex-col gap-2">
                <Label>Nama</Label>
                <p className="text-sm">
                  {debtor?.guarantee_data?.guarantor_name !== ""
                    ? debtor?.guarantee_data?.guarantor_name
                    : "-"}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <Label>Blok</Label>
                <p className="text-sm">
                  {debtor?.guarantee_data?.block !== ""
                    ? debtor?.guarantee_data?.block
                    : "-"}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <Label>Luas</Label>
                <p className="text-sm">
                  {debtor?.guarantee_data?.wide !== ""
                    ? debtor?.guarantee_data?.wide
                    : "-"}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <Label>Jumlah Pohon</Label>
                <p className="text-sm">
                  {debtor?.guarantee_data?.total_tree !== ""
                    ? debtor?.guarantee_data?.total_tree
                    : "-"}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <Label>No SPPT</Label>
                <p className="text-sm">
                  {debtor?.guarantee_data?.sppt_number !== ""
                    ? debtor?.guarantee_data?.sppt_number
                    : "-"}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <Label>Atas Nama</Label>
                <p className="text-sm">
                  {debtor?.guarantee_data?.owner !== ""
                    ? debtor?.guarantee_data?.owner
                    : "-"}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mb-5">
              <div className="flex flex-col gap-2">
                <Label>Total Jaminan</Label>
                <Input
                  disabled
                  name=""
                  className="w-full"
                  defaultValue={debtor?.guarantee_data?.total_value}
                />
              </div>

              {/* <div className="flex flex-col gap-2 justify-center">
                <Label>Total Jaminan</Label>
                <label
                  htmlFor="total_guarantee"
                  className="flex gap-2 items-center"
                >
                  <input
                    type="checkbox"
                    name=""
                    id="total_guarantee"
                    className="w-4 h-4 accent-primary-700"
                  />
                  <Label>Terpenuhi Sebanyak 125%</Label>
                </label>
              </div> */}
            </div>

            <div className="mb-5">
              <Table
                bordered
                stripped
                layout="auto"
                columns={tableColumnJaminan}
                dataSource={debtor?.guarantee_data?.tally_sheets?.data}
                pagination={debtor?.guarantee_data?.tally_sheets?.paginator}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Download Data Jaminan Dibawah ini & Upload</Label>
              <div className="flex border rounded-lg p-4 max-w-[406px] w-full items-center">
                <div className="flex gap-3 flex-grow">
                  <AiFillFilePdf size={40} fill="#00BD52" />
                  <div className="flex flex-col gap-1">
                    <Label>File Jaminan</Label>
                    <p className="text-xs">
                      {tallyDoc?.name} ({(tallyDoc?.size / 1024).toFixed(0)} KB)
                    </p>
                  </div>
                </div>
                <Button
                  size="sm"
                  className="flex gap-2 bg-primary-700 hover:bg-primary-600 text-white"
                  onClick={() => {
                    window.open(tallyDoc?.url, "_blank");
                  }}
                >
                  <BsDownload />
                  <h4>Download</h4>
                </Button>
              </div>
            </div>
          </CollapsingCard>
        )}

        <CollapsingCard title="Daftar Dokumen Pendukung" isCollapse={false}>
          <Table
            columns={tableColumnDocumenPendukung}
            dataSource={supportingDoc}
            showPagination={false}
          />
        </CollapsingCard>

        <CollapsingCard title="Pembiayaan" isCollapse={false}>
          <div className="grid grid-cols-2 gap-5 mb-5">
            <div className="flex flex-col gap-2">
              <Label>Jenis Usaha</Label>
              <Input
                defaultValue={debtor?.loan?.business_type}
                className=""
                disabled
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Komoditas Usaha</Label>
              <Input
                value={
                  debtor?.loan?.business_comodities
                    ? debtor?.loan?.business_comodities
                    : "-"
                }
                disabled
                className="w-full"
                placeholder="Masukan Komoditas Usaha"
              />
            </div>

            <div className="flex flex-col gap-2 col-span-2">
              <Label>Lama Usaha</Label>
              <Input
                disabled
                name=""
                className="w-full"
                value={
                  debtor?.loan?.business_cycle
                    ? debtor?.loan?.business_cycle
                    : "-"
                }
                placeholder="Masukan Lama Usaha (Tahun)"
              />
            </div>
            {/* <div className="flex flex-col gap-2">
              <Label>Produktivitas/Hasil Panen Terakhir</Label>
              <Input
                disabled
                name=""
                className="w-full"
                value={
                  debtor?.loan?.last_productivity
                    ? debtor?.loan?.last_productivity
                    : "-"
                }
                placeholder="Masukan Produktivitas/Hasil Panen Terakhir"
              />
            </div> */}

            {/* <div className="flex flex-col gap-2">
              <Label>Harga (Penjualan Terakhir)</Label>
              <Input
                disabled
                name=""
                className="w-full"
                placeholder="Rp."
                value={
                  debtor?.loan?.last_price
                    ? `Rp. ${Math.floor(
                        debtor?.loan?.last_price
                      ).toLocaleString()}`
                    : "-"
                }
              />
            </div> */}
            {/* <div className="flex flex-col gap-2">
              <Label>
                Luasan Lahan Yang digarap (m<sup>2</sup>)
              </Label>
              <Input
                disabled
                name=""
                className="w-full"
                value={
                  debtor?.loan?.total_member_apply_area
                    ? debtor?.loan?.total_member_apply_area
                    : "-"
                }
                placeholder="Masukan Luasan Lahan Yang digarap"
              />
            </div> */}
          </div>

          <div className="grid grid-cols-2 gap-5 mb-5">
            {/* <div className="flex flex-col gap-2">
              <Label>Omzet Usaha</Label>
              <Input
                value={
                  debtor?.loan?.omzet
                    ? `Rp. ${Math.floor(debtor?.loan?.omzet).toLocaleString()}`
                    : undefined
                }
                className=""
                disabled
              />
              
            </div> */}
            <div className="flex flex-col gap-2">
              <Label>HPP/Modal</Label>
              <Input
                name=""
                className="w-full"
                placeholder="Rp."
                value={
                  debtor?.loan?.cost_price
                    ? `Rp. ${Math.floor(
                        debtor?.loan?.cost_price
                      ).toLocaleString()}`
                    : undefined
                }
                disabled
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Pendapatan Bersih</Label>
              <Input
                value={
                  debtor?.loan?.net_income
                    ? `Rp. ${Math.floor(
                        debtor?.loan?.net_income
                      ).toLocaleString()}`
                    : undefined
                }
                className=""
                disabled
              />
              {/* <InputForm
                disabled
                name=""
                className="w-full"
                placeholder="Rp."
                value={data?.net_income}
              /> */}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5 mb-5">
            <div className="flex flex-col gap-2">
              <Label>Tujuan Pemasaran</Label>
              <Input
                disabled
                name=""
                className="w-full"
                value={
                  debtor?.loan?.market_target
                    ? debtor?.loan?.market_target
                    : "-"
                }
                placeholder="Masukan Tujuan Pemasaran"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Mitra Usaha (Jika Ada)</Label>
              <Input
                disabled
                name=""
                className="w-full"
                value={
                  debtor?.loan?.business_partner
                    ? JSON.parse(debtor?.loan?.business_partner).join(", ")
                    : ""
                }
                placeholder="Masukan Mitra Usaha (Jika Ada)"
              />
            </div>
          </div>

          {/* <div className="flex flex-col gap-2 mb-5">
            <Label>Tipe Usaha</Label>
            <div className="flex gap-4">
              <label htmlFor="label1" className="flex gap-2 cursor-pointer">
                <input
                  type="radio"
                  className="accent-primary-700"
                  name="radio"
                  id="label1"
                  disabled
                />
                <Label>Dikelola Sendiri</Label>
              </label>
              <label htmlFor="label2" className="flex gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="radio"
                  className="accent-primary-700"
                  id="label2"
                  disabled
                />
                <Label>Dikelola Orang Lain</Label>
              </label>
            </div>
          </div> */}

          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-2 col-span-2">
              <Label>Siklus Usaha</Label>
              {/* <CustomSelect
                name=""
                className=""
                optionsData={[]}
                placeholder="Pilih Siklus Usaha"
              /> */}
              <Input
                value={debtor?.loan?.business_cycle}
                className=""
                disabled
              />
            </div>
            {/* <div className="flex flex-col gap-2">
              <Label>Kuantitas Komoditas yang sudah ada</Label>
              <Input
                disabled
                name=""
                className="w-full"
                value={
                  debtor?.loan?.existing_commodity_quantity
                    ? debtor?.loan?.existing_commodity_quantity
                    : "-"
                }
                placeholder="Masukan Kuantitas Komoditas yang sudah ada"
              />
            </div> */}
          </div>
        </CollapsingCard>

        <CollapsingCard title="Pembuatan Dokumen Perjanjian" isCollapse={false}>
          <div className="flex gap-4 items-center">
            {draftTemplate ? (
              // <div className="flex items-center p-4 border rounded-lg mt-2 w-full justify-between">
              //   <div className="flex gap-8">
              //     <AiFillFilePdf size={40} fill="#00BD52" />
              //     <div className="flex flex-col">
              //       <span className="text-base font-medium">
              //         {draftTemplate.name ?? "-"}
              //       </span>
              //       <span className="text-xs">
              //         ({draftTemplate.size ?? 0}KB)
              //       </span>
              //     </div>
              //   </div>
              //   <div className="flex gap-2">
              //     <BiPencil
              //       size={18}
              //       fill="#667085"
              //       className="hover:fill-sky-600 cursor-pointer"
              //       onClick={() => {
              //         const contractState = {
              //           doc_agreement_draft_template:
              //             debtor?.doc_agreement_draft_template,
              //           agreement_draft_name: debtor?.agreement_draft_name,
              //           agreement_draft_number: debtor?.agreement_draft_number,
              //           agreement_draft_date: debtor?.agreement_draft_date,
              //         };
              //         navigate(`draft`, { state: contractState });
              //       }}
              //     />
              //     <a href={draftTemplate.url} target="_blank" rel="noreferrer">
              //       <BsEyeFill
              //         size={18}
              //         fill="#667085"
              //         className="hover:fill-sky-600 cursor-pointer"
              //       />
              //     </a>
              //   </div>
              // </div>

              <AsyncDropzoneFieldForm
                name="doc_agreement_draft_template"
                informationText="PDF"
                maxSize={10}
                maxFiles={1}
              />
            ) : (
              <>
                <Label>Lihat Draft Perjanjian:</Label>
                <Button
                  className="bg-primary-700 hover:bg-primary-600 text-white"
                  onClick={() => {
                    const contractState = {
                      doc_agreement_draft_template:
                        debtor?.doc_agreement_draft_template,
                      agreement_draft_name: debtor?.agreement_draft_name,
                      agreement_draft_number: debtor?.agreement_draft_number,
                      agreement_draft_date: debtor?.agreement_draft_date,
                    };
                    navigate(`draft`, { state: contractState });
                  }}
                >
                  Buat Kontrak Perjanjian
                </Button>
              </>
            )}
          </div>
        </CollapsingCard>

        <CollapsingCard
          title="Upload Dokumen Perjanjian Notaris"
          isCollapse={false}
        >
          <div className="flex flex-col gap-2">
            <Label>Upload Dokumen</Label>
            {/* <DropzoneFieldForm
              name="doc_agreement"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            /> */}
            <AsyncDropzoneFieldForm
              name="doc_agreement"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            />
          </div>
        </CollapsingCard>

        <CollapsingCard title="Upload Dokumen Akta Fiduasia" isCollapse={false}>
          <div className="flex flex-col gap-2">
            <Label>Upload Dokumen</Label>
            <AsyncDropzoneFieldForm
              name="doc_fiduasia"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            />
            {/* <DropzoneFieldForm
              name="doc_fiduasia"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            /> */}
          </div>
        </CollapsingCard>

        <CollapsingCard
          title="Upload Dokumen Hak Tanggungan"
          isCollapse={false}
        >
          <div className="flex flex-col gap-2">
            <Label>Upload Dokumen</Label>
            <AsyncDropzoneFieldForm
              name="doc_rights"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            />
            {/* <DropzoneFieldForm
              name="doc_rights"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            /> */}
          </div>
        </CollapsingCard>

        <CollapsingCard
          title="Positive & Negative Covenants"
          isCollapse={false}
        >
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold">Positive Convenants</h5>
              {debtor?.positive_covenants?.map((el, idx) => (
                <div className="flex flex-row gap-2" key={idx}>
                  <p>{el?.orderNumber}.</p>
                  <p>{el?.value}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold">Negative Convenants</h5>
              {debtor?.negative_covenants?.map((el, idx) => (
                <div className="flex flex-row gap-2" key={idx}>
                  <p>{el?.orderNumber}.</p>
                  <p>{el?.value}</p>
                </div>
              ))}
            </div>
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            type="button"
            onClick={() => navigate(-1)}
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default IndividualWithinGroupDebtor;
