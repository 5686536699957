import { RiFileSearchLine, RiBookReadLine, RiTodoLine, RiContactsFill, RiContactsLine } from "react-icons/ri";

const MENU_ITEMS = [
  {
    icon: (
      <RiFileSearchLine className="text-white w-[18px] h-[20px]" />
    ),
    id: "agreement",
    label: "Perjanjian",
    path: "/agreement",
    child: [
      {
        path: '/agreement-donor',
        label: 'Donor'
      },
      {
        path: '/agreement-bank-trusty',
        label: 'Bank Trustee'
      },
      {
        path: '/agreement-direct-distribution',
        label: 'FDB'
      },
      {
        path: '/lemtara',
        label: 'Lemtara'
      },
    ]
  },
  {
    icon: (
      <RiTodoLine className="text-white w-[18px] h-[20px]" />
    ),
    id: "collateral_dashboard",
    label: "Dashboard Collateral",
    path: "/collateral/dashboard",
  },
  {
    icon: (
      <RiBookReadLine className="text-white w-[18px] h-[20px]" />
    ),
    id: "collateral_handover",
    label: "Serah Terima Agunan",
    path: "/collateral/handover",
  },
  {
    icon: (
      <RiContactsLine className="text-white w-[18px] h-[20px]" />
    ),
    id: "notary",
    label: "Master Data Notaris",
    path: "/notary",
  },
  // {
  //   icon: (
  //     <RiTodoLine className="text-white w-[18px] h-[20px]" />
  //   ),
  //   id: "debtor_agreement",
  //   label: "Perjanjian Debitur LP",
  //   path: "/debtor-agreement",
  // },
];

export default MENU_ITEMS;