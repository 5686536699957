import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BiPlus, BiSolidPlusCircle, BiTrash } from "react-icons/bi";
import { useLocation, useNavigate, useParams } from "react-router";
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { map } from "lodash";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  CollapsingCard,
  Label,
} from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import Switch from "components/HookForm/Switch";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import { useTemplateList } from "hooks/template";
import {
  useBankTrustyAgreementDetail,
  useEditBankTrustyAgreement,
} from "hooks/bankTrusty";
import {
  createFileRequest,
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "utils";
import DraftTemplate from "assets/img/draft-template.png";

const trustySchema = yup.object({
  project_id: yup.string().required("ID projek tidak boleh kosong"),
  contract_number: yup.string().required("Nomor kontak tidak boleh kosong"),
  programs: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .test("required", "Program tidak boleh kosong", (program) => {
      return !!program && program.value;
    }),
  implementation_date: yup
    .string()
    .required("Tanggal penetapan tidak boleh kosong"),
  doc_template: yup.mixed().nullable(),
  doc_supporting: yup
    .mixed()
    .test("required", "Dokumen support tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
  doc_account_detail: yup.array().of(
    yup.object({
      bank_name: yup.string().required("Nama bank harus diisi"),
      bank_pic: yup.string().required("PIC tidak boleh kosong"),
      bank_phone_number: yup.string().required("No telpon tidak boleh kosong"),
      bank_address: yup.string().required("Alamat tidak boleh kosong"),
    })
  ),
  notes: yup.string().required("Notes tidak boleh kosong"),
});

const programOptions = [
  { value: "Program 1", label: "Program 1" },
  { value: "Program 2", label: "Program 2" },
];

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Perjanjian Bank Trustee",
    path: "/agreement-bank-trusty",
  },
  {
    label: "Upload Bank Trustee",
  },
];

export const getFileFromUrl = async (files) => {
  if (!files || files.length === 0) return [];
  const fileTemp = [];
  for (let file of files) {
    const response = await fetch(file?.url);

    const data = await response.blob();
    const blobFile = new File([data], file?.name, {
      type: data.type || file?.mimeType,
    });

    blobFile.path = file.name;
    blobFile.preview = file.url;

    fileTemp.push(blobFile);
  }

  return fileTemp;
};

const BankTrustyAgreementUpload = () => {
  const methods = useForm({
    resolver: yupResolver(trustySchema),
    defaultValues: {
      toggle_draft: false,
      project_id: "",
      proposal_title: "",
      donor_name: "",
      project_name: "",
      contract_number: "",
      programs: null,
      implementation_date: null,
      bank_account_details: null,
      doc_draft: [],
      doc_supporting: [],
      notes: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "bank_account_details",
  });

  const navigate = useNavigate();
  const { proposalId } = useParams();
  const { pathname } = useLocation();

  const [selectedDraft, setSelectedDraft] = useState(null);

  const templateSectionRef = useRef(null);

  const userId = { id: "12345678" };
  const formValues = getLocalStorage("form");

  const editBankTrustyAgreement = useEditBankTrustyAgreement(proposalId);
  const { data: templates } = useTemplateList(["template_list", userId]);
  useBankTrustyAgreementDetail(["bank_trusty_agreement_detail", proposalId], {
    enabled: !!proposalId,
    onSuccess: (response) => {
      const data = response.result;

      if (data.template_id) {
        setSelectedDraft({
          template_id: data.template_id,
          template_title: data.template_title,
          template_content: data.template_content,
        });
      }

      methods.reset({
        toggle_draft: data.doc_template && !formValues ? true : false,
        project_id: formValues?.project_id ?? data.project_id ?? "",
        proposal_title: formValues?.proposal_title ?? data.proposal_title,
        donor_name: formValues?.donor_name ?? data.donor_name,
        contract_number:
          formValues?.contract_number ?? data.contract_number ?? "",
        project_name: formValues?.project_name ?? data.project_name ?? "",
        implementation_date:
          formValues?.implementation_date ??
          moment(data.implementation_date).format("YYYY-MM-DD"),
        programs: formValues?.programs ?? {
          value: data.program_id,
          label: data.program_name,
        },
        bank_account_details:
          formValues?.bank_account_details ?? data.bank_account_details,
        notes: formValues?.notes ?? data.notes ?? "",
      });

      getFileFromUrl(data.doc_template ? [data.doc_template] : []).then(
        (files) => {
          methods.setValue("doc_template", files);
        }
      );

      getFileFromUrl(data.doc_supporting ?? []).then((files) => {
        methods.setValue("doc_supporting", files);
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const validateTemplate = () => {
    const templateFile = methods.watch("doc_template");

    if (!selectedDraft && !templateFile) {
      enqueueSnackbar(
        "Harap pilih/upload template perjanjian untuk melanjutkan",
        {
          variant: "error",
        }
      );

      window.scrollTo({
        top: templateSectionRef.current.offsetTop - 80,
        behavior: "smooth",
      });

      return false;
    }

    return true;
  };

  const onSubmit = async (data) => {
    if (!validateTemplate()) return;

    const payload = {
      user_id: "12345678",
      implementation_date: data.implementation_date,
      contract_number: data.contract_number,
      project_id: data.project_id,
      project_name: data.project_name,
      program_id: data.programs.value,
      program_name: data.programs.label,
      notes: data.notes,
      bank_account_details: data.bank_account_details,
    };

    if (methods.watch("toggle_draft")) {
      await createFileRequest(data.doc_template).then((files) => {
        payload.doc_template = files[0];
        payload.template_id = null;
        payload.template_title = null;
        payload.template_content = null;
      });
    } else {
      payload.doc_template = null;
      payload.template_id = selectedDraft.template_id;
      payload.template_title = selectedDraft.template_title;
      payload.template_content = selectedDraft.template_content;
    }

    await createFileRequest(data.doc_supporting).then((files) => {
      payload.doc_supporting = files;
    });

    editBankTrustyAgreement.mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar("Data perjanjian Bank Trustee berhasil diubah", {
          variant: "success",
        });

        removeLocalStorage("template");
        removeLocalStorage("form");
        setSelectedDraft(null);

        navigate("/agreement-bank-trusty", { state: { tab: 1 } });
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    });
  };

  const getDraftFromStorage = useCallback(() => {
    const templateDraft = getLocalStorage("template");
    setSelectedDraft(templateDraft);
  }, []);

  useEffect(() => {
    getDraftFromStorage();
  }, [getDraftFromStorage]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard title="Informasi Perjanjian" isCollapse={false}>
          <div className="flex w-full gap-4">
            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label>ID Project</Label>
                <InputForm
                  name="project_id"
                  className="w-full"
                  placeholder="Masukkan ID Project"
                />
              </div>

              <div className="flex flex-col w-full gap-1">
                <Label>Nomor Kontrak</Label>
                <InputForm
                  name="contract_number"
                  className="w-full"
                  placeholder="Masukkan Nomor Kontrak"
                />
              </div>

              <div className="flex flex-col w-full">
                <Label>Program</Label>
                <CustomSelectForm
                  options={programOptions}
                  name="programs"
                  placeholder="Pilih satu"
                />
              </div>
            </div>

            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label>Judul Proposal</Label>
                <InputForm
                  name="proposal_title"
                  className="w-full"
                  value="Proposal Kehutanan"
                  disabled
                />
              </div>

              <div className="flex flex-col w-full gap-1">
                <Label>Nama Project</Label>
                <InputForm
                  name="project_name"
                  className="w-full"
                  value="Indonesia Disaster Risk Finance & Insurance"
                  disabled
                />
              </div>
            </div>

            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label>Nama Donor</Label>
                <InputForm
                  name="donor_name"
                  className="w-full truncate"
                  value="International Bank For Reconstruction and Development"
                  disabled
                />
              </div>

              <div className="flex flex-col w-full gap-1">
                <Label>Tanggal Penetapan</Label>
                <InputForm
                  type="date"
                  className="w-full"
                  name="implementation_date"
                  placeholder="Pilih Tanggal"
                />
              </div>
            </div>
          </div>
        </CollapsingCard>

        <div ref={templateSectionRef}>
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>
                  {methods.watch("toggle_draft")
                    ? "Upload Template Perjanjian"
                    : "Template Perjanjian"}
                </div>
                <Switch name="toggle_draft" />
              </div>
            </CardHeader>
            <CardBody>
              {methods.watch("toggle_draft") ? (
                <DropzoneFieldForm name="doc_template" maxSize={10} />
              ) : (
                <div>
                  {selectedDraft ? (
                    <div className="flex items-center w-full gap-8 flex-nowrap overflow-x-auto">
                      <div className="flex flex-col items-center w-fit relative">
                        <div
                          className="w-40 h-fit cursor-pointer"
                          onClick={() => {
                            setLocalStorage("form", methods.getValues());

                            navigate(`/template/draft`, {
                              state: {
                                path: pathname,
                                template_id: selectedDraft?.template_id,
                                template_title: selectedDraft?.template_title,
                                template_content:
                                  selectedDraft?.template_content,
                              },
                            });
                          }}
                          onKeyDown={() => {}}
                        >
                          <img
                            src={DraftTemplate}
                            alt="draft-template"
                            className="w-full h-full border border-slate-300 rounded-lg"
                          />
                        </div>
                        <p className="">{selectedDraft?.template_title}</p>
                        <div
                          className="absolute top-2 right-2 bg-black/10 p-[6px] rounded-md hover:text-red-600 z-20 cursor-pointer"
                          onClick={() => {
                            removeLocalStorage("template");
                            getDraftFromStorage();
                          }}
                          onKeyDown={() => {}}
                        >
                          <RiDeleteBin6Line size={18} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center w-full gap-8 flex-nowrap overflow-x-auto">
                      {map(templates?.result ?? [], (template, key) => (
                        <div
                          key={key}
                          className="flex flex-col items-center w-fit"
                        >
                          <div
                            className="w-40 h-fit cursor-pointer"
                            onClick={() => {
                              setLocalStorage("form", methods.getValues());
                              navigate(`/template/${template?.id}/edit`, {
                                state: {
                                  path: pathname,
                                  template_title: template?.title,
                                  template_content: template?.content,
                                },
                              });
                            }}
                            onKeyDown={() => {}}
                          >
                            <img
                              src={DraftTemplate}
                              alt=""
                              className="w-full h-full border border-slate-300 rounded-lg"
                            />
                          </div>
                          <p className="">{template?.title}</p>
                        </div>
                      ))}
                      <BiSolidPlusCircle
                        onClick={() =>
                          navigate("/template/new", {
                            state: { path: pathname },
                          })
                        }
                        size={100}
                        fill="#01A24A"
                        className="cursor-pointer hover:fill-primary-600 flex-none"
                      />
                    </div>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </div>

        <CollapsingCard title="Informasi Bank" isCollapse={false}>
          <div className="border border-solid border-gray-200 rounded-tl-2xl rounded-tr-2xl">
            <div className="overflow-x-auto">
              <table className="table table-striped min-w-full">
                <thead>
                  <tr>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tl-2xl">
                      Nama Bank
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                      PIC
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                      Nomor Telepon
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                      Kantor Cabang / Alamat
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tr-2xl">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, key) => {
                    return (
                      <tr key={field.id}>
                        <td>
                          <InputForm
                            name={`bank_account_details.${key}.bank_name`}
                            className="w-full"
                            disabled
                          />
                        </td>
                        <td>
                          <InputForm
                            name={`bank_account_details.${key}.bank_pic`}
                            className="w-full"
                          />
                        </td>
                        <td>
                          <InputForm
                            name={`bank_account_details.${key}.bank_phone_number`}
                            className="w-full"
                          />
                        </td>
                        <td>
                          <InputForm
                            name={`bank_account_details.${key}.bank_address`}
                            className="w-full"
                          />
                        </td>
                        <td>
                          {fields.length > 1 && (
                            <Button
                              onClick={() => {
                                remove(key);
                              }}
                              iconOnly
                              className="w-fit bg-red-500"
                            >
                              <BiTrash fill="#fff" size={15} />
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={5} align="right">
                      <Button
                        onClick={() =>
                          append({
                            bank_name: fields?.bank_name,
                            bank_pic: "",
                            bank_phone_number: "",
                            bank_address: "",
                          })
                        }
                        startIcon={<BiPlus fill="#fff" size={15} />}
                        className="w-fit bg-primary-600 text-white hover:bg-primary-700"
                      >
                        Tambah Informasi Bank
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Upload Dokumen Pendukung" isCollapse={false}>
          <div className="w-full">
            <DropzoneFieldForm
              name="doc_supporting"
              informationText="PDF"
              accept={{
                "application/pdf": [],
              }}
              maxFiles={30}
              maxSize={10}
              multiple={false}
            />
          </div>
        </CollapsingCard>

        <CollapsingCard title="Notes/Change Log" isCollapse={false}>
          <InputForm
            type="textarea"
            rows={3}
            name="notes"
            className="w-full"
            placeholder="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo, consequatur?"
          />
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            type="button"
            onClick={() =>
              navigate("/agreement-bank-trusty", { state: { tab: 1 } })
            }
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default BankTrustyAgreementUpload;
