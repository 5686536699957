import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import {
  useBankTrustyAgreementList,
  useBankTrustyAgreementListFromPPD,
  useBankTrustyList,
} from "hooks/bankTrusty";
import { debounce, map } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import { removeLocalStorage } from "utils";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Perjanjian Bank Trustee",
  },
];

const tabs = [
  {
    label: "Daftar Agenda Penandatanganan Bank Trustee",
  },
  {
    label: "Daftar Bank Trustee Dalam Proses Submit",
  },
];

const BankTrustyAgreement = () => {
  // Initialization
  const navigate = useNavigate();

  const { state } = useLocation();

  // State
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tabActive, setTabActive] = useState(0);
  const [keyword, setKeyword] = useState(undefined);
  const [year, setYear] = useState(undefined);

  // =============== Effect
  useEffect(() => {
    if (state) {
      const { tab } = state;
      setTabActive(tab);
    }
  }, [state]);

  const { isLoading: isLoadingBankTrustyPPD, data: bankTrustyPPD } =
    useBankTrustyAgreementListFromPPD([
      "bank_trusty_agreement_list_from_ppd",
      {
        page,
        limit,
        search: keyword ?? undefined,
      },
    ]);

  const { isLoading: isLoadingBankTrustyAgreement, data: bankTrustyAgreement } =
    useBankTrustyList([
      "bank_trusty_agreement_list",
      {
        page,
        limit,
        keyword: keyword ?? undefined,
        year: year ? year.value : undefined,
      },
    ]);

  const yearsArray = useMemo(() => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = 2000;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    // Get years value from currentYear to startYear
    years.reverse();

    return years.map((year) => ({ value: year, label: year }));
  }, []);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    800
  );

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const handleEditClick = useCallback(
    (id) => {
      removeLocalStorage("form");
      removeLocalStorage("template");
      navigate(`/agreement-bank-trusty/${id}/upload`);
    },
    [navigate]
  );

  const tableColumnsListBankTrusty = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(bankTrustyPPD?.paginator?.page) *
              parseInt(bankTrustyPPD.paginator?.limit) -
            parseInt(bankTrustyPPD?.paginator?.limit) +
            index +
            1;
          return no + ".";
        },
      },
      {
        id: "count_proposal",
        title: "Jumlah Proposal",
        dataIndex: "countProposal",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.countProposal ?? "-"}
          </div>
        ),
      },
      {
        id: "donor_name",
        title: "Nama Donor",
        dataIndex: "donorName",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.name ?? "-"}
          </div>
        ),
      },
    ];
  }, [bankTrustyPPD]);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(bankTrustyAgreement?.paginator?.page) *
              parseInt(bankTrustyAgreement?.paginator?.limit) -
            parseInt(bankTrustyAgreement?.paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}.
            </div>
          );
        },
      },
      {
        id: "proposal_title",
        title: "Judul Proposal",
        dataIndex: "proposalTitle",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.proposal_title ?? "-"}
          </div>
        ),
      },
      {
        id: "implementation_date",
        title: "Tanggal Penetapan",
        dataIndex: "ImplementationDate",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.implementation_date
              ? moment(data?.implementation_date).format("DD-MM-YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "invitation_number",
        title: "No Perjanjian",
        dataIndex: "invitationNumber",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.contract_number ?? "-"}
          </div>
        ),
      },
      {
        id: "donor_name",
        title: "Nama Donor",
        dataIndex: "donorName",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donor_name ?? "-"}
          </div>
        ),
      },
      // {
      //   id: "submit_date",
      //   title: "Tanggal Pengajuan",
      //   dataIndex: "submitDate",
      //   width: 540,
      //   className: "overflow-hidden text-ellipsis",
      //   sortable: true,
      //   render: (value) => (
      //     <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
      //       {moment(value).format("DD-MM-YYYY")}
      //     </div>
      //   ),
      // },
      {
        id: "currency",
        title: "Mata Uang",
        dataIndex: "currency",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.currency ?? "-"}
          </div>
        ),
      },
      // {
      //   id: "account_owner",
      //   title: "Nama Akun Pemilik",
      //   dataIndex: "accountOwner",
      //   width: 540,
      //   className: "overflow-hidden text-ellipsis",
      //   sortable: true,
      //   render: (value, data, index) => (
      //     <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
      //       {data?.bank_account_name ?? "-"}
      //     </div>
      //   ),
      // },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex">
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleEditClick(data?.id)}
            />
          </div>
        ),
      },
    ];
  }, [bankTrustyAgreement, handleEditClick]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Daftar Perjanjian Bank Trustee</TitleText>
        <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={() => navigate(`/agreement-bank-trusty/form`)}
        >
          Buat Rencana Pelaksanaan Perjanjian
        </Button>
      </div>

      {/* Filter */}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <SearchInput placeholder="Pencarian" onChange={onSearch} />
            </div>
            {tabActive !== 0 && (
              <div className="flex-1">
                <CustomSelect
                  value={year}
                  placeholder="Tahun"
                  optionsData={yearsArray}
                  onChange={setYear}
                  isClearable={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {/* Tabs */}
            {/* <div className="tabs">
              {map(tabs, (tab, key) => (
                <a
                  onClick={() => setTabActive(key)}
                  className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
                    key === tabActive
                      ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                      : "border-b"
                  }`}
                >
                  {tab.label}
                </a>
              ))}
            </div> */}

            {/* Table */}
            <Table
              bordered
              stripped
              layout="auto"
              className="mb-4"
              columns={
                // tabActive === 0 ? tableColumnsListBankTrusty : tableColumns
                tableColumns
              }
              dataSource={
                // tabActive === 0
                //   ? bankTrustyPPD?.result
                //   : bankTrustyAgreement?.result
                bankTrustyAgreement?.result
              }
              isLoading={
                // tabActive === 0
                //   ? isLoadingBankTrustyPPD
                //   : isLoadingBankTrustyAgreement
                isLoadingBankTrustyAgreement
              }
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={
                bankTrustyAgreement?.paginator
                // tabActive === 0
                //   ? bankTrustyPPD?.paginator
                //   : bankTrustyAgreement?.paginator
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTrustyAgreement;
