import React, { useState } from "react";
import Card from "..";
import CardHeader from "../CardHeader";
import CardBody from "../CardBody";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const CollapsingCard = ({ title = "", children, isCollapse = true }) => {
  const [open, setOpen] = useState(true);
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>{title}</div>
          {isCollapse && (
            <span
              className="flex items-center cursor-pointer text-primary-700 space-x-1"
              onClick={() => setOpen(!open)}
            >
              <div>{open ? "Sembunyikan" : "Lihat"}</div>
              {open ? <BiChevronUp className="text-2xl" /> : <BiChevronDown className="text-2xl" />}
            </span>
          )}
        </div>
      </CardHeader>
      {open && <CardBody>{children}</CardBody>}
    </Card>
  );
};

export default CollapsingCard;
