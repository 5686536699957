import React from 'react'
import { Input } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

const InputForm = ({
  name,
  asText = false,
  ...props
}) => {
  const {
    control,
  } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value, onBlur }, fieldState: { invalid, error } }) => (
          <>
            {
              asText ? (
                <div
                  className={[
                    "min-h-[2.5rem] py-2 overflow-hidden text-ellipsis whitespace-pre",
                  ].join(" ")}
                >
                  {value}
                </div>
              ) : (
                <div className="w-full">
                  <Input {...props} value={value} onChange={onChange} isError={invalid} />
                  {invalid && <div className="text-xs text-error-600 mt-2">{error?.message}</div>}
                </div>
              )
            }

          </>
        )}
      />
    </>
  )
}

export default InputForm

// {
//   asText ? (
//     <div
//       className={[
//         "min-h-[2.5rem] py-2 overflow-hidden text-ellipsis whitespace-pre",
//         asTextClass,
//       ].join(" ")}
//     >
//       {mask && maskValue.current
//         ? maskValue.current?.masked(value)
//         : value}
//     </div>
//   ) : undefined
// }