import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Breadcrumbs,
  Card,
  CardHeader,
  CardBody,
  Label,
  Button,
} from "components";
import {
  AsyncSelectForm,
  ButtonBackConfirm,
  CustomSelectForm,
  InputForm,
  TextareaForm,
} from "components/HookForm";
import { useNavigate } from "react-router";
import { BsArrowRightShort } from "react-icons/bs";

import TreeTable from "./Tables/TreeTable";
import CollateralTable from "./Tables/CollateralTable";
import LandTable from "./Tables/LandTable";
import VehicleTable from "./Tables/VehicleTable";
import {
  useAgencyCollateralOption,
  usePostHandover,
  useTreesList,
} from "hooks/collateralHandover";
import moment from "moment";
import { enqueueSnackbar } from "notistack";

const LINKS = [
  {
    label: "Daftar Serah Terima Agunan",
    path: "/collateral/handover",
  },
  {
    label: "Tambah Rencana Penyerahan Agunan",
  },
];

const defaultValues = {
  category: "debitur",
  NAP: null,
  debitur: "",
  birthdate: "",
  payment: "",
  address: "",
  notes: "",
};

const validationSchema = yup.object({
  category: yup.string().required("Katgeori Serah Terima Agunan wajib diisi"),
  // NAP: yup
  //   .object()
  //   .shape({
  //     value: yup.string().required(),
  //     label: yup.string().required(),
  //   })
  //   .test("required", "Nomor NAP wajib diisi", (value, ctx) => {
  //     if (!value) return false;
  //     else if (value !== null && value.value && value.label)
  //       return !!value.value && !!value.label;
  //     return true;
  //   })
  //   .nullable(),
  NAP: yup.object({ id: yup.string(), name: yup.string() }).required(),
  debitur: yup.string().required("Nama Debitur wajib diisi"),
  birthdate: yup.string().required("Tempat Tanggal Lahir wajib diisi"),
  payment: yup.string().required("Nilai Pembayaran wajib diisi"),
  address: yup.string().required("Alamat wajib diisi"),
  notes: yup.string().when("category", {
    is: "another_party",
    then: () => yup.string().required("Catatan Kronologis wajib diisi"),
    otherwise: () => yup.string().notRequired(),
  }),
});

const NewHandover = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);
  
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  
  const { setValue, register, watch, handleSubmit } = methods;

  const { data: napOptions } = useAgencyCollateralOption([
    "agency-collateral-options",
    {},
  ]);


  const { data: treeList, status } = useTreesList(["trees", { page, limit }], {enabled: !!watch("NAP")});

  const postHandover = usePostHandover();

  const onSubmit = (data) => {
    let payload = {
      category: data.category,
      nap_number: data.NAP.nap_number,
      name: data.debitur,
      birth_of_date: data.birthdate,
      value: parseInt(data.payment),
      adress: data.address,
      notes: data.notes,
      tallySheet: [{
        tree_number: treeList?.result[0]?.tree_number,
        type: treeList?.result[0]?.wide.toString(),
        condition: treeList?.result[0]?.status,
        price: treeList?.result[0]?.collateral_value,
        debtor_name: treeList?.result[0]?.debitur,
      }]
    };

    console.log(payload, "data")

    postHandover.mutate(payload, {
      onSuccess: () => {
        navigate("/collateral/handover");

        enqueueSnackbar("Data berhasil dibuat.", {
          variant: "success",
        });
      },
      onError: (error, variables) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    if (watch("NAP")) {
      setValue("debitur", watch("NAP").name);
      setValue(
        "birthdate",
        moment(watch("NAP").birth_of_date).format("DD-MM-YYYY")
      );
      setValue("payment", watch("NAP").value);
      setValue("address", watch("NAP").address);
    }
  }, [watch("NAP")]);

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <div className="space-y-6">
          <Breadcrumbs items={LINKS} />
          <Card>
            <CardHeader>Tambah Rencana Penyerahan Agunan</CardHeader>
            <CardBody>
              <div className="space-y-5">
                <div className="space-y-2">
                  <Label required>Pilih Kategori Serah Terima Agunan</Label>
                  <div className="flex space-x-6">
                    <label className="flex space-x-2 items-center">
                      <input
                        type="radio"
                        className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
                        {...register("category")}
                        onChange={(e) => {
                          setValue("category", e.target.value);
                        }}
                        name="category"
                        value="debitur"
                      />
                      <span className="text-sm text-gray-700 peer-checked:font-semibold">
                        Diserahkan kepada debitur
                      </span>
                    </label>
                    <label className="flex space-x-2 items-center">
                      <input
                        type="radio"
                        className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
                        {...register("category")}
                        onChange={(e) => {
                          setValue("category", e.target.value);
                        }}
                        name="category"
                        value="another_party"
                      />
                      <span className="text-sm text-gray-700 peer-checked:font-semibold">
                        Diserahkan ke pihak lain
                      </span>
                    </label>
                  </div>
                </div>
                <div className="space-y-2 flex-1">
                  <Label required>Nomor NAP</Label>
                  <CustomSelectForm
                    options={napOptions?.data?.data}
                    name="NAP"
                    getOptionLabel={(opt) => opt.nap_number}
                    placeholder="Pilih Nomor NAP"
                  />
                </div>
                <div className="flex space-x-6">
                  <div className="space-y-2 flex-1">
                    <Label required>Nama Debitur</Label>
                    <InputForm className="w-full" name="debitur" disabled />
                  </div>
                  <div className="space-y-2 flex-1">
                    <Label required>Tanggal Lahir</Label>
                    <InputForm className="w-full" name="birthdate" disabled />
                  </div>
                  <div className="space-y-2 flex-1">
                    <Label required>Nilai Permohonan Pembiayaan</Label>
                    <InputForm className="w-full" name="payment" disabled />
                  </div>
                </div>
                <div className="space-y-2 flex-1">
                  <Label required>Alamat</Label>
                  <TextareaForm
                    className="w-full"
                    name="address"
                    rows={5}
                    disabled
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          {watch("category") === "another_party" && (
            <Card>
              <CardHeader>Catatan Kronologis</CardHeader>
              <CardBody>
                <div className="space-y-2 flex-1">
                  <Label required>Catatan</Label>
                  <TextareaForm className="w-full" name="notes" rows={5} />
                </div>
              </CardBody>
            </Card>
          )}

          <div className="block md:flex items-center justify-between px-10 py-5 border-[2px] border-gray-200 rounded-3xl mt-4 bg-white mb-10">
            <ButtonBackConfirm
              useIcon={true}
              onConfirm={() => navigate("/collateral/handover")}
            />
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 disabled:text-white disabled:border-primary-300 disabled:bg-primary-300"
              endIcon={<BsArrowRightShort className="text-white" size={20} />}
              onClick={() => handleSubmit(onSubmit)()}
            >
              Simpan
            </Button>
          </div>

          <Card>
            <CardHeader>Daftar Agunan</CardHeader>
            <CardBody>
              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    Daftar Pohon
                  </div>
                  <TreeTable data={treeList} status={status} />
                </div>
                <hr />
                {/* <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    Daftar Collateral
                  </div>
                  <CollateralTable />
                </div>
                <hr />
                <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    Daftar Tanah & Bangunan
                  </div>
                  <LandTable />
                </div>
                <hr />
                <div className="space-y-4">
                  <div className="font-semibold text-gray-900">
                    Daftar Kendaraan & Mesin
                  </div>
                  <VehicleTable />
                </div> */}
              </div>
            </CardBody>
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};

export default NewHandover;
