import React, { useMemo, useCallback, useState, useEffect } from "react";
import ReactSelect from "react-select";

let NEXT_PAGE = null;

const CustomSelect = ({
  optionsData,
  onChange,
  value,
  isClearable = false,
  placeholder = "Cari ...",
  ...props
}) => {
  const [options, setOptions] = useState([]);

  const SelectStyles = useMemo(
    () => ({
      input: (styles) => ({
        ...styles,
        border: "0px",
      }),
      control: (styles) => ({
        ...styles,
        borderColor: "#EAECF0",
        ":hover": {
          ...styles[":hover"],
          borderColor: "#EAECF0",
        },
        ":focus": {
          ...styles[":focus"],
          borderColor: "transparent",
        },
        boxShadow: "0 !important",
        borderRadius: "6px",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "#000"
      }),
      indicatorContainer: (styles, { isFocused }) => ({
        ...styles,
        color: isFocused ? "#98A2B3" : "#98A2B3",
        ":hover": {
          ...styles[":hover"],
          color: "#98A2B3",
        },
      }),
      option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
        ...styles,
        cursor: isDisabled ? "not-allowed" : "auto",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#01A24A"
          : isFocused
          ? "#EEFFF4"
          : undefined,
        color: isDisabled
          ? "hsl(0, 0%, 80%)"
          : isSelected ? "#fff" : isFocused
          ? "#077439"
          : data.color,
      }),
    }),
    []
  );

  useEffect(() => {
    if (optionsData.length) {
      setOptions(optionsData.map((data) => ({
        ...data
      })))
    }
  }, [optionsData]);

  return (
    <ReactSelect
      {...props}
      placeholder={placeholder}
      value={value}
      options={options}
      styles={SelectStyles}
      closeMenuOnScroll={false}
      onChange={onChange}
      classNamePrefix="my-select"
      menuPlacement="auto"
      isClearable={isClearable}
    />
  );
};

export default React.memo(CustomSelect);
