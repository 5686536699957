import Modal from "react-modal";
import { IoIosClose } from "react-icons/io";

const customStyles = {
  overlay: {
    position: "fixed",
    inset: "0px",
    backgroundColor: "rgba(0,0,0, 0.75)",
    zIndex: 2,
    overflow: "hidden"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 12,
    padding: 24,
    width: "100%",
    maxWidth: "75%",
    overflow: "hidden"
  },
};

const CollateralModal = ({
  isOpen = false,
  onClose,
  title = "",
  showCloseBtn = false,
  children,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
        <div id="modalHeader" className="flex space-x-4 mb-6">
          <div className="text-lg font-semibold text-gray-900 flex-1">
            {title}
          </div>
          {showCloseBtn && (
            <IoIosClose
              className="text-gray-500 cursor-pointer"
              size={30}
              onClick={onClose}
            />
          )}
        </div>
        <div id="modalBody" style={{ maxHeight: "calc(100vh - 102px)", overflowY: "auto" }}>
            {children}
        </div>
    </Modal>
  );
};

export default CollateralModal;
