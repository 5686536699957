import { forwardRef } from "react";
import ReactSelect from "react-select";

export const Select = forwardRef(({ ...props }, ref) => {
  return (
    <ReactSelect
      {...props}
      styles={{
        placeholder: (styles) => ({
          ...styles,
          color: "#98A2B3",
          fontSize: 14,
          fontWeight: 400,
        }),
        control: (styles) => ({
          ...styles,
          borderColor: "#EAECF0",
          ":hover": {
            ...styles[":hover"],
            borderColor: "#EAECF0",
          },
          boxShadow: "none",
          borderRadius: "6px",
        }),
        singleValue: (styles) => ({
          ...styles,
          color: "#000",
        }),
        indicatorContainer: (styles, { isFocused }) => ({
          ...styles,
          color: isFocused ? "#98A2B3" : "#98A2B3",
          ":hover": {
            ...styles[":hover"],
            color: "#98A2B3",
          },
        }),
        option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
          ...styles,
          cursor: isDisabled ? "not-allowed" : "auto",
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? "#01A24A"
            : isFocused
            ? "#EEFFF4"
            : undefined,
          color: isDisabled
            ? "hsl(0, 0%, 80%)"
            : isSelected
            ? "#fff"
            : isFocused
            ? "#077439"
            : data.color,
        }),
      }}
      menuPortalTarget={document.body}
      ref={ref}
    />
  );
});
