import MainPage from "pages";

import DonorAgreement from "pages/Agreement/Donor";
import DonorAgreementForm from "pages/Agreement/Donor/Form";
import DonorAgreementUpload from "pages/Agreement/Donor/Upload";

import BankTrustyAgreement from "pages/Agreement/BankTrusty";
import BankTrustyAgreementForm from "pages/Agreement/BankTrusty/Form";
import BankTrustyAgreementUpload from "pages/Agreement/BankTrusty/Upload";

import DirectDistribution from "pages/Agreement/DirectDistribution";
import IndividualForm from "pages/Agreement/DirectDistribution/Form/Individual";
import IndividualDebtor from "pages/Agreement/DirectDistribution/Debtor/Individual";
import IndividualUpload from "pages/Agreement/DirectDistribution/Upload/Individual";
import IndividualWithinGroupForm from "pages/Agreement/DirectDistribution/Form/IndividualWithinGroup";
import IndividualWithinGroupDebtor from "pages/Agreement/DirectDistribution/Debtor/IndividualWithinGroup";
import IndividualWithinGroupUpload from "pages/Agreement/DirectDistribution/Upload/IndividualWithinGroup";
import BusinessEntityForm from "pages/Agreement/DirectDistribution/Form/BusinessEntity";
import BusinessEntityDebtor from "pages/Agreement/DirectDistribution/Debtor/BusinessEntity";
import BusinessEntityUpload from "pages/Agreement/DirectDistribution/Upload/BusinessEntity";
import OtherLegalEntitiesForm from "pages/Agreement/DirectDistribution/Form/OtherLegalEntities";
import OtherLegalEntitiesDebtor from "pages/Agreement/DirectDistribution/Debtor/OtherLegalEntities";
import OtherLegalEntitiesUpload from "pages/Agreement/DirectDistribution/Upload/OtherLegalEntities";

import Notary from "pages/Notary";
import NotaryForm from "pages/Notary/Form";
import NotaryPreview from "pages/Notary/Preview";

import CollateralDashboard from "pages/Collateral/Dashboard";
import CollateralHandover from "pages/Collateral/Handover";
import CollateralHandoverNew from "pages/Collateral/Handover/new";
import CollateralHandoverDetail from "pages/Collateral/Handover/detail";

import TemplateForm from "pages/Template";
import NewTemplate from "pages/Template/new";
import EditTemplate from "pages/Template/edit";
import DebtorAgreement from "pages/Agreement/Debtor";
import DebtorAgreementForm from "pages/Agreement/Debtor/Form";
import DebtorProfile from "pages/Agreement/Debtor/Profile";
import Lemtara from "pages/Agreement/Lemtara";
import LemtaraForm from "pages/Agreement/Lemtara/Form";
import BankTrustyV2Form from "pages/Agreement/BankTrustyV2/Form";

const collateral = [
  {
    routeType: "private",
    path: "/collateral/dashboard",
    element: CollateralDashboard,
    exact: true,
  },
  {
    routeType: "private",
    path: "/collateral/handover",
    element: CollateralHandover,
    exact: true,
  },
  {
    routeType: "private",
    path: "/collateral/handover/new",
    element: CollateralHandoverNew,
    exact: true,
  },
  {
    routeType: "private",
    path: "/collateral/handover/:id",
    element: CollateralHandoverDetail,
    exact: true,
  },
];

const agreement = [
  {
    routeType: "private",
    path: "/agreement-donor",
    element: DonorAgreement,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-donor/form",
    element: DonorAgreementForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-donor/:proposalId/upload",
    element: DonorAgreementUpload,
    exact: true,
  },
  {
    routeType: "private",
    path: "/debtor-agreement",
    element: DebtorAgreement,
    exact: true,
  },
  {
    routeType: "private",
    path: "/debtor-agreement/form",
    element: DebtorAgreementForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/debtor-agreement/debtor/:id",
    element: DebtorProfile,
    exact: true,
  },
];

const bankTrusty = [
  {
    routeType: "private",
    path: "/agreement-bank-trusty",
    element: BankTrustyAgreement,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-bank-trusty/form",
    // element: BankTrustyAgreementForm,
    // element: BankTrustyAgreementUpload,
    element: BankTrustyV2Form,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-bank-trusty/:proposalId/upload",
    element: BankTrustyV2Form,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-bank-trusty/:id/form",
    element: BankTrustyAgreementUpload,
    exact: true,
  },
];

const directDistribution = [
  {
    routeType: "private",
    path: "/agreement-direct-distribution",
    element: DirectDistribution,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/individual/form",
    element: IndividualForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/individual/form",
    element: IndividualForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/individual/form/:debtorId/debtor",
    element: IndividualDebtor,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/individual/form/:debtorId/debtor/draft",
    element: IndividualUpload,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/individual-within-group/form",
    element: IndividualWithinGroupForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/individual-within-group/form",
    element: IndividualWithinGroupForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/individual-within-group/form/:debtorId/debtor/:applicantId",
    element: IndividualWithinGroupDebtor,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/individual-within-group/form/:debtorId/debtor/:applicantId/draft",
    element: IndividualWithinGroupUpload,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/business-entity/form",
    element: BusinessEntityForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/business-entity/form",
    element: BusinessEntityForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/business-entity/form/:id_debtor/debtor",
    element: BusinessEntityDebtor,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/business-entity/form/:id_debtor/debtor/draft",
    element: BusinessEntityUpload,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/other-legal-entities/form",
    element: OtherLegalEntitiesForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/other-legal-entities/form",
    element: OtherLegalEntitiesForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/other-legal-entities/form/:id_debtor/debtor",
    element: OtherLegalEntitiesDebtor,
    exact: true,
  },
  {
    routeType: "private",
    path: "/agreement-direct-distribution/:id/other-legal-entities/form/:id_debtor/debtor/draft",
    element: OtherLegalEntitiesUpload,
    exact: true,
  },
];

const notary = [
  {
    routeType: "private",
    path: "/notary",
    element: Notary,
    exact: true,
  },
  {
    routeType: "private",
    path: "/notary/form",
    element: NotaryForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/notary/:id/form",
    element: NotaryForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/notary/:id/preview",
    element: NotaryPreview,
    exact: true,
  },
];

const draftTemplate = [
  {
    routeType: "private",
    path: "/template/new",
    element: NewTemplate,
    exact: true,
  },
  {
    routeType: "private",
    path: "/template/:templateId/edit",
    element: EditTemplate,
    exact: true,
  },
  {
    routeType: "private",
    path: "/template/draft",
    element: EditTemplate,
    exact: true,
  },
];

const lemtara = [
  {
    routeType: "private",
    path: "/lemtara",
    element: Lemtara,
    exact: true,
  },
  {
    routeType: "private",
    path: "/lemtara/form",
    element: LemtaraForm,
    exact: true,
  },
  {
    routeType: "private",
    path: "/lemtara/:id/form",
    element: LemtaraForm,
    exact: true,
  },
];

const routesName = [
  {
    routeType: "private",
    path: "/",
    element: MainPage,
    exact: true,
  },
  ...agreement,
  ...bankTrusty,
  ...directDistribution,
  ...draftTemplate,
  ...notary,
  ...collateral,
  ...lemtara,
];

export default routesName;
