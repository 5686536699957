import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Table } from "components";
import { useCollateralsList } from "hooks/collateralHandover";
import { CollateralModal } from "components/Modal";

const StatusBadge = ({ status }) => {
  let defaultWrapperClass = "bg-warning-50 text-warning-700";

  switch (status) {
    case "Realease Lunas":
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      break;
    case "Rusak":
      defaultWrapperClass = "bg-error-50 text-error-700";
      break;
    case "PUPN":
      defaultWrapperClass = "bg-indigo-50 text-indigo-700";
      break;
    case "Sudah Tidak Ada":
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
    default:
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
  }
  return (
    <div
      className={`badge border-transparent p-2 text-xs ${defaultWrapperClass}`}
    >
      {status}
    </div>
  );
};

const ModalDetail = ({ open, onClose }) => {
  return (
    <CollateralModal
      title="Detail"
      isOpen={open}
      showCloseBtn={true}
      onClose={() => onClose(false)}
    >
      <div className="flex flex-1 space-y-5 flex-col">
        <div class="grid grid-cols-3 gap-4">
          <div className="space-y-3">
            <div className="text-black font-semibold">No Bilyet</div>
            <div className="text-black">1234567890</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Atas Nama</div>
            <div className="text-black">John Doe</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Nilai</div>
            <div className="text-black">
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(10000000)}
            </div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">No Akta Fidusia</div>
            <div className="text-black">1234567890</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Debitur</div>
            <div className="text-black">John Doe</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Status</div>
            <div className="text-black">
              <StatusBadge status="Realease Lunas" />
            </div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">
              Lokasi Bank Penyimpanan
            </div>
            <div className="text-black">Bandung</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Masa Berlaku</div>
            <div className="text-black">3 Tahun</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Bank Penerbit</div>
            <div className="text-black">Bank BCA</div>
          </div>
        </div>
      </div>
    </CollateralModal>
  );
};

const CollateralTable = ({ itemsPerPage = 10 }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(itemsPerPage);
  const [isModalOpen, setModalOpen] = useState(false);

  const { status, data } = useCollateralsList(["collaterals", { page, limit }]);

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);
  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return no + ".";
        },
      },
      {
        id: "bilyet",
        title: "No Bilyet",
        dataIndex: "bilyet",
        sortable: true,
        render: (value, data, index) => value ?? "-",
      },
      {
        id: "name",
        title: "Atas Nama",
        dataIndex: "name",
        sortable: true,
        render: (value, data, index) => (value ? `${value} m2` : "-"),
      },
      {
        id: "collateral_value",
        title: "Nilai",
        dataIndex: "collateral_value",
        sortable: true,
        render: (value, data, index) =>
          value
            ? new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value)
            : "-",
      },
      {
        id: "bank",
        title: "Bank",
        dataIndex: "bank",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "debitur",
        title: "Debitur",
        dataIndex: "debitur",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "fiduciary",
        title: "No Akta Fidusia",
        dataIndex: "fiduciary",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "storage_location",
        title: "Lok. Penyimpanan",
        dataIndex: "storage_location",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "text-center",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[170px] text-center">
            <StatusBadge status={value} />
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        width: 160,
        columnClassName: "text-center",
        className: "text-center",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <div className="flex justify-center items-center">
              <AiOutlineEye
                className="text-gray-600 cursor-pointer"
                size={20}
                onClick={() => setModalOpen(true)}
              />
            </div>
          );
        },
      },
    ];
  }, [page, limit]);
  return (
    <div className="space-y-4">
      <Table
        bordered
        layout="fixed"
        isLoading={status === "loading"}
        columns={tableColumns}
        dataSource={data?.result ?? []}
        pagination={data?.pagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        limit={limit}
      />
      <ModalDetail open={isModalOpen} onClose={(val) => setModalOpen(val)} />
    </div>
  );
};

export default CollateralTable;
