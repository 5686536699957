import cn from "classnames";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { AiFillFilePdf } from "react-icons/ai";
import { BsEyeFill } from "react-icons/bs";
import { FiUploadCloud } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { map } from "lodash";
import { getFile, postFile } from "services/fileService";

const AsyncDropzoneFieldForm = ({
  name,
  informationText = "",
  maxFiles = 1,
  multiple = false,
  label,
  required = false,
  onChange = (val) => { },
  isView = false,
  ...rest
}) => {
  const methods = useFormContext();

  const field = useWatch({ control: methods.control, name });

  const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState([]);

  const uploadFile = useCallback(
    async (droppedFiles) => {
      const formData = new FormData();
      for (let file of droppedFiles) {
        if (!(file instanceof File)) continue;
        formData.append("file", file);

        const response = await postFile(formData);
        const responseData = response;

        setFiles((prev) => prev.concat(responseData));

        if (multiple) {
          methods.setValue(name, files.concat(responseData?.id), {
            shouldValidate: true,
          });
          onChange(files.concat(response));
        } else {
          methods.setValue(name, responseData?.id, { shouldValidate: true });
          onChange(responseData)
        }
      }
    },
    [multiple, files, name, methods, onChange]
  );

  const dropzoneHandleError = useCallback(
    (error) => {
      if (error.code === "file-invalid-type") {
        return `Format dokumen harus ${informationText}`;
      } else if (error.code === "file-too-large") {
        return `File maksimal berukuran ${rest.maxSize} MB`;
      }

      return "";
    },
    [informationText, rest.maxSize]
  );

  const onDrop = useCallback(
    (droppedFiles, rejectionFiles) => {
      setErrors([]);

      if (rejectionFiles.length) {
        methods.setError(name, {
          type: "manual",
          message: dropzoneHandleError(rejectionFiles[0].errors[0]),
        });
      }

      uploadFile(droppedFiles);
    },
    [uploadFile, methods, name, dropzoneHandleError]
  );

  const maxFileSize = useMemo(() => rest.maxSize * 1024 * 1024, [rest.maxSize]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...rest,
    multiple: multiple,
    maxSize: maxFileSize,
    maxFiles: maxFiles,
  });

  const handleMountingFile = useCallback(async () => {
    if (multiple) {
      for (let i = 0; i < field?.length; i++) {
        if (files?.length > 0 || field[i] === files[i]?.id) continue;
        const response = await getFile(field[i]);

        setFiles(files.concat(response));
        methods.setValue(name, files.concat(response.id));
      }
    } else {
      if (files?.length > 0 || field === files[0]?.id) return;

      const response = await getFile(field);

      setFiles(files.concat(response));
      methods.setValue(name, response?.id);
    }
  }, [files, field, multiple, name, methods]);

  useEffect(() => {
    if (field) handleMountingFile();
  }, [field, handleMountingFile]);

  const handleDeleteFile = (index) => {
    setErrors([]);
    if (multiple) {
      const tempFiles = files.filter((file) => file.name !== files[index].name);

      methods.setValue(name, tempFiles, { shouldValidate: true });
      setFiles(tempFiles);
    } else {
      methods.setValue(name, undefined, { shouldValidate: true });
      setFiles([]);
    }
  };

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field, fieldState }) => (
        <div className="form-control w-full">
          {label && (
            <label className="label font-semibold text-[14px] ">
              <span className={`label-text`}>{label}</span>
              {required && <span className="text-[#F04438]">*</span>}
            </label>
          )}
          <div className="flex flex-col">
            {typeof files !== "undefined" &&
              files.length === maxFiles ? null : (
              <div
                className={cn(
                  "flex flex-col justify-center w-full gap-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none",
                  (errors.length > 0 || methods.formState.errors[name]) &&
                  "!border-error-600"
                )}
                {...getRootProps()}
              >
                <input name={name} {...getInputProps()} />
                {isDragActive ? (
                  <div className="flex flex-col items-center space-y-1 bg-primary-50 p-4">
                    <FiUploadCloud className="text-gray-600" size={24} />
                    <p className="text-gray-600 text-sm !mt-3">
                      <b className="text-primary-700">Klik untuk upload</b> atau
                      seret dan lepas kesini
                    </p>
                    <p className="text-gray-600 text-sm">{`${informationText} (max. ${rest.maxSize}MB)`}</p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center space-y-1 p-4">
                    <FiUploadCloud className="text-gray-600" size={24} />
                    <p className="text-gray-600 text-sm !mt-3">
                      <b className="text-primary-700">Klik untuk upload</b> atau
                      seret dan lepas kesini
                    </p>
                    <p className="text-gray-600 text-sm">{`${informationText} (max. ${rest.maxSize}MB)`}</p>
                  </div>
                )}
              </div>
            )}
            {typeof files !== "undefined" &&
              files.length > 0 &&
              map(files, (file, index, key) => (
                <div
                  className="flex items-center p-4 border rounded-lg mt-2 w-full justify-between bg-white"
                  key={file?.id}
                >
                  <div className="flex gap-8">
                    <AiFillFilePdf size={40} fill="#00BD52" />
                    <div className="flex flex-col">
                      <span className="text-base font-medium">
                        {file?.name ?? "-"}
                      </span>
                      <span className="text-xs">({file?.size ?? 0}KB)</span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <a href={file?.url} target="_blank" rel="noreferrer">
                      <BsEyeFill
                        size={18}
                        fill="#667085"
                        className="hover:fill-sky-600 cursor-pointer"
                      />
                    </a>
                    {!isView && (
                      <RiDeleteBin6Line
                        onClick={() => handleDeleteFile(index)}
                        size={18}
                        fill="#667085"
                        className="hover:fill-red-600 cursor-pointer"
                      />
                    )}
                  </div>
                </div>
              ))}

            {fieldState.invalid && (
              <small className="text-xs text-error-600 mt-4">
                {fieldState.error.message}
              </small>
            )}
          </div>
        </div>
      )}
    />
  );
};

// AsyncDropzoneFieldForm.propTypes = {
//   name: PropType.string,
//   informationText: PropType.string,
//   maxFiles: PropType.number,
//   multiple: PropType.bool,
// };

export default AsyncDropzoneFieldForm;
