import cn from "classnames";
import PropType from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { AiFillFilePdf } from "react-icons/ai";
import { BsEyeFill } from "react-icons/bs";
import { FiUploadCloud } from "react-icons/fi";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { map } from "lodash";

const DropzoneFieldForm = ({
  name,
  informationText = "",
  maxFiles = 1,
  multiple = false,
  ...rest
}) => {
  const { setValue, watch, formState } = useFormContext();

  const files = watch(name);

  const [errors, setErrors] = useState([]);
  const [isAdd, setIsAdd] = useState(true);

  const onDrop = useCallback(
    (droppedFiles, rejectionFiles) => {
      setErrors([]);

      if (rejectionFiles) {
        setErrors(rejectionFiles);
      }

      for (const file of droppedFiles) {
        file["preview"] = URL.createObjectURL(file);
      }

      const newFiles =
        (!!files?.length && [...files].concat(droppedFiles)) || droppedFiles;

      setIsAdd((prev) => !prev);
      setValue(name, newFiles.slice(0, rest.maxFiles), {
        shouldValidate: true,
      });
    },
    [name, setValue, files, rest.maxFiles]
  );

  const maxFileSize = useMemo(() => rest.maxSize * 1024 * 1024, [rest.maxSize]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...rest,
    multiple: multiple,
    maxSize: maxFileSize,
    maxFiles: maxFiles,
  });

  const dropzoneHandleError = (error) => {
    if (error.code === "file-invalid-type") {
      return `Format dokumen harus ${informationText}`;
    } else if (error.code === "file-too-large") {
      return `File maksimal berukuran ${rest.maxSize}MB`;
    }

    return "";
  };

  useEffect(() => {
    if (files?.length > 0) {
      setIsAdd(false);
    }
  }, [files]);

  return (
    <div className="flex flex-col">
      {(isAdd || files?.length < 1) && (
        <div
          className={cn(
            "flex flex-col justify-center w-full gap-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none",
            (errors.length > 0 || formState.errors[name]) && "!border-error-600"
          )}
          {...getRootProps()}
        >
          <input name={name} {...getInputProps()} />
          {isDragActive ? (
            <div className="flex flex-col items-center space-y-1 bg-primary-50 p-4">
              <FiUploadCloud className="text-gray-600" size={24} />
              <p className="text-gray-600 text-sm !mt-3">
                <b className="text-primary-700">Klik untuk upload</b> atau seret
                dan lepas kesini
              </p>
              <p className="text-gray-600 text-sm">{`${informationText} (max. ${rest.maxSize}MB)`}</p>
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-1 p-4">
              <FiUploadCloud className="text-gray-600" size={24} />
              <p className="text-gray-600 text-sm !mt-3">
                <b className="text-primary-700">Klik untuk upload</b> atau seret
                dan lepas kesini
              </p>
              <p className="text-gray-600 text-sm">{`${informationText} (max. ${rest.maxSize}MB)`}</p>
            </div>
          )}
        </div>
      )}

      {typeof files !== "undefined" &&
        files.length > 0 &&
        map(files, (file, index, key) => (
          <div
            className="flex items-center p-4 border rounded-lg mt-2 w-full justify-between"
            key={file.path}
          >
            <div className="flex gap-8">
              <AiFillFilePdf size={40} fill="#00BD52" />
              <div className="flex flex-col">
                <span className="text-base font-medium">
                  {file.name ?? "-"}
                </span>
                <span className="text-xs">({file.size ?? 0}KB)</span>
              </div>
            </div>
            <div className="flex gap-2">
              <a href={file.preview} target="_blank" rel="noreferrer">
                <BsEyeFill
                  size={18}
                  fill="#667085"
                  className="hover:fill-sky-600 cursor-pointer"
                />
              </a>
              <RiDeleteBin6Line
                onClick={() => {
                  setErrors([]);
                  const newFiles = files.filter(
                    (file) => file.name !== files[index].name
                  );
                  setIsAdd(true);
                  setValue(name, newFiles, { shouldValidate: true });
                }}
                size={18}
                fill="#667085"
                className="hover:fill-red-600 cursor-pointer"
              />
            </div>
          </div>
        ))}

      {/* {errors.length > 0 ? (
        <small className="text-sm font-medium text-error-600 mt-4">
          {dropzoneHandleError(errors[0].errors[0])}
        </small>
      ) : null}
      {formState.errors[name] ? (
        <small className="text-sm font-medium text-error-600 mt-4">
          {formState.errors[name].message}
        </small>
      ) : null} */}

      {errors.length > 0 ? (
        <small className="text-sm font-medium text-error-600 mt-4">
          {dropzoneHandleError(errors[0].errors[0])}
        </small>
      ) : formState.errors[name] ? (
        <small className="text-sm font-medium text-error-600 mt-4">
          {formState.errors[name].message}
        </small>
      ) : null}

      {!isAdd && files.length < maxFiles && (
        <div className="py-4 flex justify-center">
          <div className="p-3 shadow-md rounded-full cursor-pointer text-primary-600">
            <RiAddLine onClick={() => setIsAdd((prev) => !prev)} size={36} />
          </div>
        </div>
      )}
    </div>
  );
};

DropzoneFieldForm.propTypes = {
  name: PropType.string,
  informationText: PropType.string,
  maxFiles: PropType.number,
  multiple: PropType.bool,
};

export default DropzoneFieldForm;
