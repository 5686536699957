import React, { Fragment } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";
import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

const CKEditorForm = ({
  name,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <Fragment>
            <div
              className={classNames("template-content", {
                [`has-error`]: invalid,
              })}
            >
              <CKEditor
                editor={ClassicEditor}
                onReady={(editor) => {
                  editor.setData(value);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onChange(data);
                }}
              />
            </div>
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </Fragment>
        );
      }}
    />
  );
};

export default CKEditorForm;
