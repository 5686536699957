/* eslint-disable jsx-a11y/anchor-is-valid */
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";

import {
  Breadcrumbs,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useDonorAgreementList } from "hooks/donor";
import moment from "moment/moment";
import { useGetListLemtara } from "hooks/lemtara";

// =============== Breadcrumbs Items
const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Perjanjian Lemtara",
  },
];

// =============== Tab Items
const tabs = [
  {
    label: "Daftar Proposal",
  },
  {
    label: "Daftar Perjanjian",
  },
];

const dummyProposal = [
  {
    proposal_name: "Proposal Kepulauan",
    donor_name: "John Doe",
    submission_date: new Date(),
    contract_number: null,
    status: "Request Perjanjian",
  },
  {
    proposal_name: "Proposal 1000 Pohon",
    donor_name: "John Doe",
    submission_date: new Date(),
    contract_number: null,
    status: "Request Perjanjian",
  },
];

const dummyAgreement = [
  {
    proposal_name: "Proposal Kepulauan",
    donor_name: "John Doe",
    submission_date: new Date(),
    agreement_number: 1234567,
    status: "Sudah Diupload",
  },
  {
    proposal_name: "Proposal 1000 Pohon",
    donor_name: "John Doe",
    submission_date: new Date(),
    agreement_number: 1234567,
    status: "Sudah Diupload",
  },
];

const Lemtara = () => {
  // =============== Initialize
  const navigate = useNavigate();

  const { state } = useLocation();

  // =============== State
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [tabActive, setTabActive] = useState(0);
  const [year, setYear] = useState(undefined);

  // =============== Effect
  useEffect(() => {
    if (state) {
      const { tab } = state;
      setTabActive(tab);
    }
  }, [state]);

  // const { isLoading: isLoadingDonorPPD, data: donorPPD } =
  //   useDonorAgreementListFromPPD([
  //     "donor_agreement_list_from_ppd",
  //     {
  //       page,
  //       limit,
  //       search: keyword ?? undefined,
  //     },
  //   ]);

  const { isLoading: isLoadingLemtara, data: lemtara } = useGetListLemtara(
    ["GET_LIST_PROPOSAL_LEMTARA"],
    {
      select: (data) => data?.data,
    }
  );

  // TODO: IMPL API FROM PERJANJIAN
  const { isLoading: isLoadingDonorAgreement, data: donorAgreement } =
    useDonorAgreementList([
      "donor_agreement_list",
      {
        page,
        limit,
        search: keyword ?? undefined,
        year: year ? year.value : undefined,
      },
    ]);

  const yearsArray = useMemo(() => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = 2000;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    // Get years value from currentYear to startYear
    years.reverse();

    return years.map((year) => ({ value: year, label: year }));
  }, []);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) {
            return event?.target?.value;
          }
          return undefined;
        }),
      []
    ),
    800
  );

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const handleEditClick = useCallback(
    (id) => {
      navigate(`/agreement-donor/${id}/upload`);
    },
    [navigate]
  );

  const tableColumnProposal = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        // className: "w-fit",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            // parseInt(donorPPD?.paginator?.page) *
            //   parseInt(donorPPD.paginator?.limit) -
            // parseInt(donorPPD?.paginator?.limit) +
            index + 1;
          return no + ".";
        },
      },
      {
        id: "proposal_title",
        title: "Judul Proposal",
        dataIndex: "proposal_title",
        sortable: true,
        render: (value, data, index) => data?.proposal_title ?? "-",
      },
      // {
      //   id: "donor_name",
      //   title: "Nama Donor",
      //   dataIndex: "donor_name",
      //   sortable: true,
      //   render: (value, data, index) => (
      //     <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
      //       {data?.donor_name ?? "-"}
      //     </div>
      //   ),
      // },
      {
        id: "date_submit",
        title: "Tanggal Pengajuan",
        dataIndex: "date_submit",
        sortable: true,
        render: (value, data, index) =>
          data?.date_submit
            ? moment(data?.date_submit).format("DD MMMM yyyy")
            : "-",
      },
      // {
      //   id: "contract_number",
      //   title: "No Kontrak",
      //   dataIndex: "contract_number",
      //   sortable: true,
      //   render: (value, data, index) => data?.contract_number ?? "-",
      // },
      {
        id: "status",
        title: "status",
        dataIndex: "status",
        sortable: true,
        render: (value, data, index) =>
          data?.status ? (
            <div className="bg-primary-700 text-white w-max px-2 py-1 rounded">
              {data.status === "Sedang Diverifikasi"
                ? "Request Perjanjian"
                : "-"}
            </div>
          ) : (
            "-"
          ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        sortable: true,
        width: 160,
        columnClassName: "text-center min-w-[120px]",
        className: "text-center min-w-[120px]",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex items-center w-full">
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() =>
                navigate(`${data?._id}/form`, {
                  state: data,
                })
              }
            />
          </div>
        ),
      },
    ];
  }, [navigate]);

  const tableColumnAgreement = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        width: 20,
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(donorAgreement?.paginator?.page) *
              parseInt(donorAgreement?.paginator?.limit) -
            parseInt(donorAgreement?.paginator?.limit) +
            index +
            1;
          return no + ".";
        },
      },
      // {
      //   id: "proposal_name",
      //   title: "Nama Lemtara",
      //   dataIndex: "proposal_name",
      //   sortable: true,
      //   render: (value, data, index) => (
      //     <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
      //       {data?.proposal_name ?? "-"}
      //     </div>
      //   ),
      // },
      {
        id: "donor_name",
        title: "Jenis Lembaga",
        dataIndex: "donorName",
        sortable: true,
        render: (value, data, index) => data?.donor_name ?? "-",
      },
      // {
      //   id: "submit_date",
      //   title: "Tanggal Pengajuan",
      //   dataIndex: "submitDate",
      //   columnClassName: "text-center",
      //   className: "text-center",
      //   sortable: true,
      //   render: (value, data, index) =>
      //     data?.submit_date
      //       ? moment(data?.submit_date).format("DD-MM-YYYY")
      //       : "-",
      // },
      {
        id: "submission_date",
        title: "Tanggal Pengajuan",
        dataIndex: "submission_date",
        columnClassName: "text-center",
        className: "text-center",
        sortable: true,
        render: (value, data, index) =>
          data?.submission_date
            ? moment(data?.submission_date).format("DD MMMM YYYY")
            : "-",
      },
      {
        id: "agreement_number",
        title: "No Perjanjian",
        dataIndex: "agreement_number",
        columnClassName: "text-center",
        className: "text-center",
        sortable: true,
        render: (value, data, index) => data?.agreement_number ?? "-",
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "text-center",
        className: "text-center",
        sortable: true,
        render: (value, data, index) => (
          <div className="bg-primary-700 text-white w-max px-2 py-1 rounded">
            {data.status}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        sortable: true,
        width: 160,
        columnClassName: "text-center min-w-[120px]",
        className: "text-center min-w-[120px]",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex items-center w-full">
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleEditClick(data.id)}
            />
          </div>
        ),
      },
    ];
  }, [donorAgreement, handleEditClick]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Daftar Perjanjian Lemtara</TitleText>
        {/* <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={() => navigate(`form`)}
        >
          Buat Rencana Pelaksanaan Perjanjian
        </Button> */}
      </div>

      {/* Filter */}
      {/* <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <SearchInput placeholder="Pencarian" onChange={onSearch} />
            </div>
            <div className="flex-1">
              <CustomSelect
                placeholder="Status"
                optionsData={[]}
                isClearable={true}
              />
            </div>
            <div className="flex-1">
              <CustomSelect
                placeholder="Kategori"
                optionsData={[]}
                isClearable={true}
              />
            </div>
            <div className="flex-1">
              <CustomSelect
                value={year}
                placeholder="Tahun"
                optionsData={yearsArray}
                onChange={setYear}
                isClearable={true}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {/* Tabs */}
            {/* <div className="tabs">
              {map(tabs, (tab, key) => (
                <button
                  onClick={() => setTabActive(key)}
                  className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
                    key === tabActive
                      ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                      : "border-b"
                  }`}
                >
                  {tab.label}
                </button>
              ))}
            </div> */}

            {/* Table */}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              // columns={
              //   tabActive === 0 ? tableColumnProposal : tableColumnAgreement
              // }
              // dataSource={tabActive === 0 ? lemtara : dummyAgreement}
              // isLoading={
              //   tabActive === 0 ? isLoadingLemtara : isLoadingDonorAgreement
              // }
              columns={tableColumnProposal}
              dataSource={lemtara}
              isLoading={isLoadingLemtara}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={
                tabActive === 0
                  ? { itemCount: lemtara?.length }
                  : donorAgreement?.paginator
              }
              showTotalData={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lemtara;
