import { Button, CollapsingCard, Label } from "components";
import { InputForm, CKEditorForm } from "components/HookForm";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";

const TemplateForm = ({ onSubmitAction, isLoading, defaultValues, type }) => {
  // const dispatch = useDispatch();
  // const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { templateId } = useParams();

  const validationSchema = useMemo(() => {
    return yup.object({
      template_title: yup.string().required("Judul wajib diisi"),
      template_content: yup
        .string()
        .required("Template perjanjian wajib diisi"),
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  // useEffect(() => {
  //   const body = {
  //     template_id: templateId ?? location?.state?.template_id,
  //     template_content: methods.getValues("template_content"),
  //     template_title: methods.getValues("template_title")
  //   }

  //   return () => localStorage.setItem("template", JSON.stringify(body));
  // }, [])

  const onSubmit = (payload) => {
    onSubmitAction(payload);
  };

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete="off">
        <CollapsingCard title="Template Perjanjian" isCollapse={false}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <Label required>Judul</Label>
              <InputForm
                name="template_title"
                className="w-full"
                placeholder="Masukkan judul template"
                disabled={type === "detail"}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Template Perjanjian</Label>
              <CKEditorForm
                name="template_content"
                className="!min-h-[600px]"
              />
            </div>
          </div>
        </CollapsingCard>

        <div className="flex mt-4 gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            onClick={() => {
              methods.reset({});
              navigate(location.state.path, {
                state: {
                  isFromTemplate: true,
                  // isFromEdit: location.pathname.includes("/detail"),
                },
              });
            }}
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="button"
            onClick={() => methods.handleSubmit(onSubmit)()}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default TemplateForm;
