import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { Breadcrumbs, Button, CollapsingCard, Label, Table } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { Select } from "components/select";
import {
  useAgreementGroupMemberDebtorList,
  useAgreementGroupMemberDetail,
  usePostAgreementGroupMember,
} from "hooks/directDistribution";
import { useNotaryList } from "hooks/notary";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
    path: "/agreement-direct-distribution",
  },
  {
    label: "Kontrak Perjanjian",
  },
];

const notaries = [
  { value: "bd67f367-202d-485d-a9e8-28447b259dc9", label: "Direktur" },
];

const tabs = [
  {
    label: "Perorangan Dalam Kelompok",
    value: "Perorangan Dalam Kelompok",
  },
  {
    label: "Badan Usaha",
    value: "Badan Usaha",
  },
  {
    label: "Badan Hukum Lainnya",
    value: "Badan Hukum Lainnya",
  },
];

const dataOptions = [
  {
    label: "Non Perhutanan Sosial",
    value: "Non Perhutanan Sosial",
  },
  {
    label: "Perhutanan Sosial",
    value: "Perhutanan Sosial",
  },
];

const dummyDaftarDebitur = [
  {
    id: "a013ccc7-1119-4570-a85d-99aed7db8098",
    group_id: "4c117fa3-e98f-40e3-b9cb-0fc55eafa493",
    group_name: "Kelompok",
    debtor_id: "6d477709-3289-4637-92bc-44b115b566db",
    debtor_name: "Debitur Satu",
    debtor_category: "Non Perhutanan Sosial",
  },
];

const signatoryOfficesList = [
  { value: "bd67f367-202d-485d-a9e8-28447b259dc9", label: "Direktur Utama" },
];

const validationSchema = yup.object({
  implementation_date: yup
    .string()
    .required("Tanggal Implementasi tidak boleh kosong"),
  invitation_number: yup.string().required("Nomor Undangan tidak boleh kosong"),
  notary: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Nama Notaris tidak boleh kosong"),
  delegate_decision_number: yup
    .string()
    .required("No Kepdirut tidak boleh kosong"),
  delegate_decision_date: yup
    .string()
    .required("Tanggal Kepdirut tidak boleh kosong"),
  signatory_offices: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Pejabat Penanda Tanganan tidak boleh kosong"),
  borrower_category: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Kategori Debitur tidak boleh kosong"),
  selected_debtor: yup
    .array()
    .test("check_selected_debtor", "Pilih satu atau lebih debitur", (value) => {
      return value.some((e) => typeof e !== "undefined");
    }),
});

const IndividualWithinGroupForm = () => {
  const queryClient = useQueryClient();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const { id } = useParams();

  // const categorySelectRef = useRef();
  // const dataSelectRef = useRef();
  const groupSelectRef = useRef();
  const categorySelectRef = useRef();

  const [isFiltered, setIsFiltered] = useState(false);
  const [debtors, setDebtors] = useState();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "",
    sortBy: "",
    search: "",
    filter: {
      group_type: "",
    },
    // filterCategory: "",
    // filterGroup: "",
  });

  const { data: notaryList } = useNotaryList(
    ["GET_NOTARY_LIST", { page: 1, limit: 1000 }],
    { select: (data) => data?.result }
  );
  const { data } = useAgreementGroupMemberDetail(
    ["GET_DETAIL_GROUP_MEMBER", id],
    { select: (data) => data?.result, enabled: !!id }
  );
  const { data: debtor } = useAgreementGroupMemberDebtorList(
    ["GET_DEBTOR_GROUP_PROFILE_MEMBER", filters],

    { select: (data) => data?.data, enabled: isFiltered, cacheTime: 0 }
  );

  const mutateAgreementGroupMember = usePostAgreementGroupMember();

  const tableColumnDebitur = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, dataValue, index) => {
          const no =
            (data?.paginator?.page ? parseInt(data?.paginator?.page) : 1) *
              parseInt(data?.paginator?.limit) -
            parseInt(data?.paginator?.limit) +
            index +
            1;

          return no;
        },
      },
      {
        id: "group_name",
        title: "Nama Kelompok",
        dataIndex: "group_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_name}
          </div>
        ),
      },
      {
        id: "applicant_debtor_name",
        title: "Nama Debitur",
        dataIndex: "applicant_debtor_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.applicant_debtor_name ?? "-"}
          </div>
        ),
      },

      {
        id: "group_type",
        title: "Kategori Debitur",
        dataIndex: "group_type",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.group_type}
          </div>
        ),
      },

      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-5 justify-center">
            <BiPencil
              className={`text-gray-600 cursor-pointer`}
              size={20}
              onClick={() =>
                navigate(
                  `${data.id}/debtor/${data?.applicant_id}?group_type=${data?.group_type}`
                )
              }
            />
            {/* <RiDeleteBinLine
              className={`text-gray-600 cursor-pointer`}
              size={20}
              // onClick={() => handleEditClick(data?.id)}
            /> */}
          </div>
        ),
      },
    ];
  }, [navigate, data]);

  const tableCreateColumnDebitur = useMemo(() => {
    return [
      {
        id: "checkbox",
        title: "",
        dataIndex: "checkbox",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          return (
            <Controller
              control={methods.control}
              name={`selected_debtor.${index}`}
              render={({ field }) => (
                <input
                  type="checkbox"
                  className="accent-primary-800"
                  onChange={(e) => {
                    if (e.target.checked) {
                      field.onChange(data);
                    } else {
                      field.onChange();
                    }
                  }}
                />
              )}
            />
          );
        },
      },
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(debtor?.paginator?.page) *
              parseInt(debtor?.paginator?.limit) -
            parseInt(debtor?.paginator?.limit) +
            index +
            1;

          return isNaN(no) ? "" : no;
        },
      },
      {
        id: "group_name",
        title: "Nama Kelompok",
        dataIndex: "group_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.group_name}
          </div>
        ),
      },
      {
        id: "debtor_name",
        title: "Nama Debitur",
        dataIndex: "debtor_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.applicant_debtor_name ?? "-"}
          </div>
        ),
      },

      {
        id: "debtor_category",
        title: "Kategori Debitur",
        dataIndex: "debtor_category",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.group_type}
          </div>
        ),
      },
    ];
  }, [debtor, methods]);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      signatory_offices: data?.signatory_offices?.value,
      notary_id: data?.notary?.value,
      notary_name: data?.notary?.label,
      selected_debtor: data?.selected_debtor.filter((e) => e instanceof Object),
    };

    delete payload.borrower_category;
    delete payload.group;
    delete payload.notary;

    mutateAgreementGroupMember.mutate(payload, {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "Rencana perjanjian berhasil disimpan",
        });
        queryClient.invalidateQueries();
        navigate(-1);
      },
      onError: (err) => {
        enqueueSnackbar({
          variant: "error",
          message: "Rencana perjanjian gagal disimpan",
        });
      },
    });
  };

  useEffect(() => {
    if (debtor) {
      setDebtors(debtor);
    }
    setIsFiltered(false);
  }, [debtor, isFiltered]);

  useEffect(() => {
    if (data && notaryList) {
      methods.reset({
        implementation_date: moment(data?.implementation_date).format(
          "YYYY-MM-DD"
        ),
        invitation_number: data?.invitation_number,
        notary: notaryList
          ?.map((e) => ({
            value: e.id,
            label: e.name,
          }))
          .find((e) => e.value === data.notary_id),
        delegate_decision_number: data?.delegate_decision_number,
        delegate_decision_date: moment(data?.delegate_decision_date).format(
          "YYYY-MM-DD"
        ),
        signatory_offices: signatoryOfficesList.find(
          (e) => e.value === data?.signatory_offices
        ),
      });
    }
  }, [data, methods, notaryList]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard
          title="Form Rencana Penandatanganan Perjanjian"
          isCollapse={false}
        >
          <div className="grid grid-cols-3 gap-x-5 gap-y-6">
            <div className="flex flex-col gap-2">
              <Label required>Tanggal Pelaksanaan</Label>
              <InputForm
                type="date"
                name="implementation_date"
                className="w-full"
                disabled={!!id}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Nomor Undangan Pelaksanaan</Label>
              <InputForm
                type="text"
                name="invitation_number"
                className="w-full"
                placeholder="Masukan Nomor Undangan Pelaksanaan"
                disabled={!!id}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Nama Notaris</Label>
              <CustomSelectForm
                name="notary"
                options={notaryList?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
                placeholder="Pilih satu"
                isDisabled={!!id}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label required>No Kepdirut</Label>
              <InputForm
                type="text"
                name="delegate_decision_number"
                className="w-full"
                placeholder="Masukan Nomor Keputusan Delegasi"
                disabled={!!id}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Tanggal Kepdirut</Label>
              <InputForm
                type="date"
                name="delegate_decision_date"
                className="w-full"
                disabled={!!id}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Pejabat Penanda Tanganan</Label>
              <CustomSelectForm
                options={signatoryOfficesList}
                name="signatory_offices"
                placeholder="Pilih satu"
                isDisabled={!!id}
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Daftar Debitur" isCollapse={false}>
          <div className="flex flex-col gap-4">
            {!id && (
              <div className="flex gap-5 items-stretch">
                <div className="flex flex-col gap-2 max-w-[346px] w-full">
                  <Label>Kategori Debitur</Label>
                  {/* <AsyncSelectForm name="borrower_category" /> */}
                  <Select
                    ref={groupSelectRef}
                    options={tabs}
                    placeholder="Pilih"
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        filter: {
                          ...prev.filter,
                          group_type: e?.value ?? "",
                        },
                        // filterGroup: e?.value ?? "",
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col gap-2 max-w-[346px] w-full">
                  <Label>Pilih Data</Label>
                  {/* <AsyncSelectForm name="group" options={dataOptions} /> */}
                  <Select
                    ref={categorySelectRef}
                    options={
                      filters.filter.group_type === "Perorangan Dalam Kelompok"
                        ? dataOptions
                        : []
                    }
                    placeholder="Pilih"
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        filter: {
                          ...prev.filter,
                          group_type: e?.value ?? "",
                        },
                        // filterCategory: e?.value ?? "",
                      }));
                    }}
                  />
                </div>

                <div
                  className={classNames(
                    "flex gap-4 items-end",
                    methods.formState.errors.borrower_category && "items-center"
                  )}
                >
                  <Button
                    className="bg-primary-700 text-white hover:bg-primary-800 h-10 w-24"
                    size="sm"
                    onClick={() => setIsFiltered(true)}
                  >
                    Filter
                  </Button>
                  <Button
                    className="bg-red-600 text-white hover:bg-red-700 h-10 w-24"
                    size="sm"
                    onClick={() => {
                      setFilters((prev) => ({ ...prev, filterCategory: "" }));
                      setDebtors([]);
                      setIsFiltered(false);
                      groupSelectRef.current.clearValue();
                      categorySelectRef.current.clearValue();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            )}

            {/* table */}
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-3">
                <div className="space-y-4">
                  <Table
                    bordered
                    stripped
                    layout="auto"
                    className="mb-4"
                    showPagination
                    columns={id ? tableColumnDebitur : tableCreateColumnDebitur}
                    dataSource={
                      id
                        ? data?.result?.debtor_data ?? data?.debtor_data ?? []
                        : debtors?.data
                    }
                    pagination={id ? data?.paginator : debtors?.paginator}
                    onChangePage={(e) => {
                      setFilters((prev) => ({ ...prev, page: e }));
                      setIsFiltered(true);
                    }}
                    onChangeRowsPerPage={(e) => {
                      setFilters((prev) => ({ ...prev, page: 1, limit: e }));
                      setIsFiltered(true);
                    }}
                  />
                  {methods.formState.errors.selected_debtor && (
                    <p className="text-xs text-red-600">
                      {methods.formState.errors.selected_debtor.root.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>

        {!id && (
          <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
            <Button
              type="button"
              onClick={() => navigate(-1)}
              outline
              className="bg-white text-primary-700 w-1/6"
            >
              Batal
            </Button>
            <Button
              type="submit"
              onClick={methods.handleSubmit(onSubmit)}
              className="bg-primary-700 text-white w-1/6"
            >
              Simpan
            </Button>
          </div>
        )}
      </div>
    </FormProvider>
  );
};

export default IndividualWithinGroupForm;
