import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs, Button, CollapsingCard, Label, Table } from "components";
import {
  AsyncSelectForm,
  CustomSelectForm,
  InputForm,
  TextareaForm,
} from "components/HookForm";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import { useDonorListById, usePostDonorAgreement } from "hooks/donor";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil, BiTrash } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";
import * as yup from "yup";

// =============== Breadcrumbs Items
const links = [
  {
    label: "Perjanjian Debitur Lembaga Penyalur",
    path: "/debtor-agreement",
  },
  {
    label: "Tambah Data",
  },
];

const donorOption = async (search, loadedOptions, { page }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_PPD}/donor/with-contract?page=${
        page ?? 1
      }&limit=10&search=${search}`
    );
    const responseJSON = await response.json();

    return {
      options: responseJSON.result,
      hasMore: responseJSON.paginator.hasNextPage,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const validationSchema = yup.object({
  implementation_date: yup
    .string()
    .required("Tanggal Implementasi tidak boleh kosong"),
  invitation_number: yup
    .string()
    .required("Nomor Undangan Pelaksanaan tidak boleh kosong"),
  signatory_offices: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .test(
      "required",
      "Pejabat penanda tanganan tidak boleh kosong",
      (value) => {
        if (!value) return false;
        else if (value !== null && value.value && value.label)
          return !!value.value && !!value.label;
        return true;
      }
    )
    .nullable(),
  donor_list: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .test("required", "Donor list tidak boleh kosong", (value) => {
      if (!value) return false;
      else if (value !== null && value.value && value.label)
        return !!value.value && !!value.label;
      return true;
    })
    .nullable(),
});

const DebtorAgreementForm = () => {
  // ====================== Initialize
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // ====================== State
  const [donorList, setDonorList] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [dataChecked, setDataChecked] = useState([]);
  const [result, setResult] = useState([]);

  const postDonorAgreement = usePostDonorAgreement();

  const { isLoading, data, refetch } = useDonorListById(
    [
      "donor_list",
      {
        page,
        limit,
        status: "inprogress",
        donorId: donorList?._id ?? undefined,
      },
    ],
    { enabled: false, onSuccess: (data) => setResult(data.result) }
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "actions",
        title: "",
        dataIndex: "actions",
        render: (value, data, index) => (
          <input
            type="checkbox"
            // className="accent-primary-700 w-3 h-3 cursor-pointer"
            className="text-primary-600 rounded-md border-[1px] border-gray-300 !shadow-none checked:!border-primary-600 focus:!shadow-none focus:!ring-0 focus:!ring-offset-0 focus:!ring-transparent focus:!outline-none cursor-pointer"
            checked={
              dataChecked?.findIndex((x) => x.proposal_id === data.id) > -1
            }
            onChange={() => handleCheck(data)}
          />
        ),
      },
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, item, index) => {
          const no =
            parseInt(data?.paginator?.page) * parseInt(data?.paginator?.limit) -
            parseInt(data?.paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "debtor_name",
        title: "Nama Debitur",
        dataIndex: "debtor_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.generalInformation?.title}
          </div>
        ),
      },
      {
        id: "debtor_category",
        title: "Kategori Debitur",
        dataIndex: "debtor_category",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donor?.donor}
          </div>
        ),
      },
      {
        id: "print_status",
        title: "Status Cetak",
        dataIndex: "print_status",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donor?.donor}
          </div>
        ),
      },
      {
        id: "present_status",
        title: "Status Hadir",
        dataIndex: "present_status",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donor?.donor}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        // width: 160,
        sortable: true,
        className: "text-center min-w-[50px]",
        columnClassName: "text-center min-w-[50px]",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex items-center">
            <BiPencil
              className="text-gray-600 cursor-not-allowed flex-1"
              size={20}
            />
            <BiTrash
              className="text-gray-600 cursor-not-allowed flex-1"
              size={20}
            />
          </div>
        ),
      },
    ];
  }, [result, dataChecked]);

  const supportingDocumentColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, item, index) => {
          const no =
            parseInt(data?.paginator?.page) * parseInt(data?.paginator?.limit) -
            parseInt(data?.paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "id_card",
        title: "KTP",
        dataIndex: "id_card",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.generalInformation?.title}
          </div>
        ),
      },
      {
        id: "family_card",
        title: "KK",
        dataIndex: "family_card",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donor?.donor}
          </div>
        ),
      },
      {
        id: "offering_document",
        title: "Dokummen Penawaran",
        dataIndex: "offering_document",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donor?.donor}
          </div>
        ),
      },
      {
        id: "fiduciary_document",
        title: "Dokumen Fidusia",
        dataIndex: "fiduciary_document",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donor?.donor}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        // width: 160,
        sortable: true,
        className: "text-center min-w-[50px]",
        columnClassName: "text-center min-w-[50px]",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex items-center">
            <BiPencil
              className="text-gray-600 cursor-not-allowed flex-1"
              size={20}
            />
            <BiTrash
              className="text-gray-600 cursor-not-allowed flex-1"
              size={20}
            />
          </div>
        ),
      },
    ];
  }, [result, dataChecked]);

  const handleCheck = (item) => {
    const payload = {
      proposal_id: item.id,
      proposal_title: item.generalInformation.title,
      donor_id: item.donor.donorId,
      donor_name: item.donor.donor,
    };

    setDataChecked((prevState) => {
      if (prevState.findIndex((x) => x.proposal_id === item.id) > -1) {
        return prevState.filter((x) => x.proposal_id !== item.id);
      } else {
        return [...prevState, payload];
      }
    });
  };

  // ======================== Handler
  const handleFilterDonor = () => {
    if (donorList) {
      refetch();
    }
  };

  const handleResetDonor = () => {
    setDonorList(null);
    setResult([]);
    methods.reset((prevState) => ({
      ...prevState,
      donor_list: null,
    }));
  };

  const onSubmit = (data) => {
    const {
      implementation_date,
      invitation_number,
      signatory_offices,
      delegate_decision_number,
      delegate_decision_date,
    } = data;

    const payload = {
      implementation_date,
      invitation_number,
      signatory_offices: signatory_offices.value,
      delegate_decision_number,
      delegate_decision_date:
        delegate_decision_date !== "" ? delegate_decision_date : null,
      selected_donor: dataChecked,
    };

    if (donorList && dataChecked.length < 1) {
      enqueueSnackbar("Harap pilih salah satu data proposal.", {
        variant: "error",
      });
    } else {
      postDonorAgreement.mutate(payload, {
        onSuccess: () => {
          navigate("/agreement-donor", { state: { tab: 1 } });

          enqueueSnackbar("Data Perjanjian Donor berhasil dibuat.", {
            variant: "success",
          });
        },
        onError: (error, variables) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard title="Detail Data" isCollapse={false}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col w-full gap-1">
              <Label required>Nama Lembaga Penyalur</Label>
              <InputForm
                name="notary_name"
                className="w-full"
                placeholder="Nama Notaris"
              />
            </div>
            <div className="flex flex-col w-full gap-1">
              <Label required>Alamat</Label>
              <TextareaForm
                name="address"
                className="w-full"
                placeholder="Alamat"
              />
            </div>
            <div className="flex flex-col w-full gap-1">
              <Label required>Nama Cabang</Label>
              <InputForm
                name="branch_name"
                className="w-full"
                placeholder="SK Kemenkumhan"
              />
            </div>
            <div className="flex flex-col w-full gap-1">
              <Label required>Alamat Cabang</Label>
              <TextareaForm
                name="branch_address"
                className="w-full"
                placeholder="Alamat Lembaga Penyalur"
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Daftar Debitur" isCollapse={false}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col w-3/4 gap-1">
              <Label required>Kategori Debitur</Label>
              <div className="flex gap-2 items-center">
                <AsyncSelectForm
                  name="debtor_category_list"
                  value={donorList}
                  loadOptions={donorOption}
                  getOptionValue={(opt) => opt._id}
                  getOptionLabel={(opt) => opt.name}
                  onChange={setDonorList}
                  additional={{ page: 1 }}
                  placeholder="Pilih Kategori Debitur"
                />
                <AsyncSelectForm
                  name="group_list"
                  value={donorList}
                  loadOptions={donorOption}
                  getOptionValue={(opt) => opt._id}
                  getOptionLabel={(opt) => opt.name}
                  onChange={setDonorList}
                  additional={{ page: 1 }}
                  placeholder="Pilih Kelompok"
                />
                <Button
                  disabled={!donorList}
                  className={`${
                    methods.formState.errors?.donor_list ? "mb-6" : "mb-0"
                  } bg-primary-700 text-white !min-h-[2.5rem] !h-10 hover:bg-primary-800`}
                  onClick={handleFilterDonor}
                >
                  Filter
                </Button>
                <Button
                  disabled={!donorList}
                  className={`${
                    methods.formState.errors?.donor_list ? "mb-6" : "mb-0"
                  } bg-red-600 text-white !min-h-[2.5rem] !h-10 hover:bg-red-700`}
                  onClick={handleResetDonor}
                >
                  Reset
                </Button>
              </div>
            </div>

            {/* table */}
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-3">
                <div className="space-y-4">
                  <Table
                    bordered
                    stripped
                    layout="auto"
                    className="mb-4"
                    columns={tableColumns}
                    dataSource={result}
                    isLoading={isLoading}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    pagination={data?.paginator}
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Daftar Dokumen Pendukung" isCollapse={false}>
          <div className="flex flex-col gap-4">
            {/* table */}
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-3">
                <div className="space-y-4">
                  <Table
                    bordered
                    stripped
                    layout="auto"
                    className="mb-4"
                    columns={supportingDocumentColumns}
                    dataSource={result}
                    isLoading={isLoading}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    pagination={data?.paginator}
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard
          title="Upload Dokumen Perjanjian 3 Pihak"
          isCollapse={false}
        >
          <div className="w-full">
            <DropzoneFieldForm
              name="doc_final"
              informationText="PDF"
              accept={{ "application/pdf": [".pdf"] }}
              maxFiles={3}
              maxSize={10}
              multiple={false}
            />
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            onClick={() => navigate(`/debtor-agreement`)}
            outline
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            onClick={() => methods.handleSubmit(onSubmit)()}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default DebtorAgreementForm;
