import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";

import { Table } from "components";
import { useVehiclesList } from "hooks/collateralHandover";
import { CollateralModal } from "components/Modal";

const StatusBadge = ({ status }) => {
  let defaultWrapperClass = "bg-warning-50 text-warning-700";

  switch (status) {
    case "Realease Lunas":
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      break;
    case "Rusak":
      defaultWrapperClass = "bg-error-50 text-error-700";
      break;
    case "PUPN":
      defaultWrapperClass = "bg-indigo-50 text-indigo-700";
      break;
    case "Sudah Tidak Ada":
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
    default:
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
  }
  return (
    <div
      className={`badge border-transparent p-2 text-xs ${defaultWrapperClass}`}
    >
      {status}
    </div>
  );
};

const ModalDetail = ({ open, onClose }) => {
  return (
    <CollateralModal
      title="Detail"
      isOpen={open}
      showCloseBtn={true}
      onClose={() => onClose(false)}
    >
      <div className="flex flex-1 space-y-5 flex-col">
        <div class="grid grid-cols-3 gap-4">
          <div className="space-y-3">
            <div className="text-black font-semibold">
              Nama Kendaraan / Mesin
            </div>
            <div className="text-black">Mesin A</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Quantity</div>
            <div className="text-black">10</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Nilai Agunan</div>
            <div className="text-black">
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(10000000)}
            </div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">
              Umur Mesin saat Digunakan
            </div>
            <div className="text-black">3 Tahun</div>
          </div>
          <div className="space-y-3">
            <div className="text-black font-semibold">Status</div>
            <div className="text-black">
              <StatusBadge status="Realease Lunas" />
            </div>
          </div>
        </div>
        <hr />
        <div className="space-y-3">
          <div className="text-black font-semibold">Nama Kendaraan / Mesin</div>
          <ul className="list-inside list-disc">
            <li>Lorem ipsum dolor sit amet consectetur.</li>
            <li>Lorem ipsum dolor sit amet consectetur.</li>
            <li>Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
        </div>
      </div>
    </CollateralModal>
  );
};

const VehicleTable = ({ itemsPerPage = 10 }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(itemsPerPage);
  const [isModalOpen, setModalOpen] = useState(false);

  const { status, data } = useVehiclesList(["vehicles", { page, limit }]);
  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);
  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return no + ".";
        },
      },
      {
        id: "vehicle_name",
        title: "Nama Kendaraan",
        dataIndex: "vehicle_name",
        sortable: true,
        render: (value, data, index) => value ?? "-",
      },
      {
        id: "quantity",
        title: "Quantity",
        dataIndex: "quantity",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "age",
        title: "Umur mesin saat digunakan",
        dataIndex: "age",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "fiduciary",
        title: "No Akta Fidusia",
        dataIndex: "fiduciary",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        columnClassName: "text-center",
        sortable: true,
        render: (value, data, index) => (
          <div className="min-w-[170px] text-center">
            <StatusBadge status={value} />
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        width: 160,
        columnClassName: "text-center",
        className: "text-center",
        fixed: "right",
        render: (value, data, index) => {
          return (
            <div className="flex justify-center items-center">
              <AiOutlineEye
                className="text-gray-600 cursor-pointer"
                size={20}
                onClick={() => setModalOpen(true)}
              />
            </div>
          );
        },
      },
    ];
  }, [page, limit]);
  return (
    <div className="space-y-4">
      <Table
        bordered
        layout="fixed"
        isLoading={status === "loading"}
        columns={tableColumns}
        dataSource={data?.result ?? []}
        pagination={data?.pagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        limit={limit}
      />
      <ModalDetail open={isModalOpen} onClose={(val) => setModalOpen(val)} />
    </div>
  );
};

export default VehicleTable;
