import image from "assets/Logo.png";
import masterDataMenus from "constants/Menus/menu";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

const Sidebar = ({ isSidebarOpen }) => {
  const [isOpen, _] = useState(true);

  return (
    <aside
      className="fixed left-0 top-0 bottom-0 flex-col hidden sm:flex overflow-hidden border-gray-500 bg-gray-900"
      style={{
        boxShadow: "1px 1px 5px 1px #0000001F",
        flex: `0 0 ${isSidebarOpen ? "250px" : "0px"}`,
        maxWidth: `${isSidebarOpen ? "250px" : "0px"}`,
        minWidth: `${isSidebarOpen ? "250px" : "0px"}`,
        width: `${isSidebarOpen ? "250px" : "0px"}`,
        transition: "all .2s",
        zIndex: 2,
      }}
    >
      <div className={`ml-4 mt-8 flex relative items-center`}>
        <div className="flex-1">
          <img src={image} alt="logo" className="w-full h-auto max-w-[140px]" />
        </div>
      </div>
      <div
        className={`w-full mt-6 pt-[13px] pb-[13px] text-center bg-gray-700 text-gray-300 text-sm`}
      >
        Perjanjian & Jaminan
      </div>
      <ul className="flex-2 space-y-2 m-4 overflow-y-auto">
        {map(masterDataMenus, ({ label, icon, path, id, child }, key) => (
          <MenuItem
            key={key}
            label={label}
            icon={icon}
            path={path}
            id={id}
            child={child}
            isOpenSidebar={isOpen}
          />
        ))}
      </ul>
      <div
        className={`text-white font-normal text-sm flex-1 flex items-end justify-center mb-4`}
      >
        © BPDLH 2023, Version 1.0
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
};

const MenuItem = ({ label, icon, path, id, child, isOpenSidebar = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    location?.pathname?.includes(path) && setIsOpen(true);
  }, [location?.pathname, path]);

  return (
    <li className="cursor-pointer space-y-2">
      <Link
        to={child?.length > 0 ? "#" : path}
        onClick={() => (child?.length > 0 ? setIsOpen(!isOpen) : null)}
        className={`flex items-center justify-between flex-1 rounded-lg p-2 ${
          !isOpenSidebar ? "justify-center" : ""
        } hover:bg-primary-700 ${
          location?.pathname?.includes(path) && "bg-primary-700"
        }`}
      >
        <div className="font-semibold text-sm text-white flex items-center space-x-1">
          {icon}
          {isOpenSidebar && <span>{label}</span>}
        </div>
        <span>
          {child?.length > 0 ? (
            isOpen ? (
              <FiChevronUp className="text-white text-xl" />
            ) : (
              <FiChevronDown className="text-white text-xl" />
            )
          ) : null}
        </span>
      </Link>
      {isOpenSidebar && child?.length > 0 && isOpen && (
        <div className="space-y-2">
          {map(child, ({ label, path, id }, key) => (
            <Link
              to={path}
              className={`p-2 pl-6 font-bold text-sm ${
                location?.pathname?.includes(path)
                  ? "text-primary-600"
                  : "text-white"
              } rounded-lg flex items-center ${
                !isOpenSidebar ? "justify-center" : ""
              } hover:bg-primary-900 hover:text-primary-600 ${
                location?.pathname?.includes(path) && "bg-primary-900"
              }`}
              key={key}
            >
              {location?.pathname?.includes(path) && (
                <div className="w-2 h-2 mr-3 rounded-full bg-primary-600" />
              )}
              {isOpenSidebar && <span className="text-sm">{label}</span>}
            </Link>
          ))}
        </div>
      )}
    </li>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  id: PropTypes.string,
  path: PropTypes.string,
  child: PropTypes.array,
  isOpenSidebar: PropTypes.bool,
};

export default Sidebar;
