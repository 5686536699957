import axios from "axios";

export const getNotaryList = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/notary`,
    { params }
  );
  return data;
};

export const getNotaryDetail = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/notary/${id}`
  );
  return data;
};

export const createNotary = async (payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_PERJANJIAN}/notary`,
    payload
  );
  return data;
};

export const editNotary = async (id, payload) => {
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/notary/${id}`,
    payload
  );
  return data;
};

