import { useQuery, useMutation } from "react-query";
import { DONOR } from "services";

export const useDonorListById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return DONOR.getDonorAgreementListById({ ...params });
    },
    ...options,
  });
};

export const useDonorAgreementListFromPPD = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return DONOR.getDonorAgreementListFromPPD({ ...params });
    },
    ...options,
  });
};

export const useDonorAgreementList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return DONOR.getDonorAgreementList({ ...params });
    },
    ...options,
  });
};

export const useDonorAgreementDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return DONOR.getDonorAgreementDetail(id, signal);
    },
    ...options,
  });
};

export const usePostDonorAgreement = () => {
  return useMutation((payload) => DONOR.createDonorAgreement(payload));
};

export const useEditDonorAgreement = (id) => {
  return useMutation((payload) => DONOR.editDonorAgreement(id, payload));
};
