import axios from "axios";

export const getBankTrustyAgreementListById = async (params) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_PPD}/bank-account`, { params });
  return data
};

export const getBankTrustyAgreementListFromPPD = async (params) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_PPD}/donor/with-bank-account`, { params });
  return data;
}

export const getBankTrustyAgreementList = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-bank-trusty`,
    { params }
  );
  return data;
};

export const getBankTrustyAgreementDetail = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-bank-trusty/${id}`
  );
  return data;
};

export const createBankTrustyAgreement = async (payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-bank-trusty`,
    payload
  );
  return data;
};

export const editBankTrustyAgreement = async (id, payload) => {
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-bank-trusty/${id}`,
    payload
  );
  return data;
};

export const getProposal = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN_V2}/agreement-bank-trusty/proposal`,
    { params }
  );
  return data;
}

export const createBankTrustyV2 = async (isUpdate, payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_PERJANJIAN_V2}/agreement-bank-trusty?isUpdate=${isUpdate}`,
    payload
  );
  return data;
}
export const getBankTrustyV2 = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN_V2}/agreement-bank-trusty`,
    {params}
  );
  return data;
}

export const getBankTrusteeDetail = async (param) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN_V2}/agreement-bank-trusty/${param}`,
  );
  return data;
}