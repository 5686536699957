import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs, Button, CollapsingCard, Label, Table } from "components";
import {
  AsyncSelectForm,
  CustomSelectForm,
  InputForm,
} from "components/HookForm";
import {
  useBankTrustyAgreementListById,
  usePostBankTrustyAgreement
} from "hooks/bankTrusty";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import * as yup from "yup";

const bankTrustyOptions = async (search, loadedOptions, { page }) => {
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_API_PPD
      }/donor/with-bank-account?page=${
        page ?? 1
      }&limit=10&search=${search}`
    );
    const responseJSON = await response.json();

    return {
      options: responseJSON.result,
      hasMore: responseJSON.paginator.hasNextPage,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const validationSchema = yup.object({
  implementation_date: yup
    .string()
    .required("Tanggal Implementasi tidak boleh kosong"),
  invitation_number: yup
    .string()
    .required("Nomor Undangan Pelaksanaan tidak boleh kosong"),
  signatory_offices: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .test(
      "required",
      "Pejabat penanda tanganan tidak boleh kosong",
      (value) => {
        if (!value) return false;
        else if (value !== null && value.value && value.label)
          return !!value.value && !!value.label;
        return true;
      }
    )
    .nullable(),
  bank_trusty_list: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .test("required", "Bank Trustee list tidak boleh kosong", (value) => {
      if (!value) return false;
      else if (value !== null && value.value && value.label)
        return !!value.value && !!value.label;
      return true;
    })
    .nullable(),
});

const signatoryOfficesList = [{ value: "Direktur", label: "Direktur" }];

const BankTrustyAgreementForm = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [bankTrustyOption, setBankTrustyOption] = useState();
  const [signatoryOffices, setSignatoryOffices] = useState(null);
  const [dataChecked, setDataChecked] = useState([]);
  const [result, setResult] = useState([]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const postBankTrustyAgreement = usePostBankTrustyAgreement();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const { data: bankTrusty, isLoading, refetch } = useBankTrustyAgreementListById(
    [
      "bank_trusty_agreement_list_by_id",
      {
        page,
        limit,
        donorId: bankTrustyOption?._id ?? undefined,
      },
    ],
    { enabled: false, onSuccess: (data) => setResult(data.result) }
  );

  const links = [
    {
      label: "Penyusunan Perjanjian",
      path: "#",
    },
    {
      label: "Perjanjian Bank Trustee",
      path: "/agreement-bank-trusty",
    },
    {
      label: "Kontrak Perjanjian",
    },
  ];

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const tableColumns = useMemo(() => {
    return [
      {
        id: "actions",
        title: "",
        dataIndex: "actions",
        render: (value, data, index) => (
          <input
            type="checkbox"
            className="text-primary-600 rounded-md border-[1px] border-gray-300 !shadow-none checked:!border-primary-600 focus:!shadow-none focus:!ring-0 focus:!ring-offset-0 focus:!ring-transparent focus:!outline-none"
            checked={
              dataChecked?.findIndex((x) => x.proposal_id === data._id) > -1
            }
            onChange={() => handleCheck(data)}
          />
        ),
      },
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(bankTrusty?.paginator?.page) * parseInt(bankTrusty?.paginator?.limit) -
            parseInt(bankTrusty?.paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "proposal_title",
        title: "Judul Proposal",
        dataIndex: "proposal_title",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.proposalTitle ?? "-"}
          </div>
        ),
      },
      {
        id: "donor_name",
        title: "Nama Donor",
        dataIndex: "donor_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.donorName ?? "-"}
          </div>
        ),
      },
      {
        id: "bank_name",
        title: "Nama Bank",
        dataIndex: "bank_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.bankName ?? "-"}
          </div>
        ),
      },
      {
        id: "submit_date",
        title: "Tanggal Pengajuan",
        dataIndex: "submit_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.submitDate
              ? moment(data?.submitDate).format("DD-MM-YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        // width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex items-center">
            <BiPencil
              className="text-gray-600 cursor-not-allowed flex-1"
              size={20}
              // onClick={() => navigate(`/agreement-donor/upload`)}
            />
            <BiTrash
              className="text-gray-600 cursor-not-allowed flex-1"
              size={20}
            />
          </div>
        ),
      },
    ];
  }, [result, dataChecked]);

  // ======================== Handler
  const handleFilterBankTrusty = () => {
    if (bankTrustyOption) {
      refetch();
    }
  };

  const handleResetBankTrusty = () => {
    setBankTrustyOption(null);
    setResult([]);
    setDataChecked([]);
    methods.reset((prevState) => ({
      ...prevState,
      bank_trusty_list: null,
    }));
  };

  const handleCheck = (item) => {
    const payload = {
      proposal_id: item._id,
      proposal_title: item.proposalTitle,
      donor_id: item.donorId,
      donor_name: item.donorName,
      bank_account_name: item.accountName,
      bank_account_number: item.accountNumber,
      currency: item.currency
    };

    setDataChecked((prevState) => {
      if (prevState.findIndex((x) => x.proposal_id === item._id) > -1) {
        return prevState.filter((x) => x.proposal_id !== item._id);
      } else {
        return [...prevState, payload];
      }
    });
  };

  const onSubmit = (data) => {
    const {
      implementation_date,
      invitation_number,
      signatory_offices,
      delegate_decision_number,
      delegate_decision_date,
    } = data;

    const payload = {
      implementation_date,
      invitation_number,
      signatory_offices: signatory_offices.value,
      delegate_decision_number,
      delegate_decision_date: delegate_decision_date !== "" ? delegate_decision_date : null,
      selected_donor: dataChecked,
    };

    if (bankTrustyOption && dataChecked.length < 1) {
      enqueueSnackbar("Harap pilih salah satu data proposal.", {
        variant: "error",
      });
    } else {
      postBankTrustyAgreement.mutate(payload, {
        onSuccess: (data, variables) => {
          navigate("/agreement-bank-trusty", { state: { tab: 1 } });

          enqueueSnackbar("Data Bank Trustee berhasil dibuat.", {
            variant: "success",
          });
        },
        onError: (error, variables) => {
          enqueueSnackbar(error.message, {
            variant: "error"
          })
        }
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard
          title="Form Rencana Penandatanganan Perjanjian"
          isCollapse={false}
        >
          <div className="flex flex-col gap-4">
            <div className="flex gap-16">
              <div className="flex flex-col w-1/3 gap-1">
                <Label required>Tanggal Pelaksanaan</Label>
                <InputForm
                  type="date"
                  name="implementation_date"
                  className="w-full"
                />
              </div>

              <div className="flex flex-col w-1/3 gap-1">
                <Label required>Nomor Undangan Pelaksanaan</Label>
                <InputForm
                  name="invitation_number"
                  className="w-full"
                  placeholder="Masukkan Nomor Undangan Pelaksanaan"
                />
              </div>

              <div className="flex flex-col w-1/3 gap-1">
                <Label required>Pejabat Penanda Tanganan</Label>
                <CustomSelectForm
                  options={signatoryOfficesList}
                  value={signatoryOffices}
                  onChange={setSignatoryOffices}
                  name="signatory_offices"
                  placeholder="Pilih satu"
                />
              </div>
            </div>

            <div className="flex gap-16">
              <div className="flex flex-col w-1/3 gap-1">
                <Label>No Keputusan Delegasi</Label>
                <InputForm
                  name="delegate_decision_number"
                  className="w-full"
                  placeholder="Masukkan Nomor Keputusan Delegasi"
                />
              </div>

              <div className="flex flex-col w-1/3 gap-1">
                <Label>Tanggal Keputusan Delegasi</Label>
                <InputForm
                  type="date"
                  className="w-full"
                  name="delegate_decision_date"
                  placeholder="Pilih Tanggal"
                />
              </div>

              <div className="flex flex-col w-1/3 gap-1"></div>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Daftar Bank Trustee" isCollapse={false}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col w-1/2 gap-1">
              <Label required>Pilih Data</Label>
              <div className="flex gap-2 items-center">
                <AsyncSelectForm
                  name="bank_trusty_list"
                  value={bankTrustyOption}
                  loadOptions={bankTrustyOptions}
                  getOptionValue={(opt) => opt._id}
                  getOptionLabel={(opt) => opt.name}
                  onChange={setBankTrustyOption}
                  additional={{ page: 1 }}
                />
                <Button
                  disabled={!bankTrustyOption}
                  onClick={handleFilterBankTrusty}
                  className={`${
                    methods.formState.errors?.bank_trusty_list ? "mb-6" : "mb-0"
                  } bg-primary-700 text-white w-1/4 !min-h-[2.5rem] !h-10 hover:bg-primary-800`}
                  size="sm"
                >
                  Filter
                </Button>
                <Button
                  disabled={!bankTrustyOption}
                  onClick={handleResetBankTrusty}
                  className={`${
                    methods.formState.errors?.bank_trusty_list ? "mb-6" : "mb-0"
                  } bg-red-600 text-white w-1/4 !min-h-[2.5rem] !h-10 hover:bg-red-700`}
                  size="sm"
                >
                  Reset
                </Button>
              </div>
            </div>

            {/* table */}
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-3">
                <div className="space-y-4">
                  <Table
                    bordered
                    stripped
                    layout="auto"
                    className="mb-4"
                    columns={tableColumns}
                    dataSource={result}
                    isLoading={isLoading}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    pagination={bankTrusty?.paginator}
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            type="button"
            onClick={() => navigate("/agreement-bank-trusty")}
            outline
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={() => methods.handleSubmit(onSubmit)()}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default BankTrustyAgreementForm;
