import { useMutation, useQuery } from "react-query";
import { BANK_TRUSTY } from "services";

export const useBankTrustyAgreementListById = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return BANK_TRUSTY.getBankTrustyAgreementListById({ ...params });
    },
    ...options,
  });
};

export const useBankTrustyAgreementListFromPPD = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return BANK_TRUSTY.getBankTrustyAgreementListFromPPD({ ...params });
    },
    ...options,
  });
};

export const useBankTrustyAgreementList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return BANK_TRUSTY.getBankTrustyAgreementList({ ...params });
    },
    ...options,
  });
};

export const useBankTrustyAgreementDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return BANK_TRUSTY.getBankTrustyAgreementDetail(params);
    },
    ...options,
  });
};

export const usePostBankTrustyAgreement = () => {
  return useMutation((payload) =>
    BANK_TRUSTY.createBankTrustyAgreement(payload)
  );
};

export const useEditBankTrustyAgreement = (id) => {
  return useMutation((payload) => BANK_TRUSTY.editBankTrustyAgreement(id, payload));
};


// V2

export const useProposal = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return BANK_TRUSTY.getProposal(params);
    },
    ...options,
  })
}

export const usePostBankTrusty = (isUpdate) => {
  return useMutation((payload) => BANK_TRUSTY.createBankTrustyV2(isUpdate ,payload));
}

export const useBankTrustyList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return BANK_TRUSTY.getBankTrustyV2({ ...params });
    },
    ...options,
  });
};


export const useBankTrusteeDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ queryKey }) => {
      const params = queryKey[1];
      const data = await BANK_TRUSTY.getBankTrusteeDetail(params)
      return data.result ;
    },
    enabled: !!keys[1]
  });
} 