import classNames from "classnames";
import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useAgreementGroupMember } from "hooks/directDistribution";
import { map } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getAgreementNonPeroranganList } from "services/directDistribution";
import { removeLocalStorage } from "utils";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
  },
];

const categories = [
  {
    label: "Perhutanan sosial",
    value: "Perhutanan sosial",
  },
  {
    label: "Non Perhutanan Sosial",
    value: "Non Perhutanan Sosial",
  },
];

const tabs = [
  {
    label: "Perorangan Dalam Kelompok",
  },
  {
    label: "Lembaga Penyalur",
  },
  {
    label: "Non Perorangan",
  },
];

const badanUsahaData = {
  status: 200,
  message: "OK",
  result: [
    {
      _id: "657c745c293ce43803d3e55c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      companyName: "Bank",
      registrationDate: new Date(),
      fdbCategory: "Perhutanan Sosial",
    },
    {
      _id: "657c745c293ce43803d3e56c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      companyName: "Koperasi",
      registrationDate: new Date(),
      fdbCategory: "Perhutanan Sosial",
    },
    {
      _id: "657c745c293ce43803d3e57c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      companyName: "Non Koperasi",
      registrationDate: new Date(),
      fdbCategory: "Non Perhutanan Sosial",
    },
    {
      _id: "657c745c293ce43803d3e58c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      companyName: "Bank",
      registrationDate: new Date(),
      fdbCategory: "Perhutanan Sosial",
    },
  ],
  paginator: {
    itemCount: 4,
    limit: 10,
    pageCount: 1,
    page: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
};

const badanHukumLainyaData = {
  status: 200,
  message: "OK",
  result: [
    {
      _id: "657c745c293ce43803d3e55c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      legalEntityName: "PT ABC",
      registrationDate: new Date(),
      fdbCategory: "Perhutanan Sosial",
    },
    {
      _id: "657c745c293ce43803d3e56c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      legalEntityName: "PT ABC",
      registrationDate: new Date(),
      fdbCategory: "Perhutanan Sosial",
    },
    {
      _id: "657c745c293ce43803d3e57c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      legalEntityName: "PT ABC",
      registrationDate: new Date(),
      fdbCategory: "Non Perhutanan Sosial",
    },
    {
      _id: "657c745c293ce43803d3e58c",
      implementationDate: new Date(),
      invitationNumber: 123456,
      legalEntityName: "PT ABC",
      registrationDate: new Date(),
      fdbCategory: "Perhutanan Sosial",
    },
  ],
  paginator: {
    itemCount: 4,
    limit: 10,
    pageCount: 1,
    page: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
};

const DirectDistribution = () => {
  const navigate = useNavigate();

  const [tabActive, setTabActive] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    // sort: "desc",
    // sortBy: "updated_at",
    search: "",
    year: "",
    category: "",
  });

  const listYear = useMemo(() => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = 2000;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    years.reverse();

    return years.map((year) => ({ value: year, label: year }));
  }, []);

  const { data: perOranganDalamKelompok } = useAgreementGroupMember(
    ["GET_LIST_AGREEMENT_GROUP_MEMBER", filters],
    {}
  );
  const { data: nonPerorangan } = useQuery({
    queryKey: ["GET_LIST_NON_PERORANGAN"],
    queryFn: async () => {
      return await getAgreementNonPeroranganList(
        {
          queryKey: {
            type_agency: 'Non Perorangan'
          }
        }
      )
    },
    // retry: false,
    // refetchOnWindowFocus: false,
  });
  
  const { data: lembagaPenyalur } = useQuery({
    queryKey: ["GET_LIST_LEMBAGA_PENYALUR"],
    queryFn: async () => {
      return await getAgreementNonPeroranganList(
        {
          queryKey: {
            type_agency: 'Lembaga Penyalur'
          }
        }
      )
    },
    // retry: false,
    // refetchOnWindowFocus: false,
  });


  const handleNavigateForm = useCallback(() => {
    switch (tabActive) {
      case 0:
        navigate("/agreement-direct-distribution/individual-within-group/form");
        break;
      case 1:
        navigate("/agreement-direct-distribution/business-entity/form");
        break;
      case 2:
        navigate("/agreement-direct-distribution/other-legal-entities/form");
        break;

      default:
    }
  }, [tabActive, navigate]);

  const handleNavigateUpdate = useCallback(
    (id) => {
      removeLocalStorage("form");
      removeLocalStorage("template");
      switch (tabActive) {
        case 0:
          navigate(
            `/agreement-direct-distribution/${id}/individual-within-group/form`
          );
          break;
        case 1:
          navigate(`/agreement-direct-distribution/${id}/business-entity/form`);
          break;
        case 2:
          navigate(
            `/agreement-direct-distribution/${id}/other-legal-entities/form`
          );
          break;
        default:
      }
    },
    [tabActive, navigate]
  );

  const tableColumnPeroranganDalamKelompok = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(perOranganDalamKelompok.paginator.page) *
              parseInt(perOranganDalamKelompok.paginator.limit) -
            parseInt(perOranganDalamKelompok.paginator.limit) +
            index +
            1;
          // 1;

          return no;
        },
      },
      {
        id: "implementation_date",
        title: "Tanggal Pelaksanaan",
        dataIndex: "implementation_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.implementation_date
              ? moment(data?.implementation_date).format("DD/MM/YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "invitation_number",
        title: "Nomor Undangan",
        dataIndex: "invitation_number",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.invitation_number}
          </div>
        ),
      },
      {
        id: "group_leader_name",
        title: "Nama Ketua Kelompok",
        dataIndex: "group_leader_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_leader_name ?? "-"}
          </div>
        ),
      },
      {
        id: "group_name",
        title: "Nama Kelompok",
        dataIndex: "group_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_name}
          </div>
        ),
      },
      // {
      //   id: "registration_date",
      //   title: "Tanggal Registrasi",
      //   dataIndex: "registration_date",
      //   width: 540,
      //   className: "overflow-hidden text-ellipsis",
      //   sortable: true,
      //   render: (value, data, index) => (
      //     <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
      //       {data?.registration_date
      //         ? moment(data?.registration_date).format("DD/MM/YYYY")
      //         : "-"}
      //     </div>
      //   ),
      // },
      {
        id: "group_type",
        title: "Kategori FDB",
        dataIndex: "group_type",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            <div className="bg-primary-700 text-white w-max px-2 py-1 rounded">
              {data.group_type}
            </div>
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-2">
            {/* <FiEye className="text-gray-600 cursor-pointer flex-1" size={20} /> */}
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleNavigateUpdate(data?.agreement_id)}
            />
          </div>
        ),
      },
    ];
  }, [handleNavigateUpdate, perOranganDalamKelompok]);

  const tableColumnBadanUsaha = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(badanUsahaData.paginator.page) *
              parseInt(badanUsahaData.paginator.limit) -
            parseInt(badanUsahaData.paginator.limit) +
            index +
            1;

          return no;
        },
      },
      {
        id: "implementation_date",
        title: "Tanggal Pelaksanaan",
        dataIndex: "implementation_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.implementation_date
              ? moment(data?.implementation_date).format("DD/MM/YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "agreement_number",
        title: "No Perjanjian",
        dataIndex: "agreement_number",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.agreement_number ?? "-"}
          </div>
        ),
      },
      {
        id: "group_name",
        title: "Nama Badan Usaha",
        dataIndex: "group_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_name ?? "-"}
          </div>
        ),
      },

      {
        id: "group_leader_name",
        title: "Nama Narahubung",
        dataIndex: "group_leader_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.group_leader_name ?? "-"}
          </div>
        ),
      },
      {
        id: "group_type",
        title: "Kategori",
        dataIndex: "group_type",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            <div className="bg-primary-700 text-white w-max px-2 py-1 rounded">
              {data.group_type}
            </div>
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-2">
            {/* <FiEye className="text-gray-600 cursor-pointer flex-1" size={20} /> */}
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleNavigateUpdate(data.agreement_id)}
            />
          </div>
        ),
      },
    ];
  }, [handleNavigateUpdate]);

  const tableColumnBadanHukumLainnya = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(badanUsahaData.paginator.page) *
              parseInt(badanUsahaData.paginator.limit) -
            parseInt(badanUsahaData.paginator.limit) +
            index +
            1;

          return no;
        },
      },
      {
        id: "implementation_date",
        title: "Tanggal Pelaksanaan",
        dataIndex: "implementation_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.implementation_date
              ? moment(data?.implementation_date).format("DD/MM/YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "agreement_number",
        title: "No Perjanjian",
        dataIndex: "agreement_number",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.agreement_number ?? "-"}
          </div>
        ),
      },
      {
        id: "group_name",
        title: "Nama Badan Usaha",
        dataIndex: "group_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_name ?? "-"}
          </div>
        ),
      },

      {
        id: "group_leader_name",
        title: "Nama Narahubung",
        dataIndex: "group_leader_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.group_leader_name ?? "-"}
          </div>
        ),
      },
      {
        id: "group_type",
        title: "Kategori",
        dataIndex: "group_type",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            <div className="bg-primary-700 text-white w-max px-2 py-1 rounded">
              {data.group_type}
            </div>
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-2">
            {/* <FiEye className="text-gray-600 cursor-pointer flex-1" size={20} /> */}
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleNavigateUpdate(data.agreement_id)}
            />
          </div>
        ),
      },
    ];
  }, [handleNavigateUpdate]);

  const renderColumn = useCallback(() => {
    switch (tabActive) {
      case 0:
        return tableColumnPeroranganDalamKelompok;
      case 1:
        return tableColumnBadanUsaha;
      case 2:
        return tableColumnBadanHukumLainnya;
      default:
    }
  }, [
    tabActive,
    tableColumnPeroranganDalamKelompok,
    tableColumnBadanUsaha,
    tableColumnBadanHukumLainnya,
  ]);

  const renderDataSource = useCallback(() => {
    switch (tabActive) {
      case 0:
        return perOranganDalamKelompok?.result;
      case 1:
        return lembagaPenyalur ? lembagaPenyalur?.result : [];
      case 2:
        return nonPerorangan ? nonPerorangan?.result : [];
      default:
    }
  }, [tabActive, perOranganDalamKelompok, nonPerorangan, lembagaPenyalur]);

  const renderPaginator = useCallback(() => {
    switch (tabActive) {
      case 0:
        return perOranganDalamKelompok?.paginator;
      case 1:
        return lembagaPenyalur?.paginator;
      case 2:
        return nonPerorangan?.paginator;
      default:
    }
  }, [tabActive, perOranganDalamKelompok, lembagaPenyalur, nonPerorangan]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Daftar Perjanjian Perorangan</TitleText>
        <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={handleNavigateForm}
        >
          Buat Rencana Pelaksanaan Perjanjian
        </Button>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="flex items-center space-x-4">
            <SearchInput
              placeholder="Cari"
              onChange={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  search: e.target.value,
                }));
              }}
            />
            <div className="flex-1">
              <CustomSelect
                placeholder="Tahun"
                optionsData={listYear}
                isClearable
                onChange={(e) => {
                  setFilters((prev) => ({ ...prev, year: e?.value ?? "" }));
                }}
              />
            </div>
            <div className="flex-1">
              <CustomSelect
                placeholder="Karegori"
                optionsData={categories}
                isClearable
                onChange={(e) => {
                  setFilters((prev) => ({ ...prev, category: e?.value ?? "" }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="tabs">
              {map(tabs, (tab, key) => (
                <span
                  key={key}
                  role="tab"
                  tabIndex={tabActive === key ? 0 : -1}
                  onClick={() => {
                    setTabActive(key);
                  }}
                  onKeyDown={() => {}}
                  className={classNames(
                    "flex-1 tab tab-bordered font-medium hover:text-primary-700",
                    tabActive === key
                      ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                      : "border-b"
                  )}
                >
                  {tab.label}
                </span>
              ))}
            </div>

            <Table
              bordered
              stripped
              layout="auto"
              className="mb-4"
              columns={renderColumn()}
              dataSource={renderDataSource()}
              pagination={renderPaginator()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectDistribution;
