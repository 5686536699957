import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const MainPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/agreement-donor")
  }, []);

  return (
    <div></div>
  );
}

export default MainPage;