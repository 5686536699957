import axios from "axios";

export const createFileRequest = async (payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_FILE_SERVICE}/files`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

export const getFile = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_FILE_SERVICE}/files/${id}`
  );

  return response.data.data;
};

export const postFile = async (formData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_FILE_SERVICE}/files`,
    formData
  );

  return response.data.data;
};
