import { useCallback, useMemo, useState } from "react";
import { PiExportBold } from "react-icons/pi";

import { Breadcrumbs, CustomSelect, TitleText, Button, Tabs } from "components";
import ExampleMap from "assets/img/example-map.png";
import ExampleMap2 from "assets/img/example-map-2.png";
import TreeTable from "../Handover/Tables/TreeTable";
import VehicleTable from "../Handover/Tables/VehicleTable";
import LandTable from "../Handover/Tables/LandTable";
import CollateralTable from "../Handover/Tables/CollateralTable";

const links = [
  {
    label: "Dashboard Collateral",
  },
];

const locationArray = [
  {
    value: "indonesia",
    label: "Seluruh Indonesia",
    image: ExampleMap,
  },
  {
    value: "jakarta",
    label: "Provinsi DKI Jakarta",
    image: ExampleMap2,
  },
  {
    value: "jabar",
    label: "Provinsi Jawa Barat",
  },
];

const tabItems = [
  {
    id: "tree",
    label: "Agunan Pohon",
  },
  {
    id: "cash",
    label: "Cash Collateral",
  },
  {
    id: "land",
    label: "Tanah & Bangunan",
  },
  {
    id: "vehicle",
    label: "Kendaraan & Mesin",
  },
];

const CollateralDashboard = () => {
  const [locationFilter, setLocationFilter] = useState(locationArray[0]);
  const [activeTab, setActiveTab] = useState("tree");

  const onChangeLocation = useCallback(
    (obj) => setLocationFilter(obj ?? null),
    []
  );
  const onChangeTab = useCallback((active) => {
    setActiveTab(active);
  }, []);

  const RenderedTab = useMemo(() => {
    let component = null;
    switch (activeTab) {
      case "cash":
        component = (
          <div className="text-gray-base min-h-[150px]">
            <img
              src={locationFilter.image}
              alt="example-map"
              className="self-stretch max-h-[405px] rounded-[15px]"
            />
          </div>
        );
        break;
      case "land":
        component = (
          <div className="text-gray-base min-h-[150px]">
            <img
              src={locationFilter.image}
              alt="example-map"
              className="self-stretch max-h-[405px] rounded-[15px]"
            />
          </div>
        );
        break;
      case "vehicle":
        component = (
          <div className="text-gray-base min-h-[150px]">
            <img
              src={locationFilter.image}
              alt="example-map"
              className="self-stretch max-h-[405px] rounded-[15px]"
            />
          </div>
        );
        break;
      default:
        component = (
          <div className="text-gray-base min-h-[150px]">
            <img
              src={locationFilter.image}
              alt="example-map"
              className="self-stretch max-h-[405px] rounded-[15px]"
            />
          </div>
        );
        break;
    }
    return component;
  }, [activeTab, locationFilter]);

  const RenderedTable = useMemo(() => {
    let component = null;
    switch (activeTab) {
      case "cash":
        component = <CollateralTable itemsPerPage={5} />;
        break;
      case "land":
        component = <LandTable itemsPerPage={5} />;
        break;
      case "vehicle":
        component = <VehicleTable itemsPerPage={5} />
        break;
      default:
        component = <TreeTable itemsPerPage={5} />
        break;
    }
    return component;
  }, [activeTab]);

  return (
    <div className="flex flex-col space-y-6">
      <Breadcrumbs items={links} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <TitleText className="self-center">Dashboard Collateral</TitleText>
        <CustomSelect
          optionsData={locationArray}
          placeholder="Status"
          className="rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
          onChange={onChangeLocation}
          value={locationFilter}
          isClearable={true}
        />
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-6">
          <div className="space-y-6">
            <div className="flex space-x-4 items-center">
              <TitleText className="flex-1">{locationFilter.label}</TitleText>
              <Button
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                startIcon={<PiExportBold className="text-white" size={20} />}
              >
                Export
              </Button>
            </div>
            {locationFilter.value === "indonesia" && (
              <div className="card w-full bg-primary-50 shadow-sm rounded-xl border-primary-500 border-solid border-[1px]">
                <div className="card-body p-6">
                  <div className="space-y-6">
                    <div className="flex flex-col space-y-2">
                      <div className="text-sm text-gray-600">
                        Total Nilai Semua Agunan
                      </div>
                      <div className="text-2xl text-gray-900 font-semibold">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(1000000000)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-4">
              <div className="card w-full bg-white shadow-sm rounded-xl border-[#E0E0E0] border-solid border-[1px] relative">
                <div className="border-l-[4px] border-[#0086C9] rounded-tl-xl rounded-bl-xl absolute left-0 right-0 bottom-0 top-0"></div>
                <div className="card-body p-6">
                  <div className="flex flex-col space-y-3">
                    <div className="text-sm text-gray-600">
                      Total Cash Collateral
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="bg-[#ECFDF3] px-2.5 py-0.5 rounded-2xl flex items-center w-fit">
                        <div className="text-sm text-[#027A48]">1000</div>
                      </div>
                      <div className="break-all text-lg font-semibold text-[#0086C9]">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(1000000000)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-full bg-white shadow-sm rounded-xl border-[#E0E0E0] border-solid border-[1px] relative">
                <div className="border-l-[4px] border-[#E04F16] rounded-tl-xl rounded-bl-xl absolute left-0 right-0 bottom-0 top-0"></div>
                <div className="card-body p-6">
                  <div className="flex flex-col space-y-3">
                    <div className="text-sm text-gray-600">
                      Total Kendaraan & Mesin
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="bg-[#ECFDF3] px-2.5 py-0.5 rounded-2xl flex items-center w-fit">
                        <div className="text-sm text-[#027A48]">1000</div>
                      </div>
                      <div className="break-all text-lg font-semibold text-[#E04F16]">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(1000000000)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-full bg-white shadow-sm rounded-xl border-[#E0E0E0] border-solid border-[1px] relative">
                <div className="border-l-[4px] border-[#DD2590] rounded-tl-xl rounded-bl-xl absolute left-0 right-0 bottom-0 top-0"></div>
                <div className="card-body p-6">
                  <div className="flex flex-col space-y-3">
                    <div className="text-sm text-gray-600">
                      Total Tanah & Bangunan
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="bg-[#ECFDF3] px-2.5 py-0.5 rounded-2xl flex items-center w-fit">
                        <div className="text-sm text-[#027A48]">1000</div>
                      </div>
                      <div className="break-all text-lg font-semibold text-[#DD2590]">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(1000000000)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card w-full bg-white shadow-sm rounded-xl border-[#E0E0E0] border-solid border-[1px] relative">
                <div className="border-l-[4px] border-[#15B79E] rounded-tl-xl rounded-bl-xl absolute left-0 right-0 bottom-0 top-0"></div>
                <div className="card-body p-6">
                  <div className="flex flex-col space-y-3">
                    <div className="text-sm text-gray-600">Total Pohon</div>
                    <div className="flex flex-col space-y-2">
                      <div className="bg-[#ECFDF3] px-2.5 py-0.5 rounded-2xl flex items-center w-fit">
                        <div className="text-sm text-[#027A48]">1000</div>
                      </div>
                      <div className="break-all text-lg font-semibold text-[#15B79E]">
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          maximumFractionDigits: 0,
                        }).format(1000000000)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6">
        <Tabs items={tabItems} onTabChanged={onChangeTab} />
      </div>
      {RenderedTab}
      {locationFilter.value === "indonesia" ? (
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px] min-h-[150px] justify-center">
          <div className="text-gray-500 text-center">
            Silahkan pilih filter salah satu dari wilayah yang ingin diliihat
            detailnya
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-2">
            <div className="font-semibold text-gray-900">
              {`${tabItems.find((x) => x.id === activeTab).label} - ${
                locationFilter.label
              }`}
            </div>
            <div
              className={`badge border-transparent px-3 py-1 text-sm bg-[#ECFDF3] text-primary-700`}
            >
              {`Total Nilai Agunan: ${new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                maximumFractionDigits: 0,
              }).format(1000000000)}`}
            </div>
          </div>
          {RenderedTable}
        </div>
      )}
    </div>
  );
};

export default CollateralDashboard;
