import axios from "axios";

export const getListProposalLemtara = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_DAPROG}/call-for-proposal/list/review`
  );

  return data;
};

export const getNotesConcept = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_DAPROG}/cms/notes/detail/${queryKey[1]}`
  );

  return data;
};

export const getProfileUmumDetail = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_DAPROG}/general-profile/findByUserId/${queryKey[1]}`
  );

  return data;
};

export const updateLemtara = async ({ id, payload }) => {
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-intermediary-institution/${id}`,
    payload
  );

  return data;
};

// export const getDetailLemtara = async();

export const updateLemtaraProposal = async ({ payload }) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_DAPROG}/call-for-proposal/review/update`,
    payload
  );

  return data;
};
