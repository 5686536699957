import { useMutation, useQuery } from "react-query";
import { NOTARY } from "services";

export const useNotaryList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return NOTARY.getNotaryList({ ...params });
    },
    ...options,
  });
};


export const useNotaryDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return NOTARY.getNotaryDetail(id);
    },
    ...options,
  });
};
export const usePostNotary = () => {
  return useMutation((payload) => NOTARY.createNotary(payload));
};

export const useEditNotary = (id) => {
  return useMutation((payload) => NOTARY.editNotary(id, payload));
};
