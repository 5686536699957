import { useMutation, useQuery } from "react-query";
import { LEMTARA } from "services";

export const useGetListLemtara = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: LEMTARA.getListProposalLemtara,
    ...options,
  });
};

export const useGetNotesConceptDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: LEMTARA.getNotesConcept,
    ...options,
  });
};

export const useGetProfileByUserId = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: LEMTARA.getProfileUmumDetail,
    ...options,
  });
};

export const useUpdateLemtara = () => {
  return useMutation({ mutationFn: LEMTARA.updateLemtara });
};

export const useUpdateLemtaraProposal = () => {
  return useMutation({ mutationFn: LEMTARA.updateLemtaraProposal });
};
