import { debounce, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";

import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import {
  useDonorAgreementList,
  useDonorAgreementListFromPPD,
} from "hooks/donor";
import moment from "moment/moment";

// =============== Breadcrumbs Items
const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Perjanjian Donor",
  },
];

// =============== Tab Items
const tabs = [
  {
    label: "Daftar Agenda Penandatanganan Donor",
  },
  {
    label: "Daftar Donor Dalam Proses Submit",
  },
];

const DonorAgreement = () => {
  // =============== Initialize
  const navigate = useNavigate();

  const { state } = useLocation();

  // =============== State
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [tabActive, setTabActive] = useState(0);
  const [year, setYear] = useState(undefined);

  // =============== Effect
  useEffect(() => {
    if (state) {
      const { tab } = state;
      setTabActive(tab);
    }
  }, [state]);

  const { isLoading: isLoadingDonorPPD, data: donorPPD } =
    useDonorAgreementListFromPPD([
      "donor_agreement_list_from_ppd",
      {
        page,
        limit,
        search: keyword ?? undefined,
      },
    ]);

  const { isLoading: isLoadingDonorAgreement, data: donorAgreement } =
    useDonorAgreementList([
      "donor_agreement_list",
      {
        page,
        limit,
        search: keyword ?? undefined,
        year: year ? year.value : undefined,
      },
    ]);

  const yearsArray = useMemo(() => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = 2000;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    // Get years value from currentYear to startYear
    years.reverse();

    return years.map((year) => ({ value: year, label: year }));
  }, []);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) {
            return event?.target?.value;
          }
          return undefined;
        }),
      []
    ),
    800
  );

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  const handleEditClick = useCallback(
    (id) => {
      navigate(`/agreement-donor/${id}/upload`);
    },
    [navigate]
  );

  const tableColumnDonorPPD = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        // className: "w-fit",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(donorPPD?.paginator?.page) *
              parseInt(donorPPD.paginator?.limit) -
            parseInt(donorPPD?.paginator?.limit) +
            index +
            1;
          return no + ".";
        },
      },
      {
        id: "proposal_total",
        title: "Jumlah Proposal",
        dataIndex: "proposalTotal",
        sortable: true,
        render: (value, data, index) => data?.countProposal ?? "-",
      },
      {
        id: "donor_name",
        title: "Nama Donor",
        dataIndex: "donorName",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.name ?? "-"}
          </div>
        ),
      },
    ];
  }, [donorPPD]);

  const tableColumnsDonorAgreement = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        width: 20,
        columnClassName: "w-[50px]",
        render: (value, data, index) => {
          const no =
            parseInt(donorAgreement?.paginator?.page) *
              parseInt(donorAgreement?.paginator?.limit) -
            parseInt(donorAgreement?.paginator?.limit) +
            index +
            1;
          return no + ".";
        },
      },
      {
        id: "proposal_title",
        title: "Judul Proposal",
        dataIndex: "proposalTitle",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.proposal_title ?? "-"}
          </div>
        ),
      },
      {
        id: "donor_name",
        title: "Nama Donor",
        dataIndex: "donorName",
        sortable: true,
        render: (value, data, index) => data?.donor_name ?? "-",
      },
      // {
      //   id: "submit_date",
      //   title: "Tanggal Pengajuan",
      //   dataIndex: "submitDate",
      //   columnClassName: "text-center",
      //   className: "text-center",
      //   sortable: true,
      //   render: (value, data, index) =>
      //     data?.submit_date
      //       ? moment(data?.submit_date).format("DD-MM-YYYY")
      //       : "-",
      // },
      {
        id: "invitation_number",
        title: "No Kontrak",
        dataIndex: "invitationNumber",
        columnClassName: "text-center min-w-[120px]",
        className: "text-center",
        sortable: true,
        render: (value, data, index) => data?.invitation_number ?? "-",
      },
      {
        id: "implementation_date",
        title: "Tanggal Penandatanganan",
        dataIndex: "submissionDate",
        columnClassName: "text-center",
        className: "text-center",
        sortable: true,
        render: (value, data, index) =>
          data?.implementation_date
            ? moment(data?.implementation_date).format("DD-MM-YYYY")
            : "-",
      },
      // {
      //   id: "invitation_number",
      //   title: "No Undangan",
      //   dataIndex: "invitationNumber",
      //   columnClassName: "text-center",
      //   className: "text-center",
      //   sortable: true,
      //   render: (value, data, index) => data?.invitation_number ?? "-",
      // },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        sortable: true,
        width: 160,
        columnClassName: "text-center min-w-[120px]",
        className: "text-center min-w-[120px]",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex items-center w-full">
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleEditClick(data.id)}
            />
          </div>
        ),
      },
    ];
  }, [donorAgreement, handleEditClick]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Daftar Perjanjian Donor</TitleText>
        <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={() => navigate(`/agreement-donor/form`)}
        >
          Buat Rencana Pelaksanaan Perjanjian
        </Button>
      </div>

      {/* Filter */}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="flex items-center space-x-4">
            <div className="flex-1">
              <SearchInput placeholder="Pencarian" onChange={onSearch} />
            </div>
            {tabActive === 1 && (
              <div className="flex-1">
                <CustomSelect
                  value={year}
                  placeholder="Tahun"
                  optionsData={yearsArray}
                  onChange={setYear}
                  isClearable={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            {/* Tabs */}
            <div className="tabs">
              {map(tabs, (tab, key) => (
                <a
                  onClick={() => setTabActive(key)}
                  className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
                    key === tabActive
                      ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                      : "border-b"
                  }`}
                >
                  {tab.label}
                </a>
              ))}
            </div>

            {/* Table */}
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={
                tabActive === 0
                  ? tableColumnDonorPPD
                  : tableColumnsDonorAgreement
              }
              dataSource={
                tabActive === 0 ? donorPPD?.result : donorAgreement?.result
              }
              isLoading={
                tabActive === 0 ? isLoadingDonorPPD : isLoadingDonorAgreement
              }
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={
                tabActive === 0
                  ? donorPPD?.paginator
                  : donorAgreement?.paginator
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonorAgreement;
