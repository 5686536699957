import { AsyncPaginate } from "react-select-async-paginate";

import { useFormContext, Controller } from 'react-hook-form';

const AsyncSelectForm = ({ name, loadOptions, ...props }) => {
  const { control } = useFormContext();
  
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field, fieldState: {invalid, error} }) => (
        <div className="w-full">
          <AsyncPaginate
            {...field}
            {...props}
            onChange={(value) => {props.onChange(value); field.onChange(value)}}
            loadOptions={loadOptions}
            value={field.value}
            defaultOptions
            placeholder={props.placeholder ?? "Pilih satu"}
            // styles="w-2/4"
          />
          {invalid && <div className="text-xs text-error-600 mt-2">{error?.message}</div>}
        </div>
      )}
    />
  );
};

export default AsyncSelectForm;
