import axios from "axios";

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getLocalStorage = (name) => {
  const data = localStorage.getItem(name);

  if (!data) return;

  return JSON.parse(data);
};

export const removeLocalStorage = (name) => {
  const data = getLocalStorage(name);

  if (!data) return;

  localStorage.removeItem(name);
};

export const createFileRequest = async (payload, id) => {
  if (payload.length < 1) return;

  const formData = new FormData();

  // ? IF SUPPORT MULTIPLE FILE
  // for (const file of payload) {
  //   formData.append("file[]", file);
  // }
  // const { data } = await axios.post(
  //   `${process.env.REACT_APP_API_FILE_SERVICE}/files`,
  //   formData,
  //   {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   }
  // );

  // ? IF ONLY SUPPORT SINGLE FILE
  const files = [];
  for (const file of payload) {
    try {
      formData.set("file", file);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_PERJANJIAN}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const mapData = {
        id: data.data.id,
        key: data.data.key,
        mimeType: data.data.mimeType,
        name: data.data.name,
        size: data.data.size,
        url: data.data.url,
      };

      files.push(mapData);
    } catch (err) {
      console.error(err);
    }
  }

  return files;
};

export const createFileRequestLemtara = async (payload) => {
  if (payload.length < 1) return;

  const formData = new FormData();

  // ? IF SUPPORT MULTIPLE FILE
  // for (const file of payload) {
  //   formData.append("file[]", file);
  // }
  // const { data } = await axios.post(
  //   `${process.env.REACT_APP_API_FILE_SERVICE}/files`,
  //   formData,
  //   {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   }
  // );

  // ? IF ONLY SUPPORT SINGLE FILE
  const files = [];
  for (const file of payload) {
    try {
      formData.set("file", file);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_PERJANJIAN}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // const mapData = {
      //   id: data.data.id,
      //   key: data.data.key,
      //   mimeType: data.data.mimeType,
      //   name: data.data.name,
      //   size: data.data.size,
      //   url: data.data.url,
      // };

      const mapData = {
        fileId: data?.data?.id,
        fileName: data?.data?.name,
        fileSize: data?.data?.size,
        mimeType: data?.data?.mimeType,
        path: data?.data?.key,
      };

      files.push(mapData);
    } catch (err) {
      console.error(err);
    }
  }

  return files;
};
