import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs, Button, CollapsingCard, Label } from "components";
import { InputForm } from "components/HookForm";
import { useEditNotary, useNotaryDetail, usePostNotary } from "hooks/notary";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string().required("Nama notaris tidak boleh kosong"),
  sk_kemenkumham: yup.string().required("SK. KEMENKUMHAM tidak boleh kosong"),
  sk_ppat: yup.string().required("SK. PPAT tidak boleh kosong"),
  phone: yup.string().required("Nomor telepon tidak boleh kosong"),
  address: yup.string().required("Alamat tidak boleh kosong"),
});

const NotaryForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [result, setResult] = useState();

  const { data, isLoading } = useNotaryDetail(["notary_detail", id], {
    enabled: !!id,
    onSuccess: (data) => {
      setResult(data.result);
      methods.reset({
        name: data.result.name,
        sk_kemenkumham: data.result.sk_kemenkumham,
        sk_ppat: data.result.sk_ppat,
        phone: data.result.phone,
        address: data.result.address,
      });
    },
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: result?.name ?? "",
      sk_kemenkumham: result?.sk_kemenkumham ?? "",
      sk_ppat: result?.sk_ppat ?? "",
      phone: result?.phone ?? "",
      address: result?.address ?? "",
    },
  });

  const postNotary = usePostNotary();
  const editNotary = useEditNotary(id);

  const links = [
    {
      label: "Master Data Notaris",
      path: "/notary",
    },
    {
      label: "Tambah",
    },
  ];

  const onSubmit = (data) => {
    // TODO: create validation data
    const { name, sk_kemenkumham, sk_ppat, phone, address } = data;
    const payload = {
      name,
      sk_kemenkumham,
      sk_ppat,
      phone,
      address,
    };

    if (id) {
      editNotary.mutate(payload, {
        onSuccess: (res) => {
          enqueueSnackbar("Data Notary berhasil diubah.", {
            variant: "success",
          });
          navigate("/notary");
        },
        onError: (err) => {
          console.log(err);
        },
      });
    } else {
      postNotary.mutate(payload, {
        onSuccess: (res) => {
          enqueueSnackbar("Data Notary berhasil dibuat.", {
            variant: "success",
          });
          navigate("/notary");
        },
        onError: (err) => {
          console.log(err);
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard title="Tambah Data Notaris" isCollapse={false}>
          <div className="flex flex-col w-full gap-4">
            <div className="flex flex-col gap-1">
              <Label required>Nama Notaris</Label>
              <InputForm
                type="text"
                name="name"
                className="w-full"
                placeholder="Masukkan nama notaris"
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>SK. KEMENKUMHAM</Label>
              <InputForm
                type="text"
                name="sk_kemenkumham"
                className="w-full"
                placeholder="Masukkan SK. KEMENKUMHAM"
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>SK. PPAT</Label>
              <InputForm
                type="text"
                name="sk_ppat"
                className="w-full"
                placeholder="Masukkan SK. PPAT"
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>No Telepon</Label>
              <InputForm
                type="text"
                name="phone"
                className="w-full"
                placeholder="Masukkan no telepon"
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>Alamat</Label>
              <InputForm
                type="textarea"
                row={3}
                name="address"
                className="w-full"
                placeholder="Masukkan alamat"
              />
            </div>
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            onClick={() => navigate(`/notary`)}
            outline
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            onClick={() => methods.handleSubmit(onSubmit)()}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default NotaryForm;
