import { useQuery } from "react-query";
import { TEMPLATE } from "services";

export const useTemplateList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return TEMPLATE.getTemplateList({ ...params });
    },
    ...options,
  });
};