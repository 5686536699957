import axios from "axios";

export const getDonorAgreementListById = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PPD}/contract`,
    { params }
  );
  return data;
};

export const getDonorAgreementListFromPPD = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PPD}/donor/with-contract`,
    { params }
  );
  return data;
};

export const getDonorAgreementList = async (params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-donor`,
    { params }
  );
  return data;
};

export const getDonorAgreementDetail = async (id, signal) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-donor/${id}`, {signal}
  );
  return data;
};

export const createDonorAgreement = async (payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-donor`,
    payload
  );
  return data;
};

export const editDonorAgreement = async (id, payload) => {
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-donor/${id}`,
    payload
  );
  return data;
};