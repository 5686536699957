import { yupResolver } from "@hookform/resolvers/yup";
import {
  Breadcrumbs,
  Button,
  CollapsingCard,
  CustomSelect,
  Label,
  Table,
} from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillFilePdf } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { useNavigate, useParams } from "react-router";

import * as yup from "yup";

const links = [
  {
    label: "Perjanjian Debitur LP",
    path: "/debtor-agreement",
  },
  {
    label: "Penyaluran Langsung",
    path: -1,
  },
  {
    label: "Detail Data",
  }
];

const validationSchema = yup.object({
  delegation_decision_number: yup.string(),
});

const DebtorProfile = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const navigate = useNavigate();
  const { id } = useParams();

  const tableColumnJaminan = useMemo(
    () => [
      {
        id: "",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          return data.no;
        },
      },
      {
        id: "tree_type",
        title: "Jenis Pohon",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
      {
        id: "tree_type",
        title: "Keliling",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
      {
        id: "tree_type",
        title: "Kondisi",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
      {
        id: "tree_type",
        title: "Harga",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
    ],
    []
  );

  const tableColumnDocumenPendukung = useMemo(
    () => [
      {
        id: "",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          return data.no;
        },
      },
      {
        id: "tree_type",
        title: "KTP",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
      {
        id: "tree_type",
        title: "KK",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
      {
        id: "tree_type",
        title: "Dokumen Penawaran",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
      {
        id: "tree_type",
        title: "Dokumen Fidusia",
        dataIndex: "treeType",
        className: "overflow-hidden text-ellipsis",
        width: 540,
        render: (value, data, index) => {
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {data.treeType}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <CollapsingCard title="Profil Debitur">
        <div className="flex gap-4 min-h-[300px]">
          <div className="w-40 h-40 border"></div>
          <div className="flex flex-grow justify-between">
            <div className="flex flex-col justify-between">
              <div className="flex flex-col gap-2 max-w-[336px]">
                <div className="flex">
                  <span className="text-sm font-bold w-40">Nama Debitur</span>
                  <span className="text-sm">Lorem Ipsum Dolor</span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">NIP</span>
                  <span className="text-sm">123456789</span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">NAP</span>
                  <span className="text-sm">123456789</span>
                </div>
              </div>

              <div className="flex flex-col gap-2 max-w-[336px]">
                <div className="flex">
                  <span className="text-sm font-bold w-40">Bank</span>
                  <span className="text-sm">Bank BACA</span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">
                    Pengeluaran/bln
                  </span>
                  <span className="text-sm">Rp.XXX,XXX,XXX</span>
                </div>
                <div className="flex">
                  <span className="text-sm font-bold w-40">Pekerjaan</span>
                  <span className="text-sm">Swasta</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between mr-10">
              <div className="flex flex-col">
                <div className="flex flex-col gap-2 max-w-[336px]">
                  <div className="flex">
                    <span className="text-sm font-bold w-40">Alamat</span>
                    <span className="text-sm w-full">
                      Jl. Detail Alamat RT 001 RW 002, Kel. Bandung Kec.
                      Bandung, Kota Bandung, Jawa Barat
                    </span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">Longitude:</span>
                    <span className="text-sm w-full">1p827378126918732</span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">Latitude:</span>
                    <span className="text-sm w-full">1p827378126918732</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex flex-col gap-2 max-w-[336px]">
                  <div className="flex">
                    <span className="text-sm font-bold w-40">
                      Nomor Rekening
                    </span>
                    <span className="text-sm ">123123123123123</span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">
                      Sumber pengeluaran
                    </span>
                    <span className="text-sm ">Investasi</span>
                  </div>
                  <div className="flex">
                    <span className="text-sm font-bold w-40">
                      Pendapatan/bln
                    </span>
                    <span className="text-sm ">Rp. XXX,XXX,XXXX</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CollapsingCard>

      <FormProvider {...methods}>
        <CollapsingCard title="Data Pokok Pemohon" isCollapse={false}>
          <div className="grid grid-cols-4 gap-x-5 gap-y-7">
            <div className="flex flex-col gap-2">
              <Label>No Kepdirut</Label>
              <InputForm
                name="delegation_decision_number"
                className="w-full"
                placeholder="Masukan No Kepdirut"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Provinsi</Label>
              <InputForm
                name="province"
                className="w-full"
                placeholder="Masukan Provinsi"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Kabupaten</Label>
              <InputForm
                name="regency"
                className="w-full"
                placeholder="Masukan Kabupaten"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Kecamatan</Label>
              <InputForm
                name="district"
                className="w-full"
                placeholder="Masukan Kecamatan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Kelurahan</Label>
              <InputForm
                name="subdistrict"
                className="w-full"
                placeholder="Masukan Kelurahan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Status Perkawinan</Label>
              <InputForm
                name="marital_status"
                className="w-full"
                placeholder="Masukan Status Perkawinan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Nama Pasangan</Label>
              <InputForm
                name="spouse_name"
                className="w-full"
                placeholder="Masukan Nama Pasangan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>NIK Pasangan</Label>
              <InputForm
                name="spouse_nik"
                className="w-full"
                placeholder="Masukan NIK Pasangan"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>No Hp/Tlpn</Label>
              <InputForm
                name="phone"
                className="w-full"
                placeholder="Masukan No Hp/Tlpn"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Email</Label>
              <InputForm
                name="email"
                className="w-full"
                placeholder="Masukan email"
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Data Jaminan" isCollapse={false}>
          <div className="grid grid-cols-3 gap-5 mb-5">
            <div className="flex flex-col gap-2">
              <Label>Nama</Label>
              <p className="text-sm">Neneh</p>
            </div>
            <div className="flex flex-col gap-2">
              <Label>Blok</Label>
              <p className="text-sm">Lebak Cikapundung</p>
            </div>
            <div className="flex flex-col gap-2">
              <Label>Luas</Label>
              <p className="text-sm">2655 m2</p>
            </div>
            <div className="flex flex-col gap-2">
              <Label>Jumlah Pohon</Label>
              <p className="text-sm">161</p>
            </div>
            <div className="flex flex-col gap-2">
              <Label>No SPPT</Label>
              <p className="text-sm">32.12.100.002.010-0035.0</p>
            </div>
            <div className="flex flex-col gap-2">
              <Label>Atas Nama</Label>
              <p className="text-sm">Citra B Dadang</p>
            </div>
            <div className="flex flex-col  gap-2">
              <Label>Total Jaminan</Label>
              <InputForm name="" className="w-full" />
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <Label>Total Jaminan</Label>
              <label
                htmlFor="total_guarantee"
                className="flex gap-2 items-center"
              >
                <input
                  type="checkbox"
                  name=""
                  id="total_guarantee"
                  className="w-4 h-4 accent-primary-700"
                />
                <Label>Terpenuhi Sebanyak 125%</Label>
              </label>
            </div>
          </div>

          <div className="mb-5">
            <Table
              bordered
              stripped
              layout="auto"
              showPagination={false}
              columns={tableColumnJaminan}
            />
          </div>

          <div className="flex flex-col gap-2">
            <Label>Download Data Jaminan Dibawah ini</Label>
            <div className="flex border rounded-lg p-4 max-w-[406px] w-full items-center">
              <div className="flex gap-3 flex-grow">
                <AiFillFilePdf size={40} fill="#00BD52" />
                <div className="flex flex-col gap-1">
                  <Label>File Jaminan</Label>
                  <p className="text-xs">data_jaminan.xslx (2MB)</p>
                </div>
              </div>
              <Button
                size="sm"
                className="flex gap-2 bg-primary-700 hover:bg-primary-600 text-white"
              >
                <BsDownload />
                <h4>Download</h4>
              </Button>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Daftar Dokumen Pendukung" isCollapse={false}>
          <Table columns={tableColumnDocumenPendukung} showPagination={false} />
        </CollapsingCard>

        <CollapsingCard title="Pembiayaan" isCollapse={false}>
          <div className="grid grid-cols-2 gap-5 mb-5">
            <div className="flex flex-col gap-2">
              <Label>Jenis Usaha</Label>
              <CustomSelectForm
                name=""
                className="w-full"
                placeholder="Pilih Jenis Usaha"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Komoditas Usaha</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Komoditas Usaha"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Lama Usaha</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Lama Usaha (Tahun)"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Produktivitas/Hasil Panen Terakhir</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Produktivitas/Hasil Panen Terakhir"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Harga (Penjualan Terakhir)</Label>
              <InputForm name="" className="w-full" placeholder="Rp." />
            </div>
            <div className="flex flex-col gap-2">
              <Label>
                Luasan Lahan Yang digarap (m<sup>2</sup>)
              </Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Luasan Lahan Yang digarap"
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-5 mb-5">
            <div className="flex flex-col gap-2">
              <Label>Omzet Usaha</Label>
              <InputForm name="" className="w-full" placeholder="Rp." />
            </div>
            <div className="flex flex-col gap-2">
              <Label>HPP/Modal</Label>
              <InputForm name="" className="w-full" placeholder="Rp." />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Pendapatan Bersih</Label>
              <InputForm name="" className="w-full" placeholder="Rp." />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5 mb-5">
            <div className="flex flex-col gap-2">
              <Label>Tujuan Pemasaran</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Tujuan Pemasaran"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Mitra Usaha (Jika Ada)</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Mitra Usaha (Jika Ada)"
              />
            </div>
          </div>

          <div className="flex flex-col gap-2 mb-5">
            <Label>Tipe Usaha</Label>
            <div className="flex gap-4">
              <label htmlFor="label1" className="flex gap-2 cursor-pointer">
                <input
                  type="radio"
                  className="accent-primary-700"
                  name="radio"
                  id="label1"
                />
                <Label>Dikelola Sendiri</Label>
              </label>
              <label htmlFor="label2" className="flex gap-2 cursor-pointer">
                <input
                  type="radio"
                  name="radio"
                  className="accent-primary-700"
                  id="label2"
                />
                <Label>Dikelola Orang Lain</Label>
              </label>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <Label>Siklus Usaha</Label>
              <CustomSelect
                name=""
                className=""
                optionsData={[]}
                placeholder="Pilih Siklus Usaha"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Kuantitas Komoditas yang sudah ada</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Masukan Kuantitas Komoditas yang sudah ada"
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Jenis Usaha" isCollapse={false}>
          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <Label>Jenis Usaha</Label>
              <CustomSelect
                name=""
                className=""
                optionsData={[]}
                placeholder="Pilih Jenis Usaha"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Komoditas Usaha</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Komoditas Usaha"
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Kriteria" isCollapse={false}>
          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <Label>Jenis Usaha</Label>
              <CustomSelect
                name=""
                className=""
                optionsData={[]}
                placeholder="Pilih Jenis Usaha"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Komoditas Usaha</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Komoditas Usaha"
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Laporan" isCollapse={false}>
          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <Label>Jenis Usaha</Label>
              <CustomSelect
                name=""
                className=""
                optionsData={[]}
                placeholder="Pilih Jenis Usaha"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Komoditas Usaha</Label>
              <InputForm
                name=""
                className="w-full"
                placeholder="Komoditas Usaha"
              />
            </div>
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            type="button"
            onClick={() => navigate(-1)}
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            // onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default DebtorProfile;
