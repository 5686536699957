import React, { useState } from "react";
import classNames from "classnames";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const TableHeader = ({ items, headerClassName, defaultSortOrder }) => {
  const [sortOrder, setSortOrder] = useState(
    defaultSortOrder?.toLowerCase() ?? "asc"
  );

  const getValue = (value) => {
    if (typeof value === "string") return value;
    if (typeof value === "function") return value();
    return "";
  };

  if (!items.length) return null;
  return (
    <thead className={headerClassName}>
      <tr>
        {items.map((item, key) => {
          return (
            <th
              key={key}
              className={classNames(
                [
                  "bg-primary-800",
                  "text-white",
                  "text-sm",
                  "font-semibold",
                  "capitalize",
                  "relative"
                ],
                { sticky: Boolean(item?.fixed) },
                { "right-0": item?.fixed && item?.fixed === "right" },
                { "left-0": item?.fixed && item?.fixed === "left" },
                {"rounded-tl-xl": key === 0},
                {"rounded-tr-xl": key === (items.length - 1)},
                item.columnClassName && [...item.columnClassName.split(" ")]
              )}
            >
              {(item?.sortable && item?.sorter) ? (
                <div
                  className="gap-2 flex cursor-poiner"
                  onClick={() => {
                    setSortOrder((curr) => {
                      if (curr === "asc") return "desc";
                      return "asc";
                    });
                    item.sorter(item.dataIndex);
                  }}
                >
                  {getValue(item.title)}
                  {sortOrder === "asc" ? (
                    <BsArrowUpShort
                      size={20}
                      className="text-gray-700 cursor-pointer"
                    />
                  ) : (
                    <BsArrowDownShort
                      size={20}
                      className="text-gray-700 cursor-pointer"
                    />
                  )}
                </div>
              ) : (
                getValue(item.title)
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default React.memo(TableHeader);
