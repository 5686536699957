const FileDownloadCard = ({ size = 0, filename = "", link ="" }) => {
  return (
    <div className="p-4 space-x-4 flex rounded-xl bg-white border-[1px] border-gray-200">
      <div className="border-[4px] border-primary-50 rounded-[28px] bg-primary-100 p-1 flex items-center justify-center max-w[32px] max-h-[32px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M9.33317 1.51301V4.26671C9.33317 4.64008 9.33317 4.82676 9.40583 4.96937C9.46975 5.09481 9.57173 5.1968 9.69718 5.26071C9.83978 5.33337 10.0265 5.33337 10.3998 5.33337H13.1535M13.3332 6.65886V11.4667C13.3332 12.5868 13.3332 13.1469 13.1152 13.5747C12.9234 13.951 12.6175 14.257 12.2412 14.4487C11.8133 14.6667 11.2533 14.6667 10.1332 14.6667H5.8665C4.7464 14.6667 4.18635 14.6667 3.75852 14.4487C3.3822 14.257 3.07624 13.951 2.88449 13.5747C2.6665 13.1469 2.6665 12.5868 2.6665 11.4667V4.53337C2.6665 3.41327 2.6665 2.85322 2.88449 2.42539C3.07624 2.04907 3.3822 1.74311 3.75852 1.55136C4.18635 1.33337 4.7464 1.33337 5.8665 1.33337H8.00769C8.49687 1.33337 8.74146 1.33337 8.97163 1.38863C9.17571 1.43763 9.3708 1.51844 9.54974 1.62809C9.75157 1.75178 9.92453 1.92473 10.2704 2.27063L12.3959 4.39612C12.7418 4.74202 12.9148 4.91497 13.0385 5.1168C13.1481 5.29575 13.2289 5.49084 13.2779 5.69491C13.3332 5.92509 13.3332 6.16968 13.3332 6.65886Z"
            stroke="#00BD52"
            strokeWidth="1.33333"
            strokeDashoffset="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="space-y-1">
        <div className="text-sm text-gray-700">{`${filename}`}</div>
        <div className="text-sm text-gray-600">{`${size} MB`}</div>
        <a href={link} className="text-sm text-primary-600 cursor-pointer font-semibold">
          Download File
        </a>
      </div>
    </div>
  );
};

export default FileDownloadCard;
