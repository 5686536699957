import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs, Button, CollapsingCard, Label, Table } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import AsyncDropzoneFieldForm from "components/HookForm/AsyncDropzoneFieldForm";
import AtomSelect from "components/HookForm/Select";
import { useBankTrusteeDetail, usePostBankTrusty, useProposal } from "hooks/bankTrusty";
import { useBankMasterData } from "hooks/masterData";
import { map } from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Perjanjian Bank Trustee",
    path: "/agreement-bank-trusty",
  },
  {
    label: "Upload Bank Trustee",
  },
];

const currencyOptions = [
  {
    label: "IDR",
    value: "IDR",
  },
  {
    label: "EURO",
    value: "EURO",
  },
  {
    label: "POUNDS",
    value: "POUNDS",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "AUD",
    value: "AUD",
  },
]

const uploadSchema = yup.object({
  proposal_title: yup.string().required().label("Judul proposal tidak boleh kosong"),
  proposal_id: yup.string().required().label("Judul proposal tidak boleh kosong"),
  donor_name: yup.string().required().label("Judul proposal tidak boleh kosong"),
  currency: yup.string().required().label("Judul proposal tidak boleh kosong"),
  contract_number: yup.string().required().label("Nomor Perjanjian tidak boleh kosong"),
  implementation_date: yup.string().required().label("Tanggal Implementasi tidak boleh kosong"),
  doc_agreement_file: yup.string().required().label("Dokumen Perjanjian tidak boleh kosong"),
  information_bank_trusty: yup.array().of(yup.object({
    bank_name: yup.string().required().label("Bank Name tidak boleh kosong"),
    pic_name: yup.string().required().label("PIC Bank tidak boleh kosong"),
    phone: yup.number().required().label("Nomor Telepon tidak boleh kosong"),
    adress_bank_trusty: yup.string().required().label("Alamat tidak boleh kosong"),
  }))
});

const BankTrustyV2Form = () => {

  const {proposalId} = useParams()

  const navigate = useNavigate()

  const { data: dataProposal, isLoading } = useProposal(['GET_PROPOSAL'])
  const { data: dataBank } = useBankMasterData(['GET_BANK_MASTER_DATA'])

  const {data: dataBankTrusteeDetail} = useBankTrusteeDetail(['GET_BANK_TRUSTY_DETAIL', proposalId]);

  const methods = useForm({
    resolver: yupResolver(uploadSchema),
    defaultValues: {
      information_bank_trusty: dataBankTrusteeDetail?.members_bank_trusty ?? [{
        bank_name: '',
        pic_name: '',
        phone: '',
        adress_bank_trusty: ''
      }]    
    },
  });

  useEffect(() => {
    if (dataBankTrusteeDetail) {
      methods.reset({
        proposal_title: dataBankTrusteeDetail.proposal_title,
        proposal_id: dataBankTrusteeDetail.proposal_id,
        donor_name: dataBankTrusteeDetail.donor_name,
        currency: dataBankTrusteeDetail.currency,
        contract_number: dataBankTrusteeDetail.contract_number,
        implementation_date: dataBankTrusteeDetail.implementation_date,
        doc_agreement_file: dataBankTrusteeDetail.doc_agreement_file,
        information_bank_trusty: dataBankTrusteeDetail.members_bank_trusty.map((item) => {
          return {
            bank_name: item.bank_name,
            pic_name: item.pic_name,
            phone: item.phone,
            adress_bank_trusty: item.adress_bank_trusty
          }
        }),
      })
    } else {
      methods.reset({
        information_bank_trusty: [{
          bank_name: '',
          pic_name: '',
          phone: '',
          adress_bank_trusty: ''
        }],
      })
    }
  }, [dataBankTrusteeDetail, methods])

  const proposalOptions = useMemo(() => {
    if (dataProposal) {
      return map(dataProposal.result, (item) => ({
        value: item.id,
        label: item.generalInformation.title,
      }));
    }
    return [];
  }, [dataProposal]);

  const bankOptions = useMemo(() => {
    if (dataBank) {
      return map(dataBank.data, (item) => ({
        value: item.id,
        label: item.nama,
      }));
    }
  }, [dataBank])

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "information_bank_trusty",
  });

  const postBankTrusstee = usePostBankTrusty(proposalId ? 'true' : 'false');

  useEffect(() => {
    const proposalMethod = methods.watch('proposal_id');
    if (proposalMethod) {
      // Find the selected proposal
      const selectedProposal = dataProposal.result.find(option => option.id === proposalMethod);
      // Update donor_name using setValue
      methods.setValue('donor_name', selectedProposal ? selectedProposal?.donor?.donor : null);
      methods.setValue('proposal_id', proposalMethod);
      methods.setValue('proposal_title', selectedProposal ? selectedProposal?.generalInformation?.title : null);
    }
  }, [methods.watch('proposal_id'), proposalOptions]);

  const onSubmit = () => {
    const payload = methods.getValues();

    if (proposalId) payload.id = proposalId

    postBankTrusstee.mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar('Data Perjanjian Bank Trustee Berhasilahkan', {
          variant: 'success',
        })
        navigate("/agreement-bank-trusty", { state: { tab: 1 } });
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard title="Form Rencana Penandatanganan Perjanjian" isCollapse={false}>
          <div className="flex w-full gap-4">
            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label>Judul Proposal</Label>
                <AtomSelect
                  options={proposalOptions}
                  controllerName="proposal_id"
                  placeholder="Pilih satu"
                  returnObject={false}
                />
              </div>
              <div className="flex flex-col w-full gap-1">
                <Label>Mata Uang</Label>
                <AtomSelect
                  options={currencyOptions}
                  controllerName="currency"
                  placeholder="Pilih satu"
                  returnObject={false}
                />
              </div>
            </div>

            <div className="flex flex-col w-1/3 gap-2">
            <div className="flex flex-col w-full gap-1">
                <Label>Nama Donor</Label>
                <InputForm
                  name="donor_name"
                  className="w-full truncate"
                  disabled
                />
              </div>

              <div className="flex flex-col w-full gap-1">
                <Label>Tanggal Penetapan</Label>
                <InputForm
                  type="date"
                  className="w-full"
                  name="implementation_date"
                />
              </div>
            </div>

            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label>Nomor Perjanjian</Label>
                <InputForm
                  name="contract_number"
                  className="w-full"
                  placeholder="Masukkan Nomor Perjanjian"
                />
              </div>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Informasi Bank" isCollapse={false}>
          <div className="border border-solid border-gray-200 rounded-tl-2xl rounded-tr-2xl">
            <div className="overflow-x-auto">
              <table className="table table-striped min-w-full">
                <thead>
                  <tr>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tl-2xl">
                      Nama Bank
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                      PIC
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                      Nomor Telepon
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize">
                      Kantor Cabang / Alamat
                    </th>
                    <th className="bg-primary-800 text-white text-sm font-semibold capitalize rounded-tr-2xl">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, index) => {
                    return (
                      <tr index={field.id}>
                        <td>
                          <AtomSelect
                            options={bankOptions}
                            controllerName={`information_bank_trusty.${index}.bank_name`}
                            placeholder="Pilih satu"
                            returnObject={false}
                          />
                        </td>
                        <td>
                          <InputForm
                            name={`information_bank_trusty.${index}.pic_name`}
                            placeholder='Masukan Nama PIC'
                            className="w-full"
                          />
                        </td>
                        <td>
                          <InputForm
                            name={`information_bank_trusty.${index}.phone`}
                            placeholder='Masukan Nomor Telepon'
                            className="w-full"
                          />
                        </td>
                        <td>
                          <InputForm
                            name={`information_bank_trusty.${index}.adress_bank_trusty`}
                            placeholder='Masukan Alamat'
                            className="w-full"
                          />
                        </td>
                        <td>
                          {index > 0 && (
                            <Button
                              onClick={() => {
                                remove(index);
                              }}
                              iconOnly
                              className="w-fit bg-red-500"
                            >
                              <BiTrash fill="#fff" size={15} />
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={5} align="right">
                      <Button
                        onClick={() =>
                          append({
                            bank_name: "",
                            bank_pic: "",
                            bank_phone_number: "",
                            bank_address: "",
                          })
                        }
                        startIcon={<BiPlus fill="#fff" size={15} />}
                        className="w-fit bg-primary-600 text-white hover:bg-primary-700"
                      >
                        Tambah Informasi Bank
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Upload Dokumen Perjanjian" isCollapse={false}>
          <div className="w-full">
            <AsyncDropzoneFieldForm
              name="doc_agreement_file"
              informationText="PDF"
              maxSize={10}
              maxFiles={1}
            />
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            type="button"
            onClick={() =>
              navigate("/agreement-bank-trusty", { state: { tab: 1 } })
            }
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>

      </div>
    </FormProvider>
  )
};
export default BankTrustyV2Form;