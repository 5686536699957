import { yupResolver } from "@hookform/resolvers/yup";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  CollapsingCard,
  Label,
} from "components";
import { InputForm } from "components/HookForm";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { createFileRequest, getLocalStorage } from "utils";
import { usePutAgreementGroupMemberDebtorDraft } from "hooks/directDistribution";
import { enqueueSnackbar } from "notistack";
import { getFileFromUrl } from "pages/Agreement/BankTrusty/Upload";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
    path: "/agreement-direct-distribution",
  },
  {
    label: "Kontrak Perjanjian",
    path: -2,
  },
  {
    label: "Debitur",
    path: -1,
  },
  {
    label: "Draft",
  },
];

const uploadSchema = yup.object({
  agreement_draft_name: yup
    .string()
    .required("Judul Perjanjian tidak boleh kosong"),
  agreement_draft_number: yup
    .string()
    .required("Nomor Perjanjian tidak boleh kosong"),
  agreement_draft_date: yup
    .string()
    .required("Tanggal Perjanjian tidak boleh kosong"),
  doc_agreement_draft_template: yup
    .mixed()
    .test("required", "Template perjanjian tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
});

const IndividualWithinGroupUpload = () => {
  const methods = useForm({
    resolver: yupResolver(uploadSchema),
  });

  const { debtorId } = useParams();

  const location = useLocation();

  const handleMountForm = useCallback(async () => {
    if (!location.state.agreement_draft_name) return;
    const { state } = location;

    const blobFile = await getFileFromUrl(state.doc_agreement_draft_template);

    methods.reset({
      agreement_draft_name: state.agreement_draft_name,
      agreement_draft_number: state.agreement_draft_number,
      agreement_draft_date: state.agreement_draft_date,
      doc_agreement_draft_template: blobFile,
    });
  }, [location, methods]);

  const navigate = useNavigate();

  // const [selectedDraft, setSelectedDraft] = useState();
  const templateSectionRef = useRef();

  // const templates = [];

  // const getDraftFromStorage = useCallback(() => {
  //   const templateDraft = getLocalStorage("template");

  //   setSelectedDraft(templateDraft);
  // }, []);

  const mutatePutAgreementGroupMemberDebtorDraft =
    usePutAgreementGroupMemberDebtorDraft();

  const onSubmit = async (data) => {
    const payload = { ...data };

    await createFileRequest(data?.doc_agreement_draft_template).then(
      (files) => {
        payload.doc_agreement_draft_template = files;
      }
    );

    mutatePutAgreementGroupMemberDebtorDraft.mutate(
      { id: debtorId, payload },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Draft berhasil diupload",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Draft gagal diupload",
          });
        },
      }
    );
  };

  // useEffect(() => {
  //   getDraftFromStorage();
  // }, [getDraftFromStorage]);

  useEffect(() => {
    handleMountForm();
  }, [handleMountForm]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <CollapsingCard title="Informasi Perjanjian" isCollapse={false}>
          <div className="grid grid-cols-3 gap-5">
            <div className="flex flex-col gap-2">
              <Label>Judul Perjanjian</Label>
              <InputForm
                name="agreement_draft_name"
                className="w-full"
                placeholder="Masukan Judul Perjanjian"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Nomor Perjanjian</Label>
              <InputForm
                name="agreement_draft_number"
                className="w-full"
                placeholder="Masukan Nomor Perjanjian"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Tanggal Perjanjian</Label>
              <InputForm
                name="agreement_draft_date"
                className="w-full"
                type="date"
              />
            </div>
          </div>
        </CollapsingCard>

        <div ref={templateSectionRef}>
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>
                  {methods.watch("toggle_draft")
                    ? "Upload Template Perjanjian"
                    : "Template Perjanjian"}
                </div>
                {/* <Switch name="toggle_draft" /> */}
              </div>
            </CardHeader>
            <CardBody>
              <DropzoneFieldForm
                name="doc_agreement_draft_template"
                maxSize={10}
                accept={{
                  "application/docx": [".doc", ".docx"],
                }}
                informationText="DOCX"
              />
              {/* {methods.watch("toggle_draft") ? (
              ) : (
                <div>
                  {selectedDraft ? (
                    <div className="flex items-center w-full gap-8 flex-nowrap overflow-x-auto">
                      <div className="flex flex-col items-center w-fit relative">
                        <button
                          className="w-40 h-fit cursor-pointer"
                          tabIndex={0}
                          onClick={() => {
                            setLocalStorage("form", methods.getValues());

                            navigate(`/template/draft`, {
                              state: {
                                path: pathname,
                                template_id: selectedDraft?.template_id,
                                template_title: selectedDraft?.template_title,
                                template_content:
                                  selectedDraft?.template_content,
                              },
                            });
                          }}
                        >
                          <img
                            src={DraftTemplate}
                            alt="draft-template"
                            className="w-full h-full border border-slate-300 rounded-lg"
                          />
                        </button>
                        <p className="">{selectedDraft?.template_title}</p>
                        <button
                          className="absolute top-2 right-2 bg-black/10 p-[6px] rounded-md hover:text-red-600 z-20 cursor-pointer"
                          onClick={() => {
                            removeLocalStorage("template");
                            getDraftFromStorage();
                          }}
                        >
                          <RiDeleteBin6Line size={18} />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center w-full gap-8 flex-nowrap overflow-x-auto">
                      {map(templates?.result ?? [], (template, key) => (
                        <div
                          key={key}
                          className="flex flex-col items-center w-fit"
                        >
                          <button
                            className="w-40 h-fit cursor-pointer"
                            onClick={() => {
                              setLocalStorage("form", methods.getValues());
                              navigate(`/template/${template?.id}/edit`, {
                                state: {
                                  path: pathname,
                                  template_title: template?.title,
                                  template_content: template?.content,
                                },
                              });
                            }}
                          >
                            <img
                              src={DraftTemplate}
                              alt=""
                              className="w-full h-full border border-slate-300 rounded-lg"
                            />
                          </button>
                          <p className="">{template?.title}</p>
                        </div>
                      ))}
                      <BiSolidPlusCircle
                        onClick={() =>
                          navigate("/template/new", {
                            state: { path: pathname },
                          })
                        }
                        size={100}
                        fill="#01A24A"
                        className="cursor-pointer hover:fill-primary-600 flex-none"
                      />
                    </div>
                  )}
                </div>
              )} */}
            </CardBody>
          </Card>
        </div>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            type="button"
            onClick={() =>
              navigate("/agreement-bank-trusty", { state: { tab: 1 } })
            }
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default IndividualWithinGroupUpload;
