import React, { useCallback, useMemo, useState } from "react";
import { Table } from "components";
import { AiOutlineEye } from "react-icons/ai";
import { useTreesList } from "hooks/collateralHandover";
import Pohon from "assets/img/pohon.png";
import Jakarta from "assets/img/jakarta.png";

import { CollateralModal } from "components/Modal";

const StatusBadge = ({ status }) => {
  let defaultWrapperClass = "bg-warning-50 text-warning-700";

  switch (status) {
    case "Realease Lunas":
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      break;
    case "Rusak":
      defaultWrapperClass = "bg-error-50 text-error-700";
      break;
    case "PUPN":
      defaultWrapperClass = "bg-indigo-50 text-indigo-700";
      break;
    case "Sudah Tidak Ada":
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
    default:
      defaultWrapperClass = "bg-warning-50 text-warning-700";
      break;
  }
  return (
    <div
      className={`badge border-transparent p-2 text-sm ${defaultWrapperClass}`}
    >
      {status}
    </div>
  );
};

const ModalDetail = ({ open, onClose }) => {
  return (
    <CollateralModal
      title="Detail"
      isOpen={open}
      showCloseBtn={true}
      onClose={() => onClose(false)}
    >
      <div className="flex flex-1 space-y-5 flex-col">
        <div className="flex space-x-8">
          <div className="flex flex-col space-y-3">
            <div className="text-gray-900 font-semibold">Foto Pohon</div>
            <img
              src={Pohon}
              alt="example-tree"
              className="w-[150px] h-[150px]"
            />
          </div>
          <div className="flex flex-col space-y-8 flex-1">
            <div className="flex space-x-4">
              <div className="space-y-3 flex-1">
                <div className="text-gray-900 font-semibold">No Pohon</div>
                <div className="text-gray-900">123456789</div>
              </div>
              <div className="space-y-3 flex-1">
                <div className="text-gray-900 font-semibold">Nama Pohon</div>
                <div className="text-gray-900">Pohon 1</div>
              </div>
              <div className="space-y-3 flex-1">
                <div className="text-gray-900 font-semibold">Ukuran</div>
                <div className="text-gray-900">53 m2</div>
              </div>
            </div>
            <div className="flex space-x-8">
              <div className="space-y-3 flex-1">
                <div className="text-gray-900 font-semibold">Nilai Agunan</div>
                <div className="text-gray-900">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  }).format(10000000)}
                </div>
              </div>
              <div className="space-y-3 flex-1">
                <div className="text-gray-900 font-semibold">Debitur</div>
                <div className="text-gray-900">John Doe</div>
              </div>
              <div className="space-y-3 flex-1">
                <div className="text-gray-900 font-semibold">
                  No Akta Fidusia
                </div>
                <div className="text-gray-900">1234567890</div>
              </div>
            </div>
            <div className="space-y-3 flex-1">
              <div className="text-gray-900 font-semibold">Status</div>
              <div className="text-gray-900">
                <StatusBadge status="Realease Lunas" />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="space-y-3 flex-1">
          <div className="text-gray-900 font-semibold">Lokasi</div>
          <div className="text-gray-900">
            Lorem ipsum dolor sit amet consectetur. Lorem a suspendisse ipsum
            ullamcorper commodo. Viverra feugiat nunc morbi sit.
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="space-y-3 flex-1">
            <div className="text-gray-900 font-semibold">Provinsi</div>
            <div className="text-gray-900">DKI Jakarta</div>
          </div>
          <div className="space-y-3 flex-1">
            <div className="text-gray-900 font-semibold">Latitude</div>
            <div className="text-gray-900">-6.200000</div>
          </div>
          <div className="space-y-3 flex-1">
            <div className="text-gray-900 font-semibold">Longitude</div>
            <div className="text-gray-900">106.816666</div>
          </div>
          <div className="space-y-3 flex-1">
            <div className="text-gray-900 font-semibold">
              Link Kordinat Lokasi Gmaps
            </div>
            <a href="https://g.co/kgs/u5yTXM" className="text-gray-900">
              https://g.co/kgs/u5yTXM
            </a>
          </div>
        </div>
        <div className="space-y-3">
          <div className="text-gray-900 font-semibold">
            Posisi Pohon di Peta
          </div>
          <img
            src={Jakarta}
            alt="example-map"
            className="w-full self-stretch rounded-[15px]"
          />
        </div>
      </div>
    </CollateralModal>
  );
};

const TreeTable = ({ itemsPerPage = 10, data, status }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(itemsPerPage);
  const [isModalOpen, setModalOpen] = useState(false);

  // const { status, data } = useTreesList(["trees", { page, limit }]);

  const onChangePage = useCallback((val) => setPage(val), []);
  const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);
  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        render: (value, data, index) => {
          const no =
            parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
          return no + ".";
        },
      },
      {
        id: "tree_number",
        title: "No Pohon",
        dataIndex: "tree_number",
        sortable: true,
        render: (value, data, index) => value ?? "-",
      },
      {
        id: "tree_name",
        title: "Nama Pohon",
        dataIndex: "tree_name",
        sortable: true,
        render: (value, data, index) => (value ? `${value}` : "-"),
      },
      {
        id: "wide",
        title: "Ukuran",
        dataIndex: "wide",
        sortable: true,
        render: (value, data, index) => (value ? `${value} cm` : "-"),
      },
      {
        id: "collateral_value",
        title: "Nilai Agunan",
        dataIndex: "collateral_value",
        sortable: true,
        render: (value, data, index) =>
          value
            ? new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(value)
            : "-",
      },
    ];
  }, [page, limit]);
  return (
    <div className="space-y-4">
      <Table
        bordered
        layout="fixed"
        isLoading={status === "loading"}
        columns={tableColumns}
        dataSource={data?.result ?? []}
        pagination={data?.pagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        limit={limit}
      />
      <ModalDetail open={isModalOpen} onClose={(val) => setModalOpen(val)} />
    </div>
  );
};

export default TreeTable;
