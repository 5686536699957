import React, { useEffect, useState } from "react";
import Select from "react-select";

import { Controller, useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
// import { useMutation } from "@tanstack/react-query";

const AtomSelect = ({
  controllerName,
  options: initialOptions = [],
  className,
  isError = false,
  label = null,
  required = false,
  wrapperClass,
  returnObject = true,
  showErrorLabel = true,
  placeholder,
  asText,
  asTextClass,
  setValue = null,
  disable = false,
  type = "",
  mutation,
  customOnChange,
  ...props
}) => {
  const { control } = useFormContext();

  const [dynamicOptions, setDynamicOptions] = useState(initialOptions);
  const [inputValues, setInputValues] = useState("");

  const handleChange = (callback) => (selected) => {
    if (setValue) {
      setValue({ name: controllerName, value: selected });
    }
    if (typeof callback === "function") {
      if (customOnChange) customOnChange(selected);
      if (returnObject) {
        callback(selected);
      } else {
        callback(selected?.value);
      }
    }
  };

  const getValue = (value) => {
    if (returnObject) {
      return value;
    } else {
      if (type === "dynamic") {
        return dynamicOptions.find((e) => e.value === value);
      } else {
        return initialOptions.find((e) => e.value === value);
      }
    }
  };

  useEffect(() => {
    setDynamicOptions(Array.isArray(initialOptions) ? initialOptions : []);
  }, []);

  const handleInputChange = (inputValue, { action }) => {
    setInputValues(inputValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValues.trim() !== "") {
      const trimmedValue = inputValues.trim();

      const existingOption = dynamicOptions?.find(
        (option) => option.value === trimmedValue
      );

      if (!existingOption) {
        createOption.mutate({ nama: trimmedValue, deskripsi: trimmedValue });
        setDynamicOptions((prevOptions) => [
          ...prevOptions,
          { label: trimmedValue, value: trimmedValue },
        ]);
        setInputValues("");
      }
    }
  };

  const createOption = useMutation({
    mutationFn: (params) => {
      return mutation(params);
    },
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#01A24A" : "#EAECF0",
      boxShadow: "unset",
      "&:hover": {
        outline: "unset",
      },
      "&:focus": {
        border: "1px solid #01A24A",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0.25rem 1rem",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#01A24A" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#01A24A" : "#F3F4F6",
        color: state.isSelected ? "white" : "black",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#01A24A",
      color: "white",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "#01A24A",
        color: "white",
      },
    }),
  };

  const errorStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "red" : "red",
      boxShadow: "unset",
      "&:hover": {
        outline: "unset",
      },
      "&:focus": {
        border: "1px solid red",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0.25rem 1rem",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "red" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "red" : "#F3F4F6",
        color: state.isSelected ? "white" : "black",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "red",
      color: "white",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
  };
  return (
    <Controller
      name={controllerName}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div
            className={["form-control w-full", wrapperClass || ""].join(" ")}
          >
            {label && (
              <label className="flex gap-1 font-semibold text-[14px] ">
                <span className={`label-text`}>{label}</span>
                {required && <span className="text-[#F04438]">*</span>}
              </label>
            )}
            {asText ? (
              <div
                className={[
                  "min-h-[2.5rem] py-2 overflow-hidden text-ellipsis",
                  asTextClass,
                ].join(" ")}
              >
                {["string", "number"].includes(typeof value) || !value
                  ? value
                  : value?.label || JSON.stringify(value)}
              </div>
            ) : (
              <Select
                {...props}
                value={getValue(value)}
                onChange={handleChange(onChange)}
                onInputChange={type === "dynamic" && handleInputChange}
                className={className}
                onKeyDown={type === "dynamic" && handleKeyDown}
                options={type === "dynamic" ? dynamicOptions : initialOptions}
                placeholder={placeholder}
                styles={invalid ? errorStyles : customStyles}
                isDisabled={disable}
              />
            )}

            {invalid && showErrorLabel && (
              <div className="text-xs text-error-600 mt-2 label-text-alt text-error text-[#F04438]">
                {error?.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default AtomSelect;
