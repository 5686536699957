import { useMutation, useQuery } from "react-query";
import { DIRECT_DISTRIBUTION } from "services";

export const useAgreementGroupMember = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: DIRECT_DISTRIBUTION.getAgreementGroupMemberList,
    ...options,
  });
};

export const usePostAgreementGroupMember = () => {
  return useMutation(DIRECT_DISTRIBUTION.postAgreementGroupMember);
};

export const useAgreementGroupMemberDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: DIRECT_DISTRIBUTION.getAgreementGroupMemberDetail,
    ...options,
  });
};

export const useAgreementGroupMemberDebtorList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: DIRECT_DISTRIBUTION.getDebtorAgreementGroupMemberList,
    ...options,
  });
};

export const usePutAgreementGroupMemberDebtor = () => {
  return useMutation(DIRECT_DISTRIBUTION.updateAgreementGroupMemberDebtor);
};

export const usePutAgreementGroupMemberDebtorDraft = () => {
  return useMutation(DIRECT_DISTRIBUTION.updateAgreementGroupMemberDebtorDraft);
};

export const useAgreementGroupMemberDebtorDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: DIRECT_DISTRIBUTION.getDebtorAgreementGroupMemberDetail,
    ...options,
  });
};
