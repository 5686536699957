import { yupResolver } from "@hookform/resolvers/yup";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  CollapsingCard,
  Label,
} from "components";
import { InputForm } from "components/HookForm";
import { useCallback, useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { getDetailDebtor, putAgreementAgencyMemberDebtorDraft } from "services/directDistribution";
import { enqueueSnackbar } from "notistack";
import AsyncDropzoneFieldForm from "components/HookForm/AsyncDropzoneFieldForm";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
    path: "/agreement-direct-distribution",
  },
  {
    label: "Kontrak Perjanjian",
    path: -2,
  },
  {
    label: "Debitur",
    path: -1,
  },
  {
    label: "Upload Penyaluran Langsung",
  },
];

const uploadSchema = yup.object({
  agreement_draft_name: yup.string().required("Judul Perjanjian tidak boleh kosong"),
  agreement_draft_number: yup
    .string()
    .required("Nomor Perjanjian tidak boleh kosong"),
  agreement_draft_date: yup
    .string()
    .required("Tanggal Perjanjian tidak boleh kosong"),
  doc_agreement_draft_template: yup
    .mixed()
    .test("required", "Template perjanjian tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
});

const BusinessEntityUpload = () => {
  const methods = useForm({
    resolver: yupResolver(uploadSchema),
  });
  const { id_debtor } = useParams();

  const navigate = useNavigate();

  const templateSectionRef = useRef();


  const { data: detailDebtor } = useQuery(
    ['GET_DETAIL_DEBTOR', id_debtor],
    getDetailDebtor,
    {
      select: (data) => data?.result
    }
  )

  const handleMountForm = useCallback(async () => {
    if (detailDebtor) {
      methods.reset({
        agreement_draft_date: detailDebtor?.agreement_draft_date,
        agreement_draft_name: detailDebtor?.agreement_draft_name,
        agreement_draft_number: detailDebtor?.agreement_draft_number,
        doc_agreement_draft_template: detailDebtor?.doc_agreement_draft_template,
      })
    }
  }, [detailDebtor, methods])

  useEffect(() => {
    handleMountForm();
  }, [handleMountForm]);

  const mutatePutAgreementAgencypMemberDebtorDraft = useMutation(putAgreementAgencyMemberDebtorDraft)

  const onSubmit = async (data) => {
    const payload = methods.getValues()

    mutatePutAgreementAgencypMemberDebtorDraft.mutate(
      { id_debtor, payload },
      {
        onSuccess: (res) => {
          enqueueSnackbar({
            variant: "success",
            message: "Draft berhasil diupload",
          });
          navigate(-1);
        },
        onError: (err) => {
          enqueueSnackbar({
            variant: "error",
            message: "Draft gagal diupload",
          });
        },
      }
    )

  };

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        <CollapsingCard title="Informasi Perjanjian" isCollapse={false}>
          <div className="grid grid-cols-3 gap-5">
            <div className="flex flex-col gap-2">
              <Label>Judul Perjanjian</Label>
              <InputForm
                name="agreement_draft_name"
                className="w-full"
                placeholder="Masukan Judul Perjanjian"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Nomor Perjanjian</Label>
              <InputForm
                name="agreement_draft_number"
                className="w-full"
                placeholder="Masukan Nomor Perjanjian"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label>Tanggal Perjanjian</Label>
              <InputForm
                name="agreement_draft_date"
                className="w-full"
                type="date"
                placeholder="Masukan Judul Perjanjian"
              />
            </div>
          </div>
        </CollapsingCard>

        <div ref={templateSectionRef}>
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>
                  {methods.watch("toggle_draft")
                    ? "Upload Dokumen Final Perjanjian"
                    : "Template Perjanjian"}
                </div>
              </div>
            </CardHeader>
            <CardBody>

              <AsyncDropzoneFieldForm
                name={"doc_agreement_draft_template"}
                maxSize={10}
                informationText="PNG, JPG, PDF, DOCX"
              // isView={is_view} 
              />
            </CardBody>
          </Card>
        </div>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            outline
            type="button"
            onClick={() =>
              navigate("/agreement-bank-trusty", { state: { tab: 1 } })
            }
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default BusinessEntityUpload;
