import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { Breadcrumbs, Button, CollapsingCard, Label, Table } from "components";
import {
  AsyncSelectForm,
  CustomSelectForm,
  InputForm,
} from "components/HookForm";
import { useNotaryList } from "hooks/notary";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getDetailInstitutions, getListInstitution, postAgreementNonPerorangan } from "services/directDistribution";
import * as yup from "yup";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
    path: "/agreement-direct-distribution",
  },
  {
    label: "Kontrak Perjanjian",
  },
];

const notaries = [
  {
    label: "John Doe",
    value: 1,
  },
  {
    label: "John Doe",
    value: 2,
  },
];

const tabs = [
  {
    label: "Lembaga Penyalur",
    value: "Lembaga Penyalur",
  },
];

const subTabs = [
  { value: "1", label: "Bank & Ventura" },
  { value: "2", label: "LKNB Koperasi" },
  { value: "3", label: "Badan Hukum Lainnya" },
  { value: "4", label: "LKNB Non Koperasi" },
];

const dummyDaftarDebitur = [
  {
    no: 1,
    borrowerName: "Lorem Ipsum",
    borrowerCategory: "Lorem Ipsum",
    printStatus: "Lorem Ipsum",
    attendanceStatus: "Lorem Ipsum",
  },
];

const signatoryOfficesList = [{ value: "Direktur", label: "Direktur" }];

const validationSchema = yup.object({
  implementation_date: yup
    .string()
    .required("Tanggal Implementasi tidak boleh kosong"),
  invitation_number: yup.string().required("Nomor Undangan tidak boleh kosong"),
  notary: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Nama Notaris tidak boleh kosong"),
  delegate_decision_number: yup
    .string()
    .required("No Kepdirut tidak boleh kosong"),
  delegate_decision_date: yup
    .string()
    .required("Tanggal Kepdirut tidak boleh kosong"),
  signatory_offices: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Pejabat Penanda Tanganan tidak boleh kosong"),
  borrower_category: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Kategori Debitur tidak boleh kosong"),
  selected_debtor: yup
    .array()
    .test("check_selected_debtor", "Pilih satu atau lebih debitur", (value) => {
      return value.some((e) => typeof e !== "undefined");
    }),
});

const BusinessEntityForm = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const queryClient = useQueryClient()

  const navigate = useNavigate();
  const { id } = useParams();

  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "",
    sortBy: "",
    search: "",
    filter: {
      group_type: "",
      agency_type: "",
    },
    // filterCategory: "",
    // filterGroup: "",
  });

  const { data: notaryList } = useNotaryList(
    ["GET_NOTARY_LIST", { page: 1, limit: 1000 }],
    { select: (data) => data?.result }
  );

  const { data: institution } = useQuery(
    ["GET_INSTITUTION_LIST", filters],
    getListInstitution,
    {
      enabled: isFilter,
      select: (data) => data?.data,
      onSuccess: (res) => setIsFilter(false),
      onError: (err) => setIsFilter(false),
    }
  );

  const { data: detailInstitutions } = useQuery(
    ['GET_INSTITUTION_DETAIL', id],
    getDetailInstitutions,
    {
      select: (data) => data?.result,
      enabled: !!id,
    }
  )

  const postCreateAgreement = useMutation({
    mutationFn: postAgreementNonPerorangan,
  });

  useEffect(() => {
    if (detailInstitutions) {
      methods.reset({
        implementation_date: moment(detailInstitutions?.implementation_date).format("YYYY-MM-DD"),
        invitation_number: detailInstitutions?.invitation_number,
        notary: {
          value: detailInstitutions?.notary_id,
          label: detailInstitutions?.notary_name
        },
        delegate_decision_number: detailInstitutions?.delegate_decision_number,
        delegate_decision_date: moment(detailInstitutions?.delegate_decision_date).format("YYYY-MM-DD"),
        signatory_offices: {
          label: detailInstitutions?.signatory_offices,
          value: detailInstitutions?.signatory_offices,
        },
        selected_debtor: detailInstitutions?.selected_debtor,
      })
    }
  }, [detailInstitutions, methods])

  const onSubmit = (data) => {
    const payload = {
      ...data,
      signatory_offices: data?.signatory_offices?.value,
      notary_id: data?.notary?.value,
      notary_name: data?.notary?.label,
      selected_debtor: data?.selected_debtor.filter((e) => e instanceof Object),
      type_agency: "Lembaga Penyalur",
    };

    delete payload.borrower_category;
    delete payload.group;
    delete payload.notary;

    postCreateAgreement.mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Rencana perjanjian berhasil disimpan",
        });
        navigate('/agreement-direct-distribution');
        queryClient.invalidateQueries();
      },
      onError: () => {
        enqueueSnackbar({
          variant: "error",
          message: "Rencana perjanjian gagal disimpan",
        });
      },
    });
  };

  const tableColumnDebitur = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, dataValue, index) => {
          const no =
            (detailInstitutions?.paginator?.page ? parseInt(detailInstitutions?.paginator?.page) : 1) *
            parseInt(detailInstitutions?.paginator?.limit) -
            parseInt(detailInstitutions?.paginator?.limit) +
            index +
            1;

          return no;
        },
      },
      {
        id: "agenciy_name",
        title: "Nama Badan Usaha",
        dataIndex: "borrowerName",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_leader_name}
          </div>
        ),
      },
      {
        id: "contact_person_name",
        title: "Nama Narahubung",
        dataIndex: "borrowerCategory",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_name}
          </div>
        ),
      },
      {
        id: "category_type",
        title: "Kategori",
        dataIndex: "category_type",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.group_type}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-5 justify-center">
            <BiPencil
              className={`text-gray-600 cursor-pointer`}
              size={20}
              onClick={() =>
                navigate(
                  `${data.id}/debtor`
                )
              }
            />
            {/* <RiDeleteBinLine
              className={`text-gray-600 cursor-pointer`}
              size={20}
              // onClick={() => handleEditClick(data?.id)}
            /> */}
          </div>
        ),
      },
    ];
  }, [detailInstitutions, methods, navigate]);

  const tableCreateColumnDebitur = useMemo(() => {
    return [
      {
        id: "checkbox",
        title: "",
        dataIndex: "checkbox",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          return (
            <Controller
              control={methods.control}
              name={`selected_debtor.${index}`}
              render={({ field }) => (
                <input
                  type="checkbox"
                  className="accent-primary-800"
                  onChange={(e) => {
                    if (e.target.checked) {
                      field.onChange(data);
                    } else {
                      field.onChange();
                    }
                  }}
                />
              )}
            />
          );
        },
      },
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(institution?.meta?.current_page) *
            parseInt(institution?.meta?.per_page) -
            parseInt(institution?.meta?.per_page) +
            index +
            1;

          return isNaN(no) ? "" : no;
        },
      },
      {
        id: "agenciy_name",
        title: "Nama Badan Usaha",
        dataIndex: "borrowerName",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.agency_name}
          </div>
        ),
      },
      {
        id: "contact_person_name",
        title: "Nama Narahubung",
        dataIndex: "borrowerCategory",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.contact_person_name}
          </div>
        ),
      },
      {
        id: "category_type",
        title: "Kategori",
        dataIndex: "category_type",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.category_type}
          </div>
        ),
      },
    ];
  }, [institution?.meta, methods]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard
          title="Form Rencana Penandatanganan Perjanjian"
          isCollapse={false}
        >
          <div className="grid grid-cols-3 gap-x-5 gap-y-6">
            <div className="flex flex-col gap-2">
              <Label required>Tanggal Pelaksanaan</Label>
              <InputForm
                type="date"
                name="implementation_date"
                className="w-full"
                disabled={id ? true : false}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Nomor Undangan Pelaksanaan</Label>
              <InputForm
                type="text"
                name="invitation_number"
                className="w-full"
                placeholder="Masukan Nomor Undangan Pelaksanaan"
                disabled={id ? true : false}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Nama Notaris</Label>
              <CustomSelectForm
                name="notary"
                options={notaryList?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
                isDisabled={id ? true : false}
                placeholder="Pilih satu"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label required>No Kepdirut</Label>
              <InputForm
                type="text"
                name="delegate_decision_number"
                className="w-full"
                placeholder="Masukan Nomor Keputusan Delegasi"
                disabled={id ? true : false}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Tanggal Kepdirut</Label>
              <InputForm
                type="date"
                name="delegate_decision_date"
                className="w-full"
                disabled={id ? true : false}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Pejabat Penanda Tanganan</Label>
              <CustomSelectForm
                options={signatoryOfficesList}
                name="signatory_offices"
                placeholder="Pilih satu"
                isDisabled={id ? true : false}
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Daftar Debitur" isCollapse={false}>
          <div className="flex flex-col gap-4">
            {!id && (
              <div className="flex gap-5 items-stretch">
                <div className="flex flex-col gap-2 max-w-[346px] w-full">
                  <Label>Kategori Debitur</Label>
                  {/* <AsyncSelectForm name="borrower_category" /> */}
                  <CustomSelectForm
                    name="agency_type"
                    options={tabs}
                    placeholder="Pilih"
                    onValueChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        filter: {
                          ...prev.filter,
                          agency_type: e.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col gap-2 max-w-[346px] w-full">
                  <Label>Pilih Data</Label>
                  <CustomSelectForm
                    name="group_type"
                    defaultvalue
                    options={subTabs}
                    placeholder="Pilih"
                    onValueChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        filter: {
                          ...prev.filter,
                          group_type: e.value,
                        },
                      }))
                    }
                  />
                  {/* <AsyncSelectForm name="group" /> */}
                </div>


                <div
                  className={classNames(
                    "flex gap-4 items-end",
                    methods.formState.errors.borrower_category && "items-center"
                  )}
                >
                  <Button
                    className="bg-primary-700 text-white hover:bg-primary-800 h-10 w-24"
                    size="sm"
                    onClick={() => setIsFilter(true)}
                  >
                    Filter
                  </Button>
                  <Button
                    className="bg-red-600 text-white hover:bg-red-700 h-10 w-24"
                    size="sm"
                  >
                    Reset
                  </Button>
                </div>
              </div>
            )}


            {/* table */}
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-3">
                <div className="space-y-4">
                  <Table
                    bordered
                    stripped
                    layout="auto"
                    className="mb-4"
                    columns={id ? tableColumnDebitur : tableCreateColumnDebitur}
                    dataSource={institution ? institution?.data : detailInstitutions ? detailInstitutions?.debtor_data : []}
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>

        {
          !id && (
            <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
              <Button
                type="button"
                onClick={() => navigate(-1)}
                outline
                className="bg-white text-primary-700 w-1/6"
              >
                Batal
              </Button>
              <Button
                type="submit"
                onClick={methods.handleSubmit(onSubmit)}
                className="bg-primary-700 text-white w-1/6"
              >
                Simpan
              </Button>
            </div>
          )
        }

      </div>
    </FormProvider>
  );
};

export default BusinessEntityForm;
