import axios from "axios";

export const getAgreementGroupMemberList = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-group-member`,
    { params: queryKey[1] }
  );
  return data;
};

export const postAgreementGroupMember = async (data) => {
  const { data: res } = await axios.post(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-group-member`,
    data
  );

  return res;
};

export const getAgreementGroupMemberDetail = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-group-member/${queryKey[1]}`
  );
  return data;
};

export const getDebtorAgreementGroupMemberList = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_FDB}cms/agreement/debtor`,
    {
      params: queryKey[1],
    }
  );

  return data;
};

export const getDebtorAgreementDetail = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_FDB}cms/agreement/${queryKey[1]}`
  );

  return data;
};

export const updateAgreementGroupMemberDebtor = async ({ id, payload }) => {
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-group-member/debtor/${id}`,
    payload
  );

  return data;
};

export const updateAgreementGroupMemberDebtorDraft = async ({
  id,
  payload,
}) => {
  const { data } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-group-member/debtor/agreement-draft/${id}`,
    payload
  );

  return data;
};

export const getDebtorAgreementGroupMemberDetail = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-group-member/debtor/${queryKey[1]}`
  );

  return data;
};

// ?
export const getListInstitution = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_FDB}cms/agreement/debtor-agency`,
    {
      params: queryKey[1],
    }
  );

  return data;
};

export const getDetailInstitutions = async ({ queryKey }) => {
  const { data: res } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-agency-member/${queryKey[1]}`
  );

  return res;
};

export const getDetailDebtor = async ({ queryKey }) => {
  const { data: res } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-agency-member/debtor/${queryKey[1]}`
  );

  return res;
};

export const postAgreementNonPerorangan = async (data) => {
  const { data: res } = await axios.post(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-agency-member`,
    data
  );

  return res;
};

export const getAgreementNonPeroranganList = async ({ queryKey }) => {
  const { data: res } = await axios.get(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-agency-member`,
    { params: queryKey }
  );

  return res;
};

export const putAgreementAgencyMemberDebtorDraft = async ({ id_debtor, payload }) => {
  const { data: res } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-agency-member/debtor/agreement-draft/${id_debtor}`,
    payload
  );

  return res;
};

export const putAgreementAgencyMemberDebtor = async ({ id_debtor, mapPayload }) => {
  const { data: res } = await axios.put(
    `${process.env.REACT_APP_API_PERJANJIAN}/agreement-agency-member/debtor/${id_debtor}`,
    mapPayload
  );

  return res;
};
