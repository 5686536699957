import { yupResolver } from "@hookform/resolvers/yup";
import DraftTemplate from "assets/img/draft-template.png";
import { Breadcrumbs, Button, CollapsingCard, Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import DropzoneFieldForm from "components/HookForm/DropzoneFieldForm";
import { useDonorAgreementDetail, useEditDonorAgreement } from "hooks/donor";
import moment from "moment";

import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { createFileRequest } from "utils";
import * as yup from "yup";

const getFileFromUrl = async (files) => {
  if (files.length === 0) return [];
  const fileTemp = [];
  for (let file of files) {
    const response = await fetch(file?.url);

    const data = await response.blob();
    const blobFile = new File([data], file?.name, {
      type: data.type || file?.mimeType,
    });

    blobFile.path = file.name;
    blobFile.preview = file.url;

    fileTemp.push(blobFile);
  }

  return fileTemp;
};

const validationSchema = yup.object({
  project_id: yup.string().notRequired("ID Projek tidak boleh kosong"),
  contract_number: yup.string().required("Nomor Kontrak tidak boleh kosong"),
  programs: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .test("required", "Windows tidak boleh kosong", (value) => {
      if (!value) return false;
      else if (value !== null && value.value && value.label)
        return !!value.value && !!value.label;
      return true;
    })
    .nullable(),
  implementation_date: yup
    .string()
    .required("Tanggal Penandatanganan tidak boleh kosong"),
  doc_final: yup
    .mixed()
    .test("required", "Dokumen donor final tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    }),
  doc_supporting: yup
    .mixed()
    .test("required", "Dokumen support tidak boleh kosong", (files) => {
      return !!files && files.length > 0;
    })
    .nullable(),
  notes: yup.string().required("Notes tidak boleh kosong"),
});

const programOptions = [
  { value: "CLUA", label: "CLUA" },
  { value: "GCF", label: "GCF" },
  { value: "FCPF", label: "FCPF" },
  { value: "+REDD", label: "+REDD" },
  { value: "TERRA Fund", label: "TERRA Fund" },
];

const DonorAgreementUpload = () => {
  const navigate = useNavigate();
  const { proposalId } = useParams();

  const [result, setResult] = useState([]);
  const [programs, setPrograms] = useState(null);
  const editDonorAgreement = useEditDonorAgreement(proposalId);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      proposal_title: result?.proposal_title,
      donor_name: result?.donor_name,
      project_name: result?.project_name,
      project_id: result?.project_id ?? "",
      contract_number: result?.contract_number ?? "",
      implementation_date: result?.implementation_date
        ? moment(result?.implementation_date).format("YYYY-MM-DD")
        : null,
      notes: result?.notes ?? "",
      doc_final: [],
      doc_supporting: [],
    },
  });

  useDonorAgreementDetail(["donor_agreement_detail", `${proposalId}`], {
    onSuccess: (data) => {
      Promise.all([
        // getFileFromUrl(data.result?.doc_final),
        // getFileFromUrl(data.result?.doc_supporting),
        getFileFromUrl(
          data.result?.doc_final
            ? Array.isArray(data.result?.doc_final)
              ? data.result?.doc_final
              : JSON.parse(data.result.doc_final)
            : []
        ),
        getFileFromUrl(
          data.result?.doc_supporting
            ? Array.isArray(data.result?.doc_supporting)
              ? data.result?.doc_supporting
              : JSON.parse(data.result.doc_supporting)
            : []
        ),
      ]).then((values) => {
        setResult(data.result);
        methods.reset({
          proposal_title: data.result.proposal_title,
          donor_name: data.result.donor_name,
          project_name: data.result.project_name,
          project_id: data.result?.project_id,
          contract_number: data.result?.contract_number,
          programs: {
            label: data.result?.program_name,
            value: data.result?.program_id,
          },
          implementation_date: data.result?.implementation_date
            ? moment(data.result?.implementation_date).format("YYYY-MM-DD")
            : null,
          notes: data.result?.notes,
          doc_final: values[0],
          doc_supporting: values[1],
        });
      });
    },
    enabled: !!proposalId,
  });

  const links = [
    {
      label: "Penyusunan Perjanjian",
      path: "#",
    },
    {
      label: "Perjanjian Donor",
      path: "/agreement-donor",
    },
    {
      label: "Upload Kontrak",
    },
  ];

  const onSubmit = (data) => {
    const {
      project_id,
      project_name,
      proposal_title,
      donor_name,
      implementation_date,
      contract_number,
      programs,
      doc_final,
      doc_supporting,
      notes,
    } = data;

    const payload = {
      project_id,
      project_name,
      proposal_title,
      donor_id: proposalId,
      donor_name,
      doc_draft: result.doc_draft,
      contract_number,
      implementation_date,
      program_id: programs.value,
      program_name: programs.label,
      doc_final,
      doc_supporting,
      notes,
    };

    Promise.all([
      createFileRequest(doc_final),
      createFileRequest(doc_supporting),
    ]).then((values) => {
      payload.doc_final = values[0];
      payload.doc_supporting = values[1];

      editDonorAgreement.mutate(payload, {
        onSuccess: (data, variables) => {
          enqueueSnackbar("Data perjanjian donor berhasil diubah", {
            variant: "success",
          });

          navigate("/agreement-donor", { state: { tab: 1 } });
        },
        onError: (error, variables) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        },
      });
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard title="Informasi Perjanjian" isCollapse={false}>
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col w-full gap-1">
              <Label required>ID Project</Label>
              <InputForm
                name="project_id"
                className="w-full"
                placeholder="Masukkan ID Project"
              />
            </div>

            <div className="flex flex-col w-full gap-1">
              <Label>Judul Proposal</Label>
              <InputForm name="proposal_title" className="w-full" disabled />
            </div>

            <div className="flex flex-col w-full gap-1">
              <Label>Nama Donor</Label>
              <InputForm
                name="donor_name"
                className="w-full truncate"
                value="International Bank For Reconstruction and Development"
                disabled
              />
            </div>

            <div className="flex flex-col w-full gap-1">
              <Label required>Nomor Kontrak</Label>
              <InputForm
                name="contract_number"
                className="w-full"
                placeholder="Masukkan Nomor Kontrak"
              />
            </div>

            <div className="flex flex-col w-full gap-1">
              <Label required>Tanggal Penandatanganan</Label>
              <InputForm
                type="date"
                className="w-full"
                name="implementation_date"
                placeholder="Pilih Tanggal"
              />
            </div>

            <div className="flex flex-col w-full">
              <Label required>Program</Label>
              <CustomSelectForm
                options={programOptions}
                value={programs}
                onChange={setPrograms}
                name="programs"
                placeholder="Pilih satu"
              />
            </div>
          </div>

          {/* <div className="flex w-full gap-4">
            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label required>ID Project</Label>
                <InputForm
                  name="project_id"
                  className="w-full"
                  placeholder="Masukkan ID Project"
                />
              </div>

              <div className="flex flex-col w-full gap-1">
                <Label required>Nomor Kontrak</Label>
                <InputForm
                  name="contract_number"
                  className="w-full"
                  placeholder="Masukkan Nomor Kontrak"
                />
              </div>

              <div className="flex flex-col w-full">
                <Label required>Program</Label>
                <CustomSelectForm
                  options={programOptions}
                  value={programs}
                  onChange={setPrograms}
                  name="programs"
                  placeholder="Pilih satu"
                />
              </div>
            </div>

            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label>Judul Proposal</Label>
                <InputForm name="proposal_title" className="w-full" disabled />
              </div>

              <div className="flex flex-col w-full gap-1">
                <Label>Nama Project</Label>
                <InputForm name="project_name" className="w-full" disabled />
              </div>
            </div>

            <div className="flex flex-col w-1/3 gap-2">
              <div className="flex flex-col w-full gap-1">
                <Label>Nama Donor</Label>
                <InputForm
                  name="donor_name"
                  className="w-full truncate"
                  value="International Bank For Reconstruction and Development"
                  disabled
                />
              </div>

              <div className="flex flex-col w-full gap-1">
                <Label required>Tanggal Penandatanganan</Label>
                <InputForm
                  type="date"
                  className="w-full"
                  name="implementation_date"
                  placeholder="Pilih Tanggal"
                />
              </div>
            </div>
          </div> */}
        </CollapsingCard>

        <CollapsingCard title="Draft Awal Perjanjian Donor" isCollapse={false}>
          <div className="flex flex-col items-center w-fit">
            {result?.doc_draft ? (
              <a
                href={result?.doc_draft.url}
                className={`w-40 h-fit cursor-pointer`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={DraftTemplate}
                  alt="draft-template"
                  className="w-full h-full border border-slate-300 rounded-lg"
                />
              </a>
            ) : (
              <div className="w-40 h-fit">
                <img
                  src={DraftTemplate}
                  alt="draft-template"
                  className="w-full h-full border border-slate-300 rounded-lg"
                />
              </div>
            )}
            {/* <p className="">Template 1</p> */}
          </div>
        </CollapsingCard>

        <CollapsingCard
          title="Upload Dokumen Final Perjanjian Donor"
          isCollapse={false}
        >
          <div className="w-full">
            <DropzoneFieldForm
              name="doc_final"
              informationText="PDF/DOC"
              accept={{
                "application/docx": [".doc", ".docx"],
                "application/pdf": [".pdf"],
              }}
              maxFiles={30}
              maxSize={1}
              multiple={false}
            />
          </div>
        </CollapsingCard>

        <CollapsingCard title="Upload Dokumen Pendukung" isCollapse={false}>
          <div className="w-full">
            <DropzoneFieldForm
              name="doc_supporting"
              informationText="PDF/DOC"
              accept={{
                "application/docx": [".doc", ".docx"],
                "application/pdf": [".pdf"],
              }}
              maxFiles={30}
              maxSize={1}
              multiple={false}
            />
          </div>
        </CollapsingCard>

        <CollapsingCard title="Notes/Change Log" isCollapse={false}>
          <InputForm
            type="textarea"
            rows={3}
            name="notes"
            className="w-full"
            placeholder="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo, consequatur?"
          />
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            type="button"
            onClick={() => navigate(`/agreement-donor`, { state: { tab: 1 } })}
            outline
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default DonorAgreementUpload;
