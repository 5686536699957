import { Breadcrumbs } from "components";
import TemplateForm from ".";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useMemo } from "react";

const EditTemplate = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { templateId } = useParams();
  const { state } = useLocation(); 

  const links = [
    {
      path: "#",
      label: "Penyusunan Perjanjian",
    },
    {
      path: location.state.path,
      state: { isFromTemplate: true },
      label: "Ubah Penyusunan Perjanjian",
    },
    {
      label: "Template Penyusunan Perjanjian",
      isActive: true,
    },
  ];

  const defaultValues = useMemo(
    () => ({
      template_title: state?.template_title,
      template_content: state?.template_content,
    }),
    []
  );

  const onSubmitTemplate = (payload) => {
    const body = {
      template_id: templateId ?? state?.template_id,
      ...payload
    }
    console.log(body)

    localStorage.setItem("template", JSON.stringify(body));
    navigate(location.state.path, {
      state: {
        isFromTemplate: true,
        // isFromEdit: location.pathname.includes("/detail"),
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <TemplateForm 
        onSubmitAction={onSubmitTemplate}
        isLoading={false}
        defaultValues={defaultValues}
        type="edit"
      />
    </div>
  )
};

export default EditTemplate;