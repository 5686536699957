import { Breadcrumbs } from "components";
import { useLocation, useNavigate } from "react-router";

const { useMemo } = require("react");
const { default: TemplateForm } = require(".");

const NewTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    {
      path: "#",
      label: "Penyusunan Perjanjian",
    },
    {
      path: location.state.path,
      state: { isFromTemplate: true },
      label: "Tambah Penyusunan Perjanjian",
    },
    {
      label: "Template Penyusunan Perjanjian",
      isActive: true,
    },
  ];

  const onSubmitTemplate = (payload) => {
    const body = {
      template_id: null,
      ...payload
    }
    console.log(body)

    localStorage.setItem("template", JSON.stringify(body));
    navigate(location.state.path, {
      state: {
        isFromTemplate: true,
        // isFromEdit: location.pathname.includes("/detail"),
      },
    });
  };

  const defaultValues = useMemo(
    () => ({
      template_title: "",
      template_content: "",
    }),
    []
  );

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <TemplateForm
        onSubmitAction={onSubmitTemplate}
        isLoading={false}
        defaultValues={defaultValues}
        type="new"
      />
    </div>
  );
};

export default NewTemplate;
