import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { Breadcrumbs, Button, CollapsingCard, Label, Table } from "components";
import {
  AsyncSelectForm,
  CustomSelectForm,
  InputForm,
} from "components/HookForm";
import { useMemo } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Penyaluran Langsung",
    path: "/agreement-direct-distribution",
  },
  {
    label: "Kontrak Perjanjian",
  },
];

const notaries = [
  {
    label: "John Doe",
    value: 1,
  },
  {
    label: "John Doe",
    value: 2,
  },
];

const tabs = [
  {
    label: "Perorangan",
    value: "Perorangan",
  },
  {
    label: "Perorangan Dalam Kelompok",
    value: "Perorangan Dalam Kelompok",
  },
  {
    label: "Badan Usaha",
    value: "Badan Usaha",
  },
  {
    label: "Badan Hukum Lainnya",
    value: "Badan Hukum Lainnya",
  },
];

const dummyDaftarDebitur = [
  {
    no: 1,
    borrowerName: "Lorem Ipsum",
    borrowerCategory: "Lorem Ipsum",
    printStatus: "Lorem Ipsum",
    attendanceStatus: "Lorem Ipsum",
  },
];

const signatoryOfficesList = [{ value: "Direktur", label: "Direktur" }];

const individualFormSchema = yup.object({
  implementation_date: yup
    .string()
    .required("Tanggal Implementasi tidak boleh kosong"),
  invitation_number: yup.string().required("Nomor Undangan tidak boleh kosong"),
  notary: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Nama Notaris tidak boleh kosong"),
  delegate_decision_number: yup.string(),
  delegate_decision_date: yup.string(),
  signatory_offices: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Pejabat Penanda Tanganan tidak boleh kosong"),
  borrower_category: yup
    .object({
      value: yup.string(),
      label: yup.string(),
    })
    .required("Kategori Debitur tidak boleh kosong"),
});

const IndividualForm = () => {
  const methods = useForm({
    resolver: yupResolver(individualFormSchema),
  });

  const navigate = useNavigate();
  const { id } = useParams();

  const onSubmit = (data) => {
    console.log(data);
  };

  const tableColumnDebitur = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        // render: (value, data, index) => {
        //   const no =
        //     parseInt(perOranganData.paginator.page) *
        //       parseInt(perOranganData.paginator.limit) -
        //     parseInt(perOranganData.paginator.limit) +
        //     index +
        //     1;

        //   return no;
        // },
      },
      {
        id: "borrower_name",
        title: "Nama Debitur",
        dataIndex: "borrowerName",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.borrowerName}
          </div>
        ),
      },
      {
        id: "borrower_category",
        title: "Kategori Debitur",
        dataIndex: "borrowerCategory",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.borrowerCategory}
          </div>
        ),
      },
      {
        id: "print_status",
        title: "Status Cetak",
        dataIndex: "printStatus",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.printStatus}
          </div>
        ),
      },

      {
        id: "attendance_status",
        title: "Status Cetak",
        dataIndex: "attendanceStatus",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.attendanceStatus}
          </div>
        ),
      },

      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-5 justify-center">
            <BiPencil
              className={`text-gray-600 cursor-pointer`}
              size={20}
              onClick={() =>
                navigate(
                  `/agreement-direct-distribution/${data.no}/individual/form/debtor`
                )
              }
            />
            <RiDeleteBinLine
              className={`text-gray-600 cursor-pointer`}
              size={20}
              // onClick={() => handleEditClick(data?.id)}
            />
          </div>
        ),
      },
    ];
  }, [navigate]);

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard
          title="Form Rencana Penandatanganan Perjanjian"
          isCollapse={false}
        >
          <div className="grid grid-cols-3 gap-x-5 gap-y-6">
            <div className="flex flex-col gap-2">
              <Label required>Tanggal Pelaksanaan</Label>
              <InputForm
                type="date"
                name="implementation_date"
                className="w-full"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Nomor Undangan Pelaksanaan</Label>
              <InputForm
                type="text"
                name="invitation_number"
                className="w-full"
                placeholder="Masukan Nomor Undangan Pelaksanaan"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Nama Notaris</Label>
              <CustomSelectForm
                name="notary"
                options={notaries}
                placeholder="Pilih satu"
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label required>No Kepdirut</Label>
              <InputForm
                type="text"
                name="delegate_decision_number"
                className="w-full"
                placeholder="Masukan Nomor Keputusan Delegasi"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Tanggal Kepdirut</Label>
              <InputForm
                type="date"
                name="delegate_decision_date"
                className="w-full"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label required>Pejabat Penanda Tanganan</Label>
              <CustomSelectForm
                options={signatoryOfficesList}
                name="signatory_offices"
                placeholder="Pilih satu"
              />
            </div>
          </div>
        </CollapsingCard>

        <CollapsingCard title="Daftar Debitur" isCollapse={false}>
          <div className="flex flex-col gap-4">
            <div className="flex gap-5 items-stretch">
              <div className="flex flex-col gap-2 max-w-[346px] w-full">
                <Label>Kategori Debitur</Label>
                {/* <AsyncSelectForm name="borrower_category" /> */}
                <CustomSelectForm
                  name="borrower_category"
                  options={tabs}
                  placeholder="Pilih"
                />
              </div>
              <div className="flex flex-col gap-2 max-w-[346px] w-full">
                <Label>Pilih Data</Label>
                <AsyncSelectForm name="group" />
              </div>

              <div
                className={classNames(
                  "flex gap-4 items-end",
                  methods.formState.errors.borrower_category && "items-center"
                )}
              >
                <Button
                  className="bg-primary-700 text-white hover:bg-primary-800 h-10 w-24"
                  size="sm"
                >
                  Filter
                </Button>
                <Button
                  className="bg-red-600 text-white hover:bg-red-700 h-10 w-24"
                  size="sm"
                >
                  Reset
                </Button>
              </div>
            </div>

            {/* table */}
            <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
              <div className="card-body p-3">
                <div className="space-y-4">
                  <Table
                    bordered
                    stripped
                    layout="auto"
                    className="mb-4"
                    columns={tableColumnDebitur}
                    dataSource={id ? dummyDaftarDebitur : []}
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            type="button"
            onClick={() => navigate(-1)}
            outline
            className="bg-white text-primary-700 w-1/6"
          >
            Batal
          </Button>
          <Button
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            className="bg-primary-700 text-white w-1/6"
          >
            Simpan
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default IndividualForm;
