import React from "react";
import moment from "moment";
import { PiExportBold } from "react-icons/pi";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  FileDownloadCard,
  Label,
} from "components";
import TreeTable from "./Tables/TreeTable";
import CollateralTable from "./Tables/CollateralTable";
import LandTable from "./Tables/LandTable";
import VehicleTable from "./Tables/VehicleTable";
import { TextareaForm } from "components/HookForm";

const links = [
  {
    label: "Daftar Serah Terima Agunan",
    path: "/collateral/handover",
  },
  {
    label: "Detail",
  },
];

const DetailHandover = () => {
  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <Card>
        <CardHeader>Detail</CardHeader>
        <CardBody>
          <div className="space-y-6">
            <div class="grid grid-cols-4 gap-4">
              <div className="space-y-3">
                <div className="text-black font-semibold">
                  Tanggal Aktual Diserahkan
                </div>
                <div className="text-black">
                  {moment().format("DD MMMM YYYY")}
                </div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">
                  Diserahkan Kepada
                </div>
                <div className="text-black">Anna</div>
              </div>
            </div>
            <hr />
            <div className="space-y-3">
              <div className="text-black font-semibold">Dokumen Pendukung</div>
              <div className="w-full">
                <FileDownloadCard
                  size={0.2}
                  filename="dokumen_pendukung.pdf"
                  link="#"
                />
              </div>
            </div>
            <div class="grid grid-cols-4 gap-4">
              <div className="space-y-3">
                <div className="text-black font-semibold">Nomor NAP</div>
                <div className="text-black">123456789</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Nama Debitur</div>
                <div className="text-black">John Doe</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Kategori</div>
                <div className="text-black">Diserahkan Kepada Debitur</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Nilai Pembayaran</div>
                <div className="text-black">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  }).format(10000000)}
                </div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Tempat Lahir</div>
                <div className="text-black">Bandung</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Tanggal Lahir</div>
                <div className="text-black">
                  {moment().format("DD MMMM YYYY")}
                </div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Provinsi</div>
                <div className="text-black">Jawa Barat</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Kab/Kota</div>
                <div className="text-black">Kota Bandung</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Kecamatan</div>
                <div className="text-black">Bandung Timur</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Kelurahan</div>
                <div className="text-black">Bandung Kulon</div>
              </div>
              <div className="space-y-3">
                <div className="text-black font-semibold">Kode Pos</div>
                <div className="text-black">12345</div>
              </div>
            </div>
            <hr />
            <div className="space-y-3">
              <div className="text-black font-semibold">Alamat</div>
              <div className="text-black">
                Lorem ipsum dolor sit amet consectetur. Odio eget accumsan
                tincidunt tortor. Sed tempor a blandit viverra a vulputate
                tristique nunc nec. Facilisi id amet lacus iaculis purus
                porttitor amet libero vel. Quam volutpat volutpat tellus aliquet
                a egestas ac ut.
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Catatan Kronlogis</CardHeader>
        <CardBody>
          <div className="space-y-6">
            <div className="space-y-3">
              <Label required>Catatan</Label>
              <textarea
                className="w-full py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-gray-200"
                rows={5}
                disabled
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <div className="text-[#101828] font-semibold text-lg">
              Daftar Agunan
            </div>
            <Button
              className="text-white border-orange-600 bg-orange-600 hover:bg-orange-700 hover:border-orange-700"
              startIcon={<PiExportBold className="text-white" size={20} />}
            >
              Export
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="font-semibold text-gray-900">Daftar Pohon</div>
              <TreeTable />
            </div>
            <hr />
            <div className="space-y-4">
              <div className="font-semibold text-gray-900">
                Daftar Collateral
              </div>
              <CollateralTable />
            </div>
            <hr />
            <div className="space-y-4">
              <div className="font-semibold text-gray-900">
                Daftar Tanah & Bangunan
              </div>
              <LandTable />
            </div>
            <hr />
            <div className="space-y-4">
              <div className="font-semibold text-gray-900">
                Daftar Kendaraan & Mesin
              </div>
              <VehicleTable />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default DetailHandover;
