import { yupResolver } from "@hookform/resolvers/yup";
import { Breadcrumbs, Button, CollapsingCard, Label } from "components";
import { InputForm } from "components/HookForm";
import { useNotaryDetail } from "hooks/notary";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

const NotaryPreview = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [result, setResult] = useState();

  const { data, isLoading } = useNotaryDetail(["notary_detail", id], {
    enabled: !!id,
    onSuccess: (data) => {
      setResult(data.result);
      methods.reset({
        name: data.result.name,
        sk_kemenkumham: data.result.sk_kemenkumham,
        sk_ppat: data.result.sk_ppat,
        phone: data.result.phone,
        address: data.result.address,
      });
    },
  });

  const methods = useForm({
    defaultValues: {
      name: result?.name ?? "-",
      sk_kemenkumham: result?.sk_kemenkumham ?? "-",
      sk_ppat: result?.sk_ppat ?? "-",
      phone: result?.phone ?? "-",
      address: result?.address ?? "-",
    },
  });

  const links = [
    {
      label: "Master Data Notaris",
      path: "/notary",
    },
    {
      label: "Detail",
    },
  ];

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />

        <CollapsingCard title="Detail Data Notaris" isCollapse={false}>
          <div className="flex flex-col w-full gap-4">
            <div className="flex flex-col gap-1">
              <Label required>Nama Notaris</Label>
              <InputForm
                type="text"
                name="name"
                className="w-full"
                placeholder="Masukkan nama notaris"
                disabled
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>SK. KEMENKUMHAM</Label>
              <InputForm
                type="text"
                name="sk_kemenkumham"
                className="w-full"
                placeholder="Masukkan SK. KEMENKUMHAM"
                disabled
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>SK. PPAT</Label>
              <InputForm
                type="text"
                name="sk_ppat"
                className="w-full"
                placeholder="Masukkan SK. PPAT"
                disabled
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>No Telepon</Label>
              <InputForm
                type="text"
                name="phone"
                className="w-full"
                placeholder="Masukkan no telepon"
                disabled
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label required>Alamat</Label>
              <InputForm
                type="textarea"
                row={3}
                name="address"
                className="w-full"
                placeholder="Masukkan alamat"
                disabled
              />
            </div>
          </div>
        </CollapsingCard>

        <div className="flex gap-4 p-4 justify-end bg-white border rounded-md">
          <Button
            onClick={() => navigate(`/notary`)}
            outline
            className="bg-white text-primary-700 w-1/6"
          >
            Kembali
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default NotaryPreview;